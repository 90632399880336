import classNames from "classnames";
import * as React from "react";
import { PulseLoader } from "react-spinners";

export interface PrimaryButton extends React.HTMLProps<HTMLButtonElement> {
  width?: "auto" | "full" | "fit";
  type?: "compact" | "normal" | undefined;
  loading?: boolean;
  disabled?: boolean;
  title: string;
  icon?: any;
  iconDisabled?: any;
  onClick?: () => void;
}

export default function PrimaryButton(props: PrimaryButton) {
  const {
    className,
    type,
    children,
    loading,
    disabled = false,
    title,
    icon,
    iconDisabled,
    width = "auto",
    ...buttonProps
  } = props;

  const style = classNames(
    "font-semibold flex justify-center items-center rounded-[8px] tracking-tight cursor-pointer",
    // 'focus-visible:ring-2 focus-visible:ring-gray-900 focus-visible:ring-inset',
    "disabled:bg-none bg-nile_blue",
    "disabled:bg-neutral-5 disabled:text-neutral-60 transition disabled:text-[13px]",
    // 'text-interactive-2',
    "text-white px-6",
    { "w-full": width === "full" },
    { "w-fit": width === "fit" },
    className,
    "enabled:md:hover:bg-orange-50 enabled:hover:text-[13px] enabled:transition-all enabled:ease-in-out duration-300",
    "group",
    { "py-2": type === "compact" },
    { "min-h-[48px]": type !== "compact" }
  );

  return (
    <button
      {...buttonProps}
      className={style}
      disabled={disabled}
      onClick={props.onClick}
    >
      {loading ? (
        <PulseLoader size={10} color={"white"} loading={true} />
      ) : (
        <div
          className={classNames("flex flex-row items-center", {
            "gap-3 ": !!props.title,
          })}
        >
          {icon && !disabled && <img src={icon} className="h-6" />}
          {iconDisabled && disabled && (
            <img src={iconDisabled} className="h-6" />
          )}
          <div>{props.title}</div>
        </div>
      )}
    </button>
  );
}
