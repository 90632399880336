import React, {useContext} from "react";
import "./IdentityDashboard.scss";
import IdentityList from "../identityList/IdentityList";
import DashboardHeader from "../dashboardHeader/DashboardHeader";
import {useIdentities} from "../../providers/IdentityProvider";
import {PulseLoader} from "react-spinners";
import {useDebounceValue} from "usehooks-ts";
import GlobalContext from "../context/GlobalContext";

function IdentityDashboard(props) {

    const context = useContext(GlobalContext);
    const {isLoaded} = useIdentities();
    const [debouncedValue, setDebouncedValue] = useDebounceValue(context.identitySearchPhrase, 500)

    const updateSearchPhrase = (value) => {
        context.updateIdentitySearchPhrase(value);
    }

    return (
        <div className="no-scrollbar w-full h-full mt-[64px] lg:ml-[256px] lg:mt-0">
            <div className="h-full overflow-y-auto no-scrollbar overflow-x-hidden pb-[100px] lg:pb-6">
                {!isLoaded && (
                    <div className="flex justify-center items-center h-full">
                        <PulseLoader size={10} color={"black"} loading={true}/>
                    </div>
                )}

                {isLoaded && (
                    <>
                        <DashboardHeader searchPhrase={context.identitySearchPhrase} setSearchPhrase={updateSearchPhrase}/>
                        <IdentityList searchPhrase={debouncedValue}/>
                    </>
                )}
            </div>
        </div>
    );
}

export default IdentityDashboard;
