import HCaptcha from "@hcaptcha/react-hcaptcha";
import { config } from "@no.id/web-common";
import { trackGoal } from "fathom-client";
import React, { useEffect, useRef, useState } from "react";
import { PulseLoader } from "react-spinners";
import { Button, Icon, Image } from "semantic-ui-react";
import AnalyticsEvents from "../../events/analyticsEvents";
import { useAuthActions } from "../../providers/AuthProvider";
import PrimaryInput from "../pimaryInput/primaryInput";
import "./SignUp.scss";

const zxcvbn = require("zxcvbn");
const passwordValidator = require("password-validator");

const passwordStrengthMessage = {
  0: { msg: "too guessable", color: "red" },
  1: { msg: "very guessable", color: "red" },
  2: { msg: "somewhat guessable", color: "yellow" },
  3: { msg: "safely unguessable", color: "olive" },
  4: { msg: "very unguessable", color: "green" },
};

const maxPasswordStrength = 4;
const acceptablePasswordStrength = 3;

export default function SignUp(props) {
  const { signUp } = useAuthActions();

  const passwordSchema = useRef(
    new passwordValidator()
      .is()
      .min(8)
      .is()
      .max(256)
      .has()
      .uppercase()
      .has()
      .lowercase()
      .has()
      .digits()
      .has()
      .symbols()
      .has()
      .not()
      .spaces()
  );

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(null);
  const [identicalPassword, setIdenticalPassword] = useState(true);
  const [registrationCode, setRegistrationCode] = useState("");
  const [invalidCode, setInvalidCode] = useState(false);
  const [signUpFailed, setSignUpFailed] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [invalidCaptchaToken, setInvalidCaptchaToken] = useState(false);

  const usernameRef = React.createRef();
  const passwordRef = React.createRef();
  const repeatPasswordRef = React.createRef();
  const registrationCodeRef = React.createRef();
  const captchaRef = useRef(null);

  const validateUsername = () => {
    if (!username || username.length < 6 || username.length > 48) {
      return false;
    }
    const re = /^[0-9a-zA-Z]+$/g;
    return re.test(username);
  };

  const handleCreateClick = async (e) => {
    e.preventDefault();

    setInProgress(true);

    if (!validateUsername()) {
      setInvalidEmail(true);
      setInProgress(false);
      return;
    }
    setInvalidEmail(false);

    if (!password) {
      setPasswordStrength(0);
      setInProgress(false);
      return;
    }

    if (passwordStrength < acceptablePasswordStrength) {
      setInProgress(false);
      return;
    }

    if (password !== passwordRepeat) {
      setIdenticalPassword(false);
      setInProgress(false);
      return;
    }
    setIdenticalPassword(true);

    // if (!registrationCode || registrationCode.trim() == "") {
    //     setInvalidCode(true);
    //     setInProgress(false);
    //     return;
    // }
    setInvalidCode(false);

    // if (!captchaToken) {
    //     setInvalidCaptchaToken(true);
    //     setInProgress(false);
    //     return;
    // }
    setInvalidCaptchaToken(false);

    setInProgress(true);
    registerNewAccount();
  };

  const registerNewAccount = () => {
    trackGoal(AnalyticsEvents.WEB_REGISTRATION_CLICKED, 0);
    signUp(username, password, registrationCode, captchaToken, (status) => {
      if (!status) {
        setSignUpFailed(true);
        setInProgress(false);

        trackGoal(AnalyticsEvents.WEB_REGISTRATION_FAILED, 0);
        return;
      }

      trackGoal(AnalyticsEvents.WEB_REGISTRATION_SUCCESS, 0);

      props.history.push("/main");
    });
  };

  const captchaTokenVerified = (token) => {
    setCaptchaToken(token);
    setInvalidCaptchaToken(false);
  };

  const handleKeyUp = (e) => {
    e.which = e.which || e.keyCode;

    if (e.which === 13) {
      switch (e.target.name) {
        case "noidUsername":
          passwordRef.current.focus();
          break;
        case "noidPassword":
          repeatPasswordRef.current.focus();
          break;
        case "noidPasswordRepeat":
          registrationCodeRef.current.focus();
          break;
        case "registrationCode":
          handleCreateClick(e);
          break;
        default:
          break;
      }
    }
  }

  useEffect(() => {
    let score;

    // if (!password) {
    //     setPasswordStrength(null);
    //     return;
    // }

    if (!passwordSchema.current.validate(password)) {
      score = 1;
    } else {
      score = zxcvbn(password).score;
    }
    setPasswordStrength(score);
  }, [password]);

  useEffect(() => {
    let isIdentical = password == passwordRepeat;
    if (isIdentical != identicalPassword) {
      setIdenticalPassword(isIdentical);
    }
  }, [password, passwordRepeat]);

  useEffect(() => {
    if (username) {
      let isUsernameValid = validateUsername();
      setInvalidEmail(!isUsernameValid);
    }
  }, [username]);

  return (
    <div className="signup-grid-wrapper">
      <div
        className="signup-desc-wrapper"
        style={{ padding: "0px !important" }}
      >
        <Image className="signup-logo" src="/images/logo.png" />

        <div className="signup-head">
          <div className="signup-subheader">Mask your Identity.</div>
          <div className="signup-subheader2">Get peace of mind.</div>
        </div>
      </div>

      <div className="signup-form-wrapper">
        <div className="signup-form-header">Register New User</div>

        <div className="signup-form">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <PrimaryInput
                error={invalidEmail}
                disabled={inProgress}
                name="noidUsername"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                icon={require("../../assets/images/icon_user.svg")}
                inputRef={usernameRef}
                onKeyUp={handleKeyUp}
              />
              <div className="signup-input-tip">
                Between 6 and 42 characters (e.g adamo432)
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <PrimaryInput
                disabled={inProgress}
                error={
                  password &&
                  passwordStrength !== null &&
                  passwordStrength < acceptablePasswordStrength
                }
                type="password"
                icon={require("../../assets/images/icon_lock.svg")}
                name="noidPassword"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                inputRef={passwordRef}
                onKeyUp={handleKeyUp}
              />

              {password && (
                <div className="">
                  <div className="h-1 w-full bg-neutral-200 dark:bg-neutral-600">
                    <div
                      className="h-1 bg-primary"
                      style={{
                        width: `${
                          (passwordStrength / maxPasswordStrength) * 100
                        }%`,
                        backgroundColor: `${passwordStrengthMessage[passwordStrength].color}`,
                      }}
                    ></div>
                  </div>

                  <div className="signup-input-tip mt-3">
                    {passwordStrength <= 1
                      ? "Min. 8 length, at least one lowercase, uppercase, digit and symbol"
                      : "Password strength - " +
                        passwordStrengthMessage[passwordStrength].msg}
                  </div>
                </div>
              )}
            </div>

            <div className="flex flex-col gap-3">
              <PrimaryInput
                disabled={inProgress}
                error={passwordRepeat && !identicalPassword}
                type="password"
                icon={require("../../assets/images/icon_lock.svg")}
                name="noidPasswordRepeat"
                value={passwordRepeat}
                placeholder="Repeat Password"
                onChange={(e) => setPasswordRepeat(e.target.value)}
                inputRef={repeatPasswordRef}
                onKeyUp={handleKeyUp}
              />

              <div className="signup-password-warning">
                <Icon name="warning circle" />
                If you forget the password, we won't be able to reset it for
                you. Keep it safely.
              </div>
            </div>

            <PrimaryInput
              disabled={inProgress}
              error={invalidCode}
              icon={require("../../assets/images/icon-ticket.svg")}
              name="registrationCode"
              value={registrationCode}
              placeholder="Registration Code"
              onChange={(e) => setRegistrationCode(e.target.value)}
              inputRef={registrationCodeRef}
              onKeyUp={handleKeyUp}
            />

            <div
              className={
                "captcha-input " +
                (invalidCaptchaToken ? "captcha-input-invalid" : "")
              }
            >
              <HCaptcha
                sitekey={config.HCAPTCHA_SITE_KEY}
                onVerify={captchaTokenVerified}
                ref={captchaRef}
              />
            </div>

            {signUpFailed && (
              <div className="signup-error">
                Problem's occurred. Please try again
              </div>
            )}

            {/*{codeVerificationFailed && (*/}
            {/*<div style={{color: 'red', marginTop: '16px'}}>Invalid registration code. Check code once again or visit or page <a href="url">https://noid.today/</a> and ask for access.</div>*/}
            {/*)}*/}
          </div>

          <Button className="btn-signup" onClick={handleCreateClick}>
            {!inProgress && <span>Sign Up</span>}

            {inProgress && (
              <PulseLoader size={10} color={"white"} loading={true} />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
