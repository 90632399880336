import { Backend, IPhone } from "@no.id/web-common";
import { Device } from "@twilio/voice-sdk";
import noidWorkerApi from "../NoidWorkerAPI";
import {
  DeviceEventsHandler,
  PhoneCallDevice,
} from "../interface/phoneCallHandlers";

class PhoneDeviceUtils {
  async getIncomingCallToken(clientId: string): Promise<string | null> {
    if (!clientId) {
      return;
    }

    const sessionToken =
      await noidWorkerApi.AuthService.getPersonalisedSessionToken();

    return Backend.getIncomingCallToken({
      sessionToken,
      clientId: clientId,
    })
      .then((response) => {
        if (!response || !response.status || !response.token) {
          return;
        }
        return response.token;
      })
      .catch((err) => {
        console.error(
          "Problem with getting incoming call token. Error: " + err
        );
        return null;
      });
  }

  async getOutgoingCallToken(phone: IPhone): Promise<string | null> {
    const sessionToken =
      await noidWorkerApi.AuthService.getPersonalisedSessionToken();

    return Backend.getTokenForPhoneCall({
      sessionToken: sessionToken,
      phoneId: phone.id,
    })
      .then((response) => {
        if (!response || !response.status || !response.token) {
          return null;
        }
        return response.token;
      })
      .catch((err) => {
        console.log("Error getting phoneCall's token:" + err);
        return null;
      });
  }

  async registerDeviceForIncomingCall(
    phone: IPhone,
    deviceEventsHandler: DeviceEventsHandler
  ): Promise<PhoneCallDevice | null> {
    if (!phone || !phone.clientId) {
      return;
    }

    const incomingCallToken = await this.getIncomingCallToken(phone.clientId);
    if (!incomingCallToken) {
      return null;
    }

    const device = new Device(incomingCallToken, {
      sounds: {
        incoming: "/sounds/ringing_sound.mp3",
      },
    });

    device.on("tokenWillExpire", async () => {
      const incommingCallToken = await this.getIncomingCallToken(
        phone.clientId
      );
      device.updateToken(incommingCallToken);
      //TODO: add error handling
    });

    device.on("incoming", deviceEventsHandler.onIncoming);
    device.on("error", deviceEventsHandler.onDeviceError);
    device.register();

    return {
      device,
      clientId: phone.clientId,
    };
  }

  async registerDeviceForOutgoingCall(
    phone: IPhone,
    phoneCallToken: string,
    deviceEventsHandler: DeviceEventsHandler
  ): Promise<PhoneCallDevice | null> {
    const device = new Device(phoneCallToken);

    device.on("incoming", deviceEventsHandler.onIncoming);
    device.on("error", deviceEventsHandler.onDeviceError);

    return {
      device,
      clientId: phone.clientId,
    };
  }
}

export default new PhoneDeviceUtils();
