import { LiveQueryType, MailSummary, SingleMailMeta } from "@no.id/web-common";
import { proxy } from "comlink";
import _ from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import noidWorkerApi from "../NoidWorkerAPI";
import { EmailActionsContext, EmailsContext } from "../context/emailsContext";
import useInterval from "../hooks/useInterval";
import useLiveQuery from "../hooks/useLiveQuery";
import { useIdentities } from "./IdentityProvider";

const REFRESH_INTERVAL = 90000;

export function useEmailMessages() {
  return useContext(EmailsContext);
}

export function useEmailActions() {
  return useContext(EmailActionsContext);
}

export function EmailProvider({ children }) {
  const { identities } = useIdentities();

  const emailSummaries: MailSummary[] | undefined = useLiveQuery(
    LiveQueryType.ALL_MAIL_SUMMARY
  );
  const emailMetas: SingleMailMeta[] | undefined = useLiveQuery(
    LiveQueryType.ALL_MAIL_META
  );

  const [numberOfIdentityInProgress, setNumberOfIdentityInProgress] =
    useState(0);
  const [progress, setProgress] = useState(0);
  const [progressVisible, setProgressVisible] = useState(true);

  const [unreadMessageCounter, setUnreadMessageCounter] = useState(0);

  useEffect(() => {
    if (!identities) {
      return;
    }

    if (identities.length == 0) {
      setProgress(1);
    } else {
      setProgress(
        (identities.length - numberOfIdentityInProgress) / identities.length
      );
    }
    // setProgress(1 - inProgressByIdentity.current.filter(v => v)identities.length > 0 ? noProcessedIdentity / identities.length : 1);
  }, [numberOfIdentityInProgress]);

  useInterval(() => {
    getEmailSummaries(false, false);
  }, REFRESH_INTERVAL);

  useEffect(() => {
    getEmailSummaries(false, true);
  }, [identities]);

  const getEmailSummaries = async (
    forced = false,
    mailProgressVisible = true
  ) => {
    if (!identities) {
      return;
    }

    setNumberOfIdentityInProgress(identities.length);
    setProgressVisible(mailProgressVisible);

    await noidWorkerApi.MailboxService.checkForNewEmailSummariesForAllIdentities(
      identities,
      forced,
      proxy((loadedEmailSummaries) => {
        setNumberOfIdentityInProgress((prevValue) => prevValue - 1);
      })
    );
  };

  const getEmail = useCallback(
    async (mailId: string, identityId: string) => {
      const identity = _.find(identities, { id: identityId });
      return await noidWorkerApi.MailService.getSingleMail(
        mailId,
        identity,
        true,
        proxy((mail) => {})
      );
    },
    [identities]
  );

  const refresh = useCallback(() => {
    getEmailSummaries(true, true);
  }, [identities]);

  const markEmailAsRead = useCallback(async (mailId: string) => {
    return await noidWorkerApi.MailboxService.markEmailSummaryAsRead(mailId);
  }, []);

  const isAlreadyRead = useCallback(
    (mailId: string) => {
      return (
        emailMetas && emailMetas.find((m) => m.mailId === mailId)?.alreadyRead
      );
    },
    [emailMetas]
  );

  useEffect(() => {
    const counter = Math.max(
      (emailSummaries?.length || 0) - (emailMetas?.length || 0),
      0
    );
    setUnreadMessageCounter(counter);
  }, [emailMetas, emailSummaries]);

  const providerValue = useMemo(
    () => ({
      emailSummaries,
      progress,
      progressVisible,
      unreadMessageCounter,
    }),
    [emailSummaries, progress, progressVisible, unreadMessageCounter]
  );

  const emailActions = useMemo(
    () => ({
      refresh,
      getEmail,
      markEmailAsRead,
      isAlreadyRead,
    }),
    [refresh, getEmail, markEmailAsRead, isAlreadyRead]
  );

  return (
    <EmailsContext.Provider value={providerValue}>
      <EmailActionsContext.Provider value={emailActions}>
        {children}
      </EmailActionsContext.Provider>
    </EmailsContext.Provider>
  );
}
