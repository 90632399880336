import classNames from "classnames";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { TEModal, TEModalContent, TEModalDialog } from "tw-elements-react";
import { useIdentities } from "../../providers/IdentityProvider";
import {
  useOnboarding,
  useOnboardingUpdate,
} from "../../providers/OnboardingProvider";
import OnboardingProgressBar from "../onboardingProgressBar/OnboardingProgressBar";
import missions from "./OnboardingMissions";

const cancelOnboardingStyle = classNames(
  "flex flex-row flex-center items-center justify-center px-4 py-3 rounded-[12px] border-[1px] border-neutral-5",
  "text-[14px] leading-5 font-semibold text-white bg-red-600 order-3 min-[768px]:order-1 w-full md:w-auto"
);

const startMissionsStyle = classNames(
  "flex flex-row flex-center items-center justify-center px-4 py-3 rounded-[12px] border-[1px] border-neutral-5",
  "text-[14px] leading-5 font-semibold text-white bg-nile_blue order-2 min-[768px]:order-3 w-full md:w-auto"
);

const passwordStyle = classNames(
  "flex flex-row flex-center items-center justify-center px-4 py-3 rounded-[12px] border-[1px] border-neutral-5 outline-none",
  "text-[14px] leading-5 bg-white order-1 min-[768px]:order-2 w-full md:w-auto"
);

export default function OnboardingModal(props) {
  const [nextStepError, setNextStepError] = useState(undefined);
  const [password, setPassword] = useState("");
  const { isLoaded, onboarding } = useOnboarding();
  const { updateOnboardingStep } = useOnboardingUpdate();
  const [mission, setMission] = useState(missions[0]);
  const { identities } = useIdentities();

  useEffect(() => {
    const step = getCurrentStep();
    console.log("new mission: " + JSON.stringify(missions[step], null, 3));
    setMission(missions[step]);
  }, [isLoaded, onboarding]);

  const close = () => {
    setNextStepError(false);
    props.handleClose();
  };

  const next = () => {
    if (getCurrentStep() === missions.length - 1) {
      close();
      return;
    }

    if (mission.passwordProtected) {
      verifyPassword();
      return;
    }

    if (mission.identityProtected) {
      verifyIdentity();
      return;
    }

    setNextStep();
  };

  const verifyPassword = () => {
    if (password === mission.password) {
      setPassword("");
      setNextStepError(false);
      setNextStep();
    } else {
      setNextStepError(true);
    }
  };

  const verifyIdentity = () => {
    if (identities.find((i) => i.domain === mission.identityDomain)) {
      setNextStep();
    } else {
      setNextStepError(true);
      setTimeout(() => {
        setNextStepError(false);
      }, 2500);
    }
  };

  const getNextStepMessage = () => {
    switch (getCurrentStep()) {
      case 0:
        return "Start missions 😎";
      case missions.length - 1:
        return "Finish missions 😎";
      default:
        return "Continue missions 😎";
    }
  };

  const updatePassword = (value) => {
    setNextStepError(false);
    setPassword(value);
  };

  const setNextStep = () => {
    const nextStep = getCurrentStep() + 1;
    updateOnboardingStep(nextStep, (onboarding) => {
      console.log("Step updated, ", onboarding.step);
    });
  };

  const getCurrentStep = () => {
    return onboarding && onboarding[0] ? onboarding[0].step : 0;
  };

  // const getCurrentStep = () => {
  //     return 0;
  // }

  return (
    <>
      <TEModal show={props.show} setShow={() => null} onHide={close}>
        <TEModalDialog
          centered={true}
          theme={{
            sizeDefault: `min-[768px]:w-[600px] ${
              mission.isVideo ? "" : "max-[380px]:h-auto"
            } max-[768px]:h-full`,
          }}
        >
          <TEModalContent>
            <div className="px-6 py-10 bg-cyan">
              <div className="text-center">
                <div className="font-bold text-3xl">{mission.title}</div>
                <div className="pt-4 text-sm">
                  Big Tech and criminals will not learn all about you across
                  different platforms
                </div>

                <div className="pt-10">
                  <OnboardingProgressBar
                    missionsLength={missions.length}
                    step={getCurrentStep()}
                  />
                </div>
              </div>

              <div
                className={
                  mission.isVideo
                    ? "h-[240px] min-[768px]:h-[370px]"
                    : "h-[470px] min-[768px]:h-[440px]"
                }
              >
                {mission.url && (
                  <>
                    {mission.isVideo ? (
                      <ReactPlayer
                        className="py-4"
                        url={mission.url}
                        width="100%"
                        height="100%"
                        controls={true}
                        disableDeferredLoading={true}
                      />
                    ) : (
                      <iframe
                        className="pt-4 w-full h-[450px] min-[768px]:h-[420px]"
                        src={mission.url}
                      />
                    )}
                  </>
                )}

                {mission.description && <div>{mission.description}</div>}
              </div>

              <div className="text-center text-red-500">
                {mission.identityProtected && nextStepError && (
                  <span>Please create {mission.identityDomain} identity.</span>
                )}
              </div>

              <div className="flex flex-col items-center gap-1 min-[768px]:flex-row min-[768px]:justify-between">
                <button onClick={close} className={cancelOnboardingStyle}>
                  <div>Cancel onboarding</div>
                </button>

                <>
                  {mission.passwordProtected && (
                    <input
                      className={
                        passwordStyle + (nextStepError ? " border-red-500" : "")
                      }
                      type="text"
                      value={password}
                      placeholder="Enter answer here"
                      onChange={(e) => updatePassword(e.target.value)}
                    />
                  )}
                </>

                <button onClick={next} className={startMissionsStyle}>
                  <div>{getNextStepMessage()}</div>
                </button>
              </div>
            </div>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </>
  );
}
