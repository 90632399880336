import { ISentSms, ISms, LiveQueryType } from "@no.id/web-common";
import { Dictionary, countBy } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import noidWorkerApi from "../NoidWorkerAPI";
import { SmsActionsContext, SmsContext } from "../context/smsContext";
import useLiveQuery from "../hooks/useLiveQuery";
import { usePhones } from "./PhoneNumbersProvider";

const REFRESH_INTERVAL = 150000;

export function useSmsMessages() {
  return useContext(SmsContext);
}

export function useSmsActions() {
  return useContext(SmsActionsContext);
}

export function SmsProvider({ children }) {
  const { phones } = usePhones();
  const smsSummaries = useLiveQuery<ISms[]>(LiveQueryType.ALL_SMS);
  const smsSentMessages = useLiveQuery<ISentSms[]>(LiveQueryType.ALL_SMS_SENT);
  const [phonesWithFailures, setPhonesWithFailure] = useState<string[]>([]);
  const [phonesInitialized, setPhonesInitialized] = useState<string[]>([]);

  const [unreadTotalSmsCounter, setUnreadTotalSmsCounter] = useState<number>(0);
  const [unreadSmsCounterPerPhoneMap, setUnreadSmsCounterPerPhoneMap] =
    useState<Dictionary<number>>({});

  const inProgress = useRef({});

  useEffect(() => {
    getSmsMessages();

    const interval = setInterval(() => {
      getSmsMessages();
    }, REFRESH_INTERVAL);

    return () => clearInterval(interval);
  }, [phones]);

  const updateSmsUnreadCounters = useCallback(() => {
    const unreadTotal = smsSummaries?.filter((s) => !s.alreadyRead).length;
    setUnreadTotalSmsCounter(unreadTotal);

    const unreadCountersMap = countBy(
      smsSummaries?.filter((s) => !s.alreadyRead),
      "phoneId"
    );

    // smsSummaries
    //   ?.filter((s) => !s.alreadyRead)
    //   .forEach((m) => {
    //     console.log("UNREAD: " + JSON.stringify(m, null, 3));
    //   });

    setUnreadSmsCounterPerPhoneMap(unreadCountersMap);
  }, [smsSummaries, setUnreadTotalSmsCounter, setUnreadSmsCounterPerPhoneMap]);

  const getSmsMessages = async (forced = false) => {
    if (!phones) {
      return;
    }

    for (const phone of phones) {
      if (inProgress.current[phone.id]) {
        continue;
      }

      await noidWorkerApi.SmsService.getSmsMessages(forced, phone.id);

      inProgress.current[phone.id] = false;

      if (phonesWithFailures.includes(phone.id)) {
        setPhonesWithFailure((prevValue) =>
          prevValue.filter((pId) => pId !== phone.id)
        );
      }

      if (!phonesInitialized.includes(phone.id)) {
        setPhonesInitialized((prevValue) => [...prevValue, phone.id]);
      }
    }
  };

  const refresh = useCallback(() => {
    getSmsMessages(true);
  }, []);

  const modifyUnreadTotalSmsCounterBy = useCallback(
    (value: number) => {
      setUnreadTotalSmsCounter((currentValue) => currentValue + value);
    },
    [setUnreadTotalSmsCounter]
  );

  const markAlreadyRead = (smsId: string) =>
    noidWorkerApi.SmsService.markAlreadyRead(smsId);

  useEffect(() => {
    updateSmsUnreadCounters();
  }, [smsSummaries]);

  const providerValue = useMemo(
    () => ({
      smsSummaries,
      smsSentMessages,
      phonesWithFailures,
      phonesInitialized,
      unreadTotalSmsCounter,
      unreadSmsCounterPerPhoneMap,
    }),
    [
      smsSummaries,
      smsSentMessages,
      phonesWithFailures,
      phonesInitialized,
      unreadTotalSmsCounter,
      unreadSmsCounterPerPhoneMap,
    ]
  );

  const smsActions = useMemo(
    () => ({
      refresh,
      markAlreadyRead,
      modifyUnreadTotalSmsCounterBy,
    }),
    [refresh, markAlreadyRead, modifyUnreadTotalSmsCounterBy]
  );

  return (
    <SmsContext.Provider value={providerValue}>
      <SmsActionsContext.Provider value={smsActions}>
        {children}
      </SmsActionsContext.Provider>
    </SmsContext.Provider>
  );
}
