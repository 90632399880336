import { marked } from "marked";
import React, { useCallback, useMemo, useState } from "react";
import sanitizeHtml from "sanitize-html";
import styles from "./mailBody.module.scss";

const ALLOWED_TAGS = [
  "title",
  "h3",
  "h4",
  "h5",
  "h6",
  "blockquote",
  "p",
  "a",
  "ul",
  "ol",
  "nl",
  "li",
  "b",
  "i",
  "strong",
  "em",
  "strike",
  "code",
  "hr",
  "br",
  "div",
  "table",
  "thead",
  "caption",
  "tbody",
  "tr",
  "th",
  "td",
  "pre",
  "h2",
  "span",
];
const ALLOWED_TAGS_DANGEROUS = ALLOWED_TAGS.concat(["img"]);
const ALLOWED_ATTRIBUTES = {
  a: ["href", "name", "target"],
  "*": ["width", "height", "align", "valign", "cellspacing", "cellpadding"],
};
const ALLOWED_ATTRIBUTES_DANGEROUS = {
  a: ["href", "name", "target"],
  img: ["src"],
  "*": [
    "style",
    "width",
    "height",
    "align",
    "valign",
    "cellspacing",
    "cellpadding",
  ],
};
const ALLOWED_SCHEMES_DANGEROUS = ["data", "http", "https"];

export default function MailBody(props) {
  const [showDangerousMail, setShowDangerousMail] = useState(false);
  const [showTextOnly] = useState(
    () => props.mail && !props.mail.html && props.mail.text
  );

  const sanitizedHTML = useMemo(() => {
    if (!props.mail.html) {
      return "";
    }

    return sanitizeHtml(props.mail.html, {
      allowedTags: ALLOWED_TAGS_DANGEROUS,
      allowedAttributes: ALLOWED_ATTRIBUTES_DANGEROUS,
      allowedSchemes: ALLOWED_SCHEMES_DANGEROUS,
      transformTags: {
        a: sanitizeHtml.simpleTransform("a", { target: "_blank" }),
      },
    });
  }, [props.mail]);

  const dangerousButSlightlySanitizedHTML = useMemo(() => {
    if (!props.mail.html) {
      return "";
    }
    return sanitizeHtml(props.mail.html, {
      allowedTags: ALLOWED_TAGS_DANGEROUS,
      allowedAttributes: ALLOWED_ATTRIBUTES_DANGEROUS,
      allowedSchemes: ALLOWED_SCHEMES_DANGEROUS,
      transformTags: {
        a: sanitizeHtml.simpleTransform("a", { target: "_blank" }),
      },
    });
  }, [props.mail]);

  const isMailDangerous = useCallback(() => {
    const content = props.mail.html;

    const allowedContent = sanitizeHtml(content, {
      allowedTags: ALLOWED_TAGS,
      allowedAttributes: ALLOWED_ATTRIBUTES,
    });

    const dangerousContent = sanitizeHtml(content, {
      allowedTags: ALLOWED_TAGS_DANGEROUS,
      allowedAttributes: ALLOWED_ATTRIBUTES_DANGEROUS,
    });

    return props.mail.html && allowedContent !== dangerousContent;
  }, [props.mail.html]);

  const demarkedText = useMemo(() => {
    if (props.mail.html || !props.mail.text) {
      return undefined;
    }

    const demarkedTextMail = marked.parse(props.mail.text);
    return sanitizeHtml(demarkedTextMail, {
      allowedTags: ALLOWED_TAGS_DANGEROUS,
      allowedAttributes: ALLOWED_ATTRIBUTES_DANGEROUS,
      allowedSchemes: ALLOWED_SCHEMES_DANGEROUS,
      transformTags: {
        a: sanitizeHtml.simpleTransform("a", { target: "_blank" }),
      },
    });
  }, [props.mail]);

  return (
    <div className={styles.contentWrapper}>
      {isMailDangerous && !showDangerousMail && !showTextOnly && (
        <div className={styles.dangerousInfoWrapper}>
          <div className={styles.dangerousInfo}>
            <div className={styles.dangerousInfoText}>
              Images, scripts, and a few more elements can contain tracking
              tags. We've removed them to protect your privacy
            </div>
            <button
              className={styles.dangerousInfoButton}
              onClick={() => setShowDangerousMail(true)}
            >
              View Original Message
            </button>
          </div>
        </div>
      )}

      <div className={styles.bodyWrapper}>
        {props.mail.html && showDangerousMail && (
          <div
            dangerouslySetInnerHTML={{
              __html: dangerousButSlightlySanitizedHTML,
            }}
          />
        )}

        {props.mail.html && !showDangerousMail && (
          <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
        )}

        {showTextOnly && demarkedText && (
          <div dangerouslySetInnerHTML={{ __html: demarkedText }} />
        )}

        {showTextOnly && !demarkedText && (
          <div className="mail-text-body">{props.mail.text}</div>
        )}
      </div>
    </div>
  );
}
