import classNames from "classnames";
import { trackGoal } from "fathom-client";
import React, { ReactNode, useEffect, useState } from "react";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { useHistory, useLocation } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import noidWorkerApi from "../../NoidWorkerAPI";
import TwilioDevice from "../../TwilioDevice";
import AnalyticsEvents from "../../events/analyticsEvents";
import { useEmailMessages } from "../../providers/EmailProvider";
import { useSmsMessages } from "../../providers/SmsProvider";
import GetExtensionView from "../GetExensionView/GetExtensionView";
import MenuItem from "../menuItem/MenuItem";
import OnboardingMenu from "../onboardingMenu/OnboardingMenu";
import OnboardingModal from "../onboardingModal/OnboardingModal";
import "./Menu.scss";

const NotificationIndicator = (value: number): ReactNode => {
  if (value <= 0) return null;
  return (
    <div className="rounded-[6px] bg-[#F4484E] text-white py-[2px] px-2 text-[12px] font-semibold heading-[16px]">
      {value}
    </div>
  );
};

export default function Menu() {
  let location = useLocation();
  const history = useHistory();
  const { unreadMessageCounter } = useEmailMessages();
  const { smsSummaries, unreadTotalSmsCounter } = useSmsMessages();
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(isDesktop);

  const { width, height } = useWindowSize();

  const [active, setActive] = useState(() => {
    switch (location.pathname) {
      case "/main":
        return "main";
      case "/phones":
        return "phones";
      case "/message":
        return "message";
      default:
        return "main";
    }
  });

  const initializeOnboarding = () => {
    const params = new URLSearchParams(window.location.search);

    if (params.has("onboarding")) {
      setOnboardingModalOpen(true);
    }
  };

  useEffect(() => {
    initializeOnboarding();
  }, [onboardingModalOpen]);

  useEffect(() => {
    if (isOpen && width <= 1024) {
      setIsOpen(false);
    } else if (width >= 1024) {
      setIsOpen(true);
    }
  }, [width]);

  // const unreadSmsCounter = useMemo(
  //   () =>
  //     smsSummaries?.reduce((unreadCounter, summary, idx, array) => {
  //       return unreadCounter + (summary.alreadyRead ? 0 : 1);
  //     }, 0),
  //   [smsSummaries]
  // );

  const handleFathomEventName = (name: string) => {
    switch (name) {
      case "main":
        // @ts-ignore
        return AnalyticsEvents.WEB_IDENTITIES_CLICKED;
      case "phones":
        // @ts-ignore
        return AnalyticsEvents.WEB_PHONES_CLICKED;
      case "message":
        // @ts-ignore
        return AnalyticsEvents.WEB_MAILS_CLICKED;
    }
  };

  const handleClick = (name) => () => {
    setActive(name);

    // if(name == 'phones') {
    //     this.context.updateNewSmsFlag(false);
    // }
    // if(name == 'message') {
    //     // do nothing
    // }

    if (isTablet || isMobile) {
      setIsOpen(false);
    }

    trackGoal(handleFathomEventName(name), 0);
    history.push("/" + name);
  };

  //XXX:
  const logout = async () => {
    // @ts-ignore
    trackGoal(AnalyticsEvents.WEB_LOGOUT, 0);

    await noidWorkerApi.AuthService.logout();
    TwilioDevice.destroyAllDevices();
  };

  return (
    <>
      {isOpen && (
        <div className="absolute z-20 w-screen h-screen bg-nile_blue backdrop-blur-sm md:hidden opacity-60"></div>
      )}
      <div
        className={classNames(
          "z-30 flex flex-col absolute",
          "items-center px-3 border-r-[1px] border-r-neutral-0 bg-white",
          "w-screen h-fit items-center ",
          "lg:w-[256px] lg:h-screen lg:items-start lg:border-b-0",
          "border-b-[1px] border-b-neutral-0 lg:border-b-0",
          { "rounded-b-[12px]": isOpen }
        )}
      >
        <div className="flex items-center lg:items-start justify-between px-3 lg:px-0 h-[64px] w-full lg:mt-[64px] lg:mb-[64px]">
          <img className="h-5 pl-3" src="/images/logo2.png" />
          <img
            className="h-6 lg:hidden cursor-pointer"
            src={require(isOpen
              ? "../../assets/images/icon-close.svg"
              : "../../assets/images/icon-menu.svg")}
            onClick={() => setIsOpen((v) => !v)}
          />
        </div>

        {isOpen && (
          <div className="h-max lg:h-screen w-full pt-4 lg:pt-0 flex flex-col gap-4 lg:gap-0">
            <MenuItem
              isActive={active === "main"}
              icon={require("../../assets/images/icon-identity.svg")}
              iconActive={require("../../assets/images/icon-identity-active.svg")}
              title="Identities"
              onClick={handleClick("main")}
            />

            <MenuItem
              isActive={active === "message"}
              icon={require("../../assets/images/icon-messages.svg")}
              iconActive={require("../../assets/images/icon-messages-active.svg")}
              title="Messages"
              onClick={handleClick("message")}
              extraButton={NotificationIndicator(unreadMessageCounter)}
            />

            <MenuItem
              isActive={active === "phones"}
              icon={require("../../assets/images/icon-phone.svg")}
              iconActive={require("../../assets/images/icon-phone-active.svg")}
              title="Phones"
              onClick={handleClick("phones")}
              extraButton={NotificationIndicator(unreadTotalSmsCounter)}
            />

            <OnboardingMenu open={() => setOnboardingModalOpen(true)} />

            <div className="lg:absolute lg:bottom-0 lg:left-0 w-full items-start mt-6 lg:mt-0 lg:px-3 mb-6 flex flex-col gap-10 ">
              <GetExtensionView />

              <MenuItem
                isActive={false}
                icon={require("../../assets/images/icon-logout.svg")}
                iconActive={require("../../assets/images/icon-logout.svg")}
                title="Logout"
                onClick={logout}
              />
            </div>
          </div>
        )}

        {onboardingModalOpen && (
          <OnboardingModal
            show={onboardingModalOpen}
            handleClose={() => setOnboardingModalOpen(false)}
          />
        )}
      </div>
    </>
  );
}
