import { MailSummary } from "@no.id/web-common";
import { createContext } from "react";

export interface EmailsContextProps {
  emailSummaries: MailSummary[];
  progress: number;
  progressVisible: boolean;
  unreadMessageCounter: number;
}

export interface EmailsActionsContextProps {
  refresh: () => void;
  getEmail: (mailId: string, identityId: string) => Promise<void>;
  markEmailAsRead: (mailId: string) => Promise<void>;
  isAlreadyRead: (mailId: string) => boolean;
}

const defaultEmailsContext: EmailsContextProps = {
  emailSummaries: [],
  progress: 0,
  progressVisible: false,
  unreadMessageCounter: 0,
};

const defaultEmailsActionsContext: EmailsActionsContextProps = {
  refresh: () => {
    throw new Error("Not implemeneted");
  },
  getEmail: (mailId: string, identityId: string) => {
    throw new Error("Not implemented");
  },
  markEmailAsRead: (mailId: string) => {
    throw new Error("Not implemented");
  },
  isAlreadyRead: (mailId: string) => {
    throw new Error("Not implemented");
  },
};

export const EmailsContext =
  createContext<EmailsContextProps>(defaultEmailsContext);

export const EmailActionsContext = createContext<EmailsActionsContextProps>(
  defaultEmailsActionsContext
);
