import { IPhone } from "@no.id/web-common";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import {
  TEModal,
  TEModalBody,
  TEModalContent,
  TEModalDialog,
  TEModalFooter,
  TEModalHeader,
} from "tw-elements-react";
import { usePhonesActions } from "../providers/PhoneNumbersProvider";
import InfoView from "./InfoView";

const btnConfirm = classNames(
  "flex flex-row flex-center items-center justify-center px-4 py-3 rounded-[12px] border-[1px] border-neutral-5",
  "text-[14px] leading-5 font-semibold text-white bg-rose-700"
);

const btnCancel = classNames(
  "flex flex-row flex-center items-center justify-center px-4 py-3 rounded-[12px] border-[1px] border-neutral-5",
  "text-[14px] leading-5 font-semibold text-black bg-neutral-50"
);

export interface DeactivatePhoneModalProps {
  show: boolean;
  phone: IPhone;
  onClose: () => void;
}

export default function DeactivatePhoneModal({
  show,
  phone,
  onClose,
}: DeactivatePhoneModalProps) {
  const [error, setError] = useState<string>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleted, setIsDeleted] = useState<boolean>();

  const { deactivatePhone } = usePhonesActions();

  const onDeletePhone = useCallback(() => {
    setIsDeleting(true);
    deactivatePhone(phone).then((result) => {
      if (result) {
        setError(undefined);
        setIsDeleted(true);
        setTimeout(() => {
          setIsDeleted(undefined);
          onClose();
        }, 2500);
      } else {
        setError("An error occurred, try again.");
        setTimeout(() => {
          setIsDeleted(undefined);
          setError(undefined);
        }, 1500);
      }
      setIsDeleting(false);
    });
  }, []);

  return (
    <TEModal
      show={show}
      backdrop={false}
      className="bg-neutral-100/80 pt-[10vh]"
    >
      <TEModalDialog
        className="absolute z-50"
        theme={{
          sizeDefault: "max-w-[400px]",
        }}
      >
        <TEModalContent className="border-2 border-red-600">
          <TEModalHeader className="border-none py-4">
            <h5 className="text-xl font-semibold">Delete number</h5>
          </TEModalHeader>
          <TEModalBody className="border-none">
            <p className="py-3 ">
              Are you sure and want to delete this phone number?
            </p>
            {error && <InfoView message={error} type="error" />}
            {isDeleted && (
              <InfoView message="Your number has been deleted" type="success" />
            )}
          </TEModalBody>
          <TEModalFooter className="border-none">
            {isDeleted === undefined && !error && (
              <div className="flex flex-col gap-3 w-full">
                <button
                  type="button"
                  className={btnConfirm}
                  onClick={onDeletePhone}
                  disabled={isDeleting}
                >
                  Delete this number
                </button>
                <button
                  type="button"
                  className={btnCancel}
                  onClick={onClose}
                  disabled={isDeleting}
                >
                  No, thanks
                </button>
              </div>
            )}
          </TEModalFooter>
        </TEModalContent>
      </TEModalDialog>
    </TEModal>
  );
}
