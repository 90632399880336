const dev = {
  APP_CODE: "VYXGKAHH",
  WEB_REGISTRATION_CLICKED: "XWTXJHSS",
  WEB_REGISTRATION_SUCCESS: "SZPXUS9U",
  WEB_REGISTRATION_FAILED: "BA6OZDBE",
  WEB_LOGIN_CLICKED: "XN1GVEBT",
  WEB_LOGIN_SUCCESS: "GE0Q7PDH",
  WEB_LOGIN_FAILED: "RAFESQHG",
  WEB_IDENTITIES_CLICKED: "X7R4KEQY",
  WEB_MAILS_CLICKED: "EGTGBOGK",
  WEB_PHONES_CLICKED: "8XABTBUN",
  WEB_LOGOUT: "XPGQVFWU",
  WEB_COPY_PASSWORD: "6TYQUYBQ",
  WEB_COPY_IDENTITY: "YBPUGDO1",
  WEB_MAILS_REFRESHED: "EXGG77KZ",
  WEB_MAIL_ENTERED: "M8LKCYK0",
  WEB_MAIL_READ: "KORKY3Z4",
  WEB_PHONE_BUY: "2U3Y6HWI",
  WEB_PHONE_BUY_SUCCESS: "B43PYVD2",
  WEB_PHONE_BUY_FAILED: "HXFTAQGR",
  WEB_PHONE_MESSAGES_ENTERED: "QA35QQ8N",
  WEB_PHONE_CALL_CLICKED: "3DTGXCJR",
  WEB_PHONE_COST_CLICKED: "RXJLMVT5",
  WEB_PHONE_COST_SUCCESS: "LO9QEQUY",
  WEB_PHONE_COST_FAILED: "VIFKAT37",
  WEB_CALL_STARTED: "J5FHKVOY",
  WEB_CALL_ENDED: "DJ1CFOQW",
};

const test = {
  APP_CODE: "UELGKSKM",
  WEB_REGISTRATION_CLICKED: "YX83EQ7A",
  WEB_REGISTRATION_SUCCESS: "VPVSYANA",
  WEB_REGISTRATION_FAILED: "YRM8IUMF",
  WEB_LOGIN_CLICKED: "LQLFZN7Y",
  WEB_LOGIN_SUCCESS: "ZXKDCYDR",
  WEB_LOGIN_FAILED: "DXPM87SZ",
  WEB_IDENTITIES_CLICKED: "118PZMDH",
  WEB_MAILS_CLICKED: "Q7SHXEQZ",
  WEB_PHONES_CLICKED: "K2S5YNJA",
  WEB_LOGOUT: "MRWDUM8T",
  WEB_COPY_PASSWORD: "7QHNES6V",
  WEB_COPY_IDENTITY: "PLI2L6QW",
  WEB_MAILS_REFRESHED: "FES9UQRN",
  WEB_MAIL_ENTERED: "SOYBXTCT",
  WEB_MAIL_READ: "UFEFJCBO",
  WEB_PHONE_BUY: "NY0AWDLT",
  WEB_PHONE_BUY_SUCCESS: "AOTMXTM7",
  WEB_PHONE_BUY_FAILED: "DLTXX68D",
  WEB_PHONE_MESSAGES_ENTERED: "RRE2UHY0",
  WEB_PHONE_CALL_CLICKED: "GKIJAZIA",
  WEB_PHONE_COST_CLICKED: "QTYBNFWO",
  WEB_PHONE_COST_SUCCESS: "E5JBHVM7",
  WEB_PHONE_COST_FAILED: "9BL7WVQM",
  WEB_CALL_STARTED: "RQCVQE1I",
  WEB_CALL_ENDED: "R4CMZLPH",
};

const prod = {
  APP_CODE: "KFSBVVDT",
  WEB_REGISTRATION_CLICKED: "RATOWXC6",
  WEB_REGISTRATION_SUCCESS: "O4BY025Z",
  WEB_REGISTRATION_FAILED: "YECGYYR7",
  WEB_LOGIN_CLICKED: "GDE5AH7X",
  WEB_LOGIN_SUCCESS: "ILB46VHH",
  WEB_LOGIN_FAILED: "6D7RCL5Q",
  WEB_IDENTITIES_CLICKED: "NG48NX7K",
  WEB_MAILS_CLICKED: "G7Z6BAFV",
  WEB_PHONES_CLICKED: "RAJQ2TDR",
  WEB_LOGOUT: "CWRZ4W76",
  WEB_COPY_PASSWORD: "1OHC8W1O",
  WEB_COPY_IDENTITY: "TGCPYMKX",
  WEB_MAILS_REFRESHED: "L1VHP1S2",
  WEB_MAIL_ENTERED: "JYY3AMJX",
  WEB_MAIL_READ: "YGUU6VWF",
  WEB_PHONE_BUY: "K3H6XIWS",
  WEB_PHONE_BUY_SUCCESS: "94SHLGN7",
  WEB_PHONE_BUY_FAILED: "XJOX1IOZ",
  WEB_PHONE_MESSAGES_ENTERED: "IWY39HX1",
  WEB_PHONE_CALL_CLICKED: "O2NWF0OM",
  WEB_PHONE_COST_CLICKED: "XZSOZJPG",
  WEB_PHONE_COST_SUCCESS: "N7YJGENF",
  WEB_PHONE_COST_FAILED: "OXXPCI73",
  WEB_CALL_STARTED: "PMXVUUGM",
  WEB_CALL_ENDED: "DMPLZDL0",
};

function getConfig() {
  const stage = !process.env.REACT_APP_STAGE
    ? ""
    : process.env.REACT_APP_STAGE.trim();
  switch (stage) {
    case "dev":
      return dev;
    case "test":
      return test;
    case "production":
      return prod;
    default:
      return {};
  }
}

const AnalyticsEvents = getConfig();

export default {
  ...AnalyticsEvents,
};
