import React, { useState } from "react";
import { useUserProfile } from "../../providers/UserProfileProvider";
import FundCreditsModal from "../FundCreditsModal";
import "./CreditPanel.scss";

export default function CreditPanel(props) {
  const credits = useUserProfile().credits;

  const [isFundModalOpen, setIsFundModalOpen] = useState(false);

  return (
    <div className="flex flex-col gap-4 py-6 lg:p-6">
      <div className="text-neutral-100 text-[14px] font-medium leading-5">
        Credits:{" "}
      </div>
      <div className="flex flex-row items-center gap-4">
        <div className="text-neutral-100 text-[32px] font-bold leading-10">
          {credits ? credits.toFixed(2) : 0}
        </div>
        <button
          className="bg-neutral-100 h-8 w-8 p-2 flex justify-center items-center rounded-[12px] text-white hover:bg-warning-50"
          onClick={() => setIsFundModalOpen(true)}
        >
          <img
            src={require("../../assets/images/icon-plus.svg")}
            className="h-4"
          />
        </button>
        <FundCreditsModal
          open={isFundModalOpen}
          onClose={() => setIsFundModalOpen(false)}
        />
      </div>
    </div>
  );
}
