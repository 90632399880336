import React from "react";

export function TrashIcon({
  color = "#153F4C",
  size = 21,
}: {
  color?: string;
  size?: number;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33398 6.70469L16.6673 6.70469"
        stroke={color}
        stroke-width="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33268 10.038L8.33268 15.038"
        stroke={color}
        stroke-width="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 10.038L11.6667 15.038"
        stroke={color}
        stroke-width="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16602 6.70465L4.99935 16.7047C4.99935 17.6251 5.74554 18.3713 6.66602 18.3713H13.3327C14.2532 18.3713 14.9993 17.6251 14.9993 16.7047L15.8327 6.70465"
        stroke={color}
        stroke-width="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 6.70467L7.5 4.20467C7.5 3.74443 7.8731 3.37134 8.33333 3.37134L11.6667 3.37134C12.1269 3.37134 12.5 3.74443 12.5 4.20467V6.70467"
        stroke={color}
        stroke-width="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
