import { IPhone } from "@no.id/web-common";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import {
  TEModal,
  TEModalBody,
  TEModalContent,
  TEModalDialog,
  TEModalFooter,
  TEModalHeader,
} from "tw-elements-react";
import { usePhonesActions } from "../providers/PhoneNumbersProvider";
import InfoView from "./InfoView";

const btnConfirm = classNames(
  "flex flex-row flex-center items-center justify-center px-4 py-3 rounded-[12px] border-[1px] border-neutral-5",
  "text-[14px] leading-5 font-semibold text-white bg-rose-700"
);

const btnCancel = classNames(
  "flex flex-row flex-center items-center justify-center px-4 py-3 rounded-[12px] border-[1px] border-neutral-5",
  "text-[14px] leading-5 font-semibold text-black bg-neutral-50"
);

export interface RenewPhoneModalProps {
  show: boolean;
  phone: IPhone;
  onClose: () => void;
}

export default function RenewPhoneModal({
  show,
  phone,
  onClose,
}: RenewPhoneModalProps) {
  const [error, setError] = useState<string>();
  const [isRenewing, setIsRenewing] = useState(false);
  const [isDeleted, setIsDeleted] = useState<boolean>();

  const { renewPhone } = usePhonesActions();

  const onRenewPhone = useCallback(() => {
    setIsRenewing(true);
    renewPhone(phone).then((result) => {
      if (result) {
        setError(undefined);
        setIsDeleted(true);
        setTimeout(() => {
          setIsDeleted(undefined);
          onClose();
        }, 2500);
      } else {
        setError("An error occurred, try again.");
        setTimeout(() => {
          setIsDeleted(undefined);
          setError(undefined);
        }, 1500);
      }
      setIsRenewing(false);
    });
  }, []);

  return (
    <div>
      <TEModal
        show={show}
        backdrop={false}
        className="bg-neutral-100/80 pt-[10vh]"
      >
        <TEModalDialog
          theme={{
            sizeDefault: "max-w-[400px]",
          }}
        >
          <TEModalContent>
            <TEModalHeader className="border-none py-4">
              <h5 className="text-xl font-semibold">Renew number</h5>
            </TEModalHeader>
            <TEModalBody className="border-none">
              Are you sure and want to renew this phone number?
              {error && <InfoView message={error} type="error" />}
              {isDeleted && (
                <InfoView
                  message="Your number has been renewed"
                  type="success"
                />
              )}
            </TEModalBody>
            <TEModalFooter className="border-none">
              {isDeleted === undefined && !error && (
                <div className="grid w-full">
                  <button
                    type="button"
                    className={btnConfirm}
                    onClick={onRenewPhone}
                    disabled={isRenewing}
                  >
                    Renew this number
                  </button>
                  <button type="button" className={btnCancel} onClick={onClose}>
                    No, thanks
                  </button>
                </div>
              )}
            </TEModalFooter>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </div>
  );
}
