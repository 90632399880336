import { AttachmentUtils } from "@no.id/web-common";
import classNames from "classnames";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import Select from "react-select";
import validator from "validator";
import noidWorkerApi from "../../NoidWorkerAPI";
import {
  useIdentities,
  useIdentitiesUpdate,
} from "../../providers/IdentityProvider";
import Modal from "../Modal";
import NewIdentityRequiredModal from "../NewIdentityRequiredModal";
import { AttachmentIcon } from "../common/icons/AttachmentIcon";
import { CheckIcon } from "../common/icons/CheckIcon";
import { CloseIcon } from "../common/icons/CloseIcon";
import { InfoIcon } from "../common/icons/InfoIcon";
import { PencilIcon } from "../common/icons/PencilIcon";
import { SendIcon } from "../common/icons/SendIcon";
import { TrashIcon } from "../common/icons/TrashIcon";
import { WarningIcon } from "../common/icons/WarningIcon";
import EmailSentModal from "../emailSentModal/emailSentModal";
import IdentityChooseModal from "../identityChooseModal";
import IdentityDeanonymizationModal from "../identityDeanonymizationModal/identityDeanonymizationModal";
import IdentitySelectView from "../identitySelectView/identitySelectView";
import MailSendEditor from "../mailSendEditor/mailSendEditor";

const btnMailStyle = classNames(
  "flex flex-row flex-center gap-2 items-center px-5 py-2.5 rounded-[8px]",
  "text-[14px] leading-5 font-semibold text-white bg-nile_blue"
);

const selectIdentityStyle = {
  control: (base, state) => ({
    ...base,
    border: "0",
  }),
};

const CustomOption = ({ innerProps, isDisabled, data }) =>
  !isDisabled ? (
    <div {...innerProps} className="">
      {data.content}
    </div>
  ) : null;

export interface MailSendModalProps {
  onClose: () => void;
}

export default function MailSendModal({ onClose }: MailSendModalProps) {
  const { identities } = useIdentities();
  const [isLoading, setIsLoading] = useState(false);
  const [sendMailError, setSendMailError] = useState(undefined);
  const [sender, setSender] = useState<string>();
  const [filteredSenders, setFilteredSenders] = useState([]);
  const [senderAutomatic, setSenderAutomatic] = useState(true);
  const [invalidSender, setInvalidSender] = useState(false);
  const [recipient, setRecipient] = useState("");
  const [invalidRecipient, setInvalidRecipient] = useState(false);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("<p/>");
  const [attachments, setAttachments] = useState([]);
  const [openNewIdentityRequiredModal, setOpenNewIdentityRequiredModal] =
    useState(false);
  const [openRiskDeanonymizationModal, setOpenRiskDeanonymizationModal] =
    useState(false);
  const [identityChooseModal, setIdentityChooseModal] = useState(false);
  const [emailSentModal, setEmailSentModal] = useState(false);

  const { generateNewIdentity } = useIdentitiesUpdate();

  const close = () => {
    setSendMailError(undefined);
    setSender(null);
    setRecipient("");
    setSubject("");
    setBody("<p/>");
    setAttachments([]);
    setInvalidRecipient(false);
    setInvalidSender(false);
    setSenderAutomatic(true);
    setIsLoading(false);
    setFilteredSenders([]);
    setOpenNewIdentityRequiredModal(false);
    setOpenRiskDeanonymizationModal(false);
    setIdentityChooseModal(false);
    setEmailSentModal(false);
    onClose();
  };

  const verifyAndSendMessage = async () => {
    const formValid = validateForm();
    if (!formValid) {
      return;
    }
    setSendMailError(undefined);

    if (!sender) {
      setOpenNewIdentityRequiredModal(true);
    } else {
      await sendMail();
    }
  };

  const generateNewIdentityAsSender = async () => {
    const recipientDomain = getDomainFromEmail(recipient);
    try {
      const newIdentity = await generateNewIdentity(
        recipientDomain,
        "New identity"
      );
      setSender(newIdentity);
    } catch (err) {
      setSendMailError(err);
    }
    setOpenNewIdentityRequiredModal(false);
  };

  const showIdentityDeanonymizationModal = () => {
    if (senderAutomatic) {
      setOpenRiskDeanonymizationModal(true);
    } else {
      setSenderAutomatic(true);
    }
  };

  const handleIdentityDeanonymizationModal = () => {
    setSenderAutomatic(false);
    setOpenRiskDeanonymizationModal(false);
  };

  const handleIdentityChooseModal = (id: string) => {
    setSender(id);
    setIdentityChooseModal(false);
  };

  const sendMail = async () => {
    setIsLoading(true);
    const attachmentFiles = await Promise.all(
      attachments.map((f) => AttachmentUtils.fromFile(f))
    );
    const htmlBody = body.replace(/\n/g, "<br />");
    noidWorkerApi.MailService.sendMail(
      sender,
      recipient,
      subject,
      htmlBody,
      attachmentFiles
    ).then((result) => {
      setIsLoading(false);
      if (result) {
        openEmailSentModal();
      } else {
        setSendMailError("An error occurred, try again.");
      }
    });
  };

  const openEmailSentModal = () => {
    setEmailSentModal(true);
    setTimeout(() => {
      setEmailSentModal(false);
      close();
    }, 2000);
  };

  const validateForm = () => {
    const senderInvalid =
      (!senderAutomatic || filteredSenders.length > 0) && !sender;
    const recipientInvalid = !recipient || !validator.isEmail(recipient);

    setInvalidSender(senderInvalid);
    setInvalidRecipient(recipientInvalid);

    return !senderInvalid && !recipientInvalid;
  };

  const identityOptions = useMemo(() => {
    return _.map(_.uniqBy(identities, "id"), (id) => {
      return {
        value: id.id,
        label: id.note
          ? `${id.note} (Identity for ${id.domain})`
          : `Dedicated Identity for ${id.domain}`,
        content: <IdentitySelectView identity={id} />,
      };
    });
  }, [identities]);

  const handleRecipientChange = (value) => {
    if (senderAutomatic) {
      const recipientDomain = getDomainFromEmail(value);
      const filteredIdentities = _.filter(
        identities,
        (i) =>
          i.domain === recipientDomain ||
          i.trustedDomains.includes(recipientDomain)
      );
      if (filteredIdentities.length === 1) {
        setSender(filteredIdentities[0].id);
        setFilteredSenders([]);
      } else if (filteredIdentities.length > 1) {
        setIdentityChooseModal(true);
        setFilteredSenders(filteredIdentities);
        setSender(null);
      } else {
        setFilteredSenders([]);
        setSender(null);
      }
    }
    setRecipient(value);
  };

  function getDomainFromEmail(email: string) {
    const pos = email.lastIndexOf("@") + 1;
    return email.substr(pos);
  }

  const onSenderChange = (identity, action) => {
    // trackGoal(AnalyticsEvents.WEB_MAIL_ENTERED, 0);
    setSender(identity?.value);
    setFilteredSenders([]);
  };

  const removeAttachment = (attachment) => {
    setAttachments(attachments.filter((a) => a.name !== attachment.name));
  };

  return (
    <>
      <Modal isOpen={true} onClose={close} style="w-full h-full">
        <div className="w-full absolute bottom-7 px-2 md:px-4 min-[1024px]:px-0 min-[1024px]:right-7 min-[1024px]:max-w-[600px] min-[1200px]:max-w-[800px]">
          <div className="relative">
            <div className="p-6 !rounded-[12px] shadow-2xl shadow-cyan-500/50 bg-white">
              <div className="p-unset flex flex-row justify-between pb-6 items-center">
                <div className="text-xl font-semibold text-neutral-100">
                  New message
                </div>

                <div
                  onClick={onClose}
                  aria-label="Close"
                  className="p-2 cursor-pointer"
                >
                  <CloseIcon />
                </div>
              </div>

              <div
                className={`flex justify-between items-center pt-2 pb-3 border-b-[1px] ${
                  invalidSender ? "border-error-50" : "border-neutral-0"
                }`}
              >
                <div className="flex-1 flex flex-row gap-2 items-center">
                  <div className="text-silver_sand text-sm font-medium leading-5">
                    From
                  </div>
                  {!sender && senderAutomatic && (
                    <div className="flex items-center gap-2">
                      <InfoIcon />
                      <div className="text-xs leading-4 md:text-sm md:leading-5 font-medium text-neutral-80">
                        The sender will be matched after entering the
                        destination address.
                      </div>
                    </div>
                  )}
                  {sender && senderAutomatic && (
                    <div className="flex-1 flex flex-row gap-2">
                      <div className="sm:px-2">
                        <CheckIcon />
                      </div>
                      <div className="flex items-center text-xs sm:text-base">
                        Sender matched automatically
                      </div>
                    </div>
                  )}
                  {!senderAutomatic && (
                    <div className="flex-1 flex flex-row focus-visible:border-2">
                      <div className="flex items-center px-1">
                        <WarningIcon />
                      </div>
                      <Select
                        // className="border-0 focus:border-2 active:border-2 focus-visible:border-2"
                        classNames={{
                          container: () => "w-full rounded-xl",
                          control: () => "shadow-none",
                          singleValue: () =>
                            "text-sm font-sans text-neutral-80 leading-5 font-medium",
                          menuList: () =>
                            "max-h-[260px]  shadow-brand rounded-xl",
                          menu: () =>
                            "rounded-xl border-[1px] border-white z-20 w-[85vw] ml-[-20vw] sm:w-full sm:ml-0",
                          menuPortal: () =>
                            "rounded-xl border-3 border-blue-400",
                          valueContainer: () => "pl-1",
                          placeholder: () =>
                            "text-neutral-80 font-sans text-sm font-medium leading-5 cursor-pointer ",
                        }}
                        styles={selectIdentityStyle}
                        placeholder="Choose sender manually"
                        options={identityOptions}
                        isSearchable={false}
                        onChange={onSenderChange}
                        components={{
                          IndicatorSeparator: () => null,
                          Option: CustomOption,
                        }}
                      />
                    </div>
                  )}
                </div>
                {senderAutomatic && (
                  <div
                    className="max-w-none cursor-pointer"
                    onClick={showIdentityDeanonymizationModal}
                  >
                    <PencilIcon />
                  </div>
                )}
                {!senderAutomatic && (
                  <div
                    className="max-w-none cursor-pointer ml-3 mr-1"
                    onClick={() => {
                      setSender(null);
                      setSenderAutomatic(true);
                    }}
                  >
                    <PencilIcon />
                  </div>
                )}
              </div>
              <div
                className={`w-full flex flex-row gap-2 items-center border-b-[1px] pt-2 pb-3 ${
                  invalidRecipient ? "border-error-50" : "border-neutral-0"
                }`}
              >
                <div className="text-silver_sand text-sm font-medium leading-5">
                  To
                </div>
                <input
                  type="email"
                  className="w-full bg-transparent outline-none text-neutral-80 text-sm font-medium leading-5"
                  value={recipient}
                  onChange={(e) => handleRecipientChange(e.target.value)}
                ></input>
              </div>
              <div className="w-full flex flex-row gap-2 items-center border-b-[1px] border-neutral-0 pt-2 pb-3">
                <div className="text-silver_sand text-sm font-medium leading-5">
                  Subject
                </div>
                <input
                  type="text"
                  value={subject}
                  className="w-full bg-transparent outline-none text-neutral-80 text-sm font-medium leading-5"
                  onChange={(e) => setSubject(e.target.value)}
                ></input>
              </div>

              <div className="flex h-80 sm:py-3">
                <MailSendEditor onChange={setBody} />
              </div>

              {attachments && attachments.length > 0 && (
                <div className="flex flex-col overflow-y-auto max-h-36 gap-2 my-6">
                  {attachments.map((attachment) => {
                    return (
                      <div className="flex flex-row justify-between items-center w-full rounded-[12px] bg-gray-100 py-2 px-4 font-medium">
                        <div className="flex justify-items-start gap-2">
                          <div className="text-neutral-100 text-xs sm:text-sm font-medium">
                            {attachment.name}
                          </div>
                          <div className="text-neutral-60 text-xs sm:text-sm">
                            (
                            {(attachment.size / (1000 * 1000)).toFixed(2) +
                              " MB"}
                            )
                          </div>
                        </div>

                        <div
                          className="hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none cursor-pointer"
                          onClick={() => removeAttachment(attachment)}
                          aria-label="Close"
                        >
                          <TrashIcon />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              <div className="flex justify-between">
                <button
                  onClick={verifyAndSendMessage}
                  className={btnMailStyle}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div
                      className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                      </span>
                    </div>
                  ) : (
                    <SendIcon />
                  )}
                  <div>Send</div>
                </button>
                <label className="flex items-center">
                  <div className="cursor-pointer max-w-none">
                    <AttachmentIcon />
                  </div>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    multiple
                    onChange={(e) =>
                      setAttachments([...e.target.files, ...attachments])
                    }
                  />
                </label>
              </div>
              {sendMailError && (
                <div className="flex justify-center">
                  <span className="text-error-50">{sendMailError}</span>
                </div>
              )}
            </div>

            {identityChooseModal && (
              <IdentityChooseModal
                show={identityChooseModal}
                domainIdentities={filteredSenders}
                onChooseIdentity={handleIdentityChooseModal}
              />
            )}

            {openNewIdentityRequiredModal && (
              <NewIdentityRequiredModal
                show={openNewIdentityRequiredModal}
                setShow={setOpenNewIdentityRequiredModal}
                onConfirm={generateNewIdentityAsSender}
              />
            )}
            {openRiskDeanonymizationModal && (
              <IdentityDeanonymizationModal
                show={openRiskDeanonymizationModal}
                setShow={setOpenRiskDeanonymizationModal}
                onSave={handleIdentityDeanonymizationModal}
              />
            )}
            {emailSentModal && <EmailSentModal show={emailSentModal} />}
          </div>
        </div>
      </Modal>
    </>
  );
}
