import React from "react";
import onboardingDone from "../../assets/images/onboarding-progress-done.svg";
import onboardingWaiting from "../../assets/images/onboarding-progress-waiting.svg";

export default function OnboardingProgressBar(props) {
  const getStatus = (i) => {
    return props.step > i ? "✅" : i + 1 < props.missionsLength ? i : "🎁";
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="grid grid-flow-col">
          {[...Array(props.missionsLength).keys()].map((m, i) => {
            return (
              <div key={i}>
                <img
                  src={props.step >= i ? onboardingDone : onboardingWaiting}
                  className="max-w-none"
                  alt=""
                />
                <div className="text-center text-[10px] pt-2">
                  {getStatus(i)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
