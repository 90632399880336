import { IPhone, LiveQueryType } from "@no.id/web-common";
import { Call, Device } from "@twilio/voice-sdk";
import { TwilioError } from "@twilio/voice-sdk/es5/twilio/errors";
import { proxy } from "comlink";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import noidWorkerApi from "../NoidWorkerAPI";
import TwilioDevice from "../TwilioDevice";
import { PhonesActionsContext, PhonesContext } from "../context/phonesContext";
import useLiveQuery from "../hooks/useLiveQuery";
import {
  DeviceEventsHandler,
  PhoneCallDevice,
  PhoneCallEventsHandler,
} from "../interface/phoneCallHandlers";
import PhoneDeviceUtils from "../utils/PhoneDeviceUtils";
import { useSyncState } from "./SyncStateProvider";

export function usePhones() {
  return useContext(PhonesContext);
}

export function usePhonesActions() {
  return useContext(PhonesActionsContext);
}

export interface IncomingCallDevice {
  device: Device;
  clientId: string;
}

export function PhonesProvider({ children }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const phones: IPhone[] = useLiveQuery(LiveQueryType.ALL_PHONES);
  const { isSynced } = useSyncState();

  const incomingCallDevices = useRef<IncomingCallDevice[]>([]);

  const activeOutgoingCallDevice = useRef<PhoneCallDevice>();
  const [activeOutgoingCall, setActiveOutgoingCall] = useState<Call>();
  const [activeIncomingCall, setActiveIncomingCall] = useState<Call>();

  const cleanUpAfterCall = useCallback(
    (call: Call) => {
      console.log("CleanUpAfterCAll from PhoneNumbersProvider");
      setActiveIncomingCall(undefined);
      setActiveOutgoingCall(undefined);
      activeOutgoingCallDevice.current?.device.destroy();
    },
    [activeOutgoingCallDevice, setActiveIncomingCall, setActiveOutgoingCall]
  );

  const defaullPhoneCallEventsHandler = useMemo<PhoneCallEventsHandler>(() => {
    return {
      onAccept: (call: Call) => {},
      onRinging: (call: Call) => {},
      onDisconnect: (call: Call) => {
        console.log("defeault Disconnect");
        cleanUpAfterCall(call);
      },
      onReject: (call: Call) => {
        console.log("defeault Reject");

        cleanUpAfterCall(call);
      },
      onCancel: (call: Call) => {
        console.log("defeault Cancel");

        cleanUpAfterCall(call);
      },
      onError: (call: Call) => {
        // cleanUpAfterCall(call);
      },
    };
  }, [cleanUpAfterCall]);

  const defaullDeviceEventsHandler = useMemo<DeviceEventsHandler>(() => {
    return {
      onIncoming: (call: Call) => {
        if (activeOutgoingCall || activeIncomingCall) {
          //By default - reject. Later we'll implement multiple calls handling
          call.reject();
          return;
        }
        console.log("Have new incoming call from " + call.parameters.From);
        call.on("accept", defaullPhoneCallEventsHandler.onAccept);
        call.on("ringing", defaullPhoneCallEventsHandler.onRinging);
        call.on("disconnect", defaullPhoneCallEventsHandler.onDisconnect);
        call.on("reject", defaullPhoneCallEventsHandler.onReject);
        call.on("cancel", defaullPhoneCallEventsHandler.onCancel);
        call.on("error", defaullPhoneCallEventsHandler.onError);
        setActiveIncomingCall(call);
        console.log("ActiveIncomingCall should set up now");
      },
      onDeviceError: (error: TwilioError, call: Call) => {
        console.error("Phone Device error: " + error.description);
        setActiveIncomingCall(undefined);
        setActiveOutgoingCall(undefined);
      },
    };
  }, []);

  const getNewPhone = useCallback(
    (country: string, callback: (newPhone: IPhone) => void) => {
      noidWorkerApi.PhoneService.getNew(
        country,
        proxy((phone: IPhone) => {
          if (phone) {
            TwilioDevice.startTwilioDevice(phone.clientId);
          }
          callback(phone);
        })
      );
    },
    []
  );

  const computeCallCost = useCallback(
    async (phone: IPhone, destPhoneNumber: string): Promise<number> => {
      const callCostInCredits = await noidWorkerApi.PhoneService.getCallCost(
        phone.number,
        destPhoneNumber,
        phone.id
      );
      return callCostInCredits;
    },
    []
  );

  const initiateOutgoingPhoneCall = async (
    phone: IPhone,
    destPhoneNumber: string
  ) => {
    //TODO: add EROR handling
    //
    if (activeIncomingCall || activeOutgoingCall) {
      throw new Error("Another call is currently active");
    }

    const callToken = await PhoneDeviceUtils.getOutgoingCallToken(phone);
    if (!callToken) {
      throw new Error("Problem with getting phone call token");
    }

    const phoneDevice = await PhoneDeviceUtils.registerDeviceForOutgoingCall(
      phone,
      callToken,
      defaullDeviceEventsHandler
    );
    if (!phoneDevice) {
      throw new Error("Problem registering a device for outgoing phone call");
    }

    const call = await phoneDevice.device.connect({
      params: { phoneNumber: destPhoneNumber, callToken },
    });

    call.on("accept", defaullPhoneCallEventsHandler.onAccept);
    call.on("ringing", defaullPhoneCallEventsHandler.onRinging);
    call.on("disconnect", defaullPhoneCallEventsHandler.onDisconnect);
    call.on("reject", defaullPhoneCallEventsHandler.onReject);
    call.on("cancel", defaullPhoneCallEventsHandler.onCancel);
    call.on("error", defaullPhoneCallEventsHandler.onError);

    activeOutgoingCallDevice.current = phoneDevice;
    setActiveOutgoingCall(call);
  };

  const hangupActivePhoneCall = useCallback(() => {
    if (activeIncomingCall) {
      activeIncomingCall.disconnect();
    }
    if (activeOutgoingCall) {
      activeOutgoingCall.disconnect();
    }
  }, [activeIncomingCall, activeOutgoingCall]);

  const deactivatePhone = useCallback(
    async (phone: IPhone): Promise<boolean> => {
      const result = await noidWorkerApi.PhoneService.deactivate(phone);
      return result ? true : false;
    },
    []
  );

  const renewPhone = useCallback(async (phone: IPhone): Promise<boolean> => {
    const result = await noidWorkerApi.PhoneService.renew(phone);
    return result ? true : false;
  }, []);

  useEffect(() => {
    if (!phones) {
      return;
    }

    const allPhoneClientIds = phones.map((p) => p.clientId);
    const devicesToDestroy = incomingCallDevices.current.filter(
      (d) => !allPhoneClientIds.includes(d.clientId)
    );

    devicesToDestroy.forEach((d) => d.device.destroy());
    const clientIdsToDestroy = devicesToDestroy.map((d) => d.clientId);
    incomingCallDevices.current = incomingCallDevices.current.filter(
      (d) => !clientIdsToDestroy.includes(d.clientId)
    );

    const activeDevicesClientIds = incomingCallDevices.current.map(
      (d) => d.clientId
    );
    const phonesToSetup = phones.filter(
      (p) => !activeDevicesClientIds.includes(p.clientId)
    );

    phonesToSetup.forEach(async (phone) => {
      const incomingCallDevice =
        await PhoneDeviceUtils.registerDeviceForIncomingCall(
          phone,
          defaullDeviceEventsHandler
        );
      incomingCallDevices.current.push(incomingCallDevice);
    });
  }, [phones]);

  useEffect(() => {
    if (isSynced && !isLoaded) {
      setIsLoaded(true);
    }
  }, [isSynced]);

  useEffect(() => {
    console.log("Initializing incoming connection | isSynced = " + isSynced);
    if (isSynced && phones) {
      console.log("[INIT] twilio phones connect...");
      TwilioDevice.startTwilioDevices(phones);
    }
  }, [isSynced, phones]);

  const providerValue = useMemo(
    () => ({
      phones,
      isLoaded,
      activeIncomingCall,
      activeOutgoingCall,
    }),
    [phones, isLoaded, activeIncomingCall, activeOutgoingCall]
  );

  const phonesActions = useMemo(
    () => ({
      getNewPhone,
      computeCallCost,
      initiateOutgoingPhoneCall,
      hangupActivePhoneCall,
      deactivatePhone,
      renewPhone,
    }),
    [
      getNewPhone,
      computeCallCost,
      initiateOutgoingPhoneCall,
      hangupActivePhoneCall,
      deactivatePhone,
      renewPhone,
    ]
  );

  return (
    <PhonesContext.Provider value={providerValue}>
      <PhonesActionsContext.Provider value={phonesActions}>
        <>
          {children}
          {/* <PickUpCallModal /> */}
        </>
      </PhonesActionsContext.Provider>
    </PhonesContext.Provider>
  );
}
