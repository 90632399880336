/* global Notification */
import React, { useEffect, useState } from "react";

import { IPhone } from "@no.id/web-common";
import noidWorkerApi from "../../NoidWorkerAPI";
import SmsList from "../../features/sms/components/smsList/SmsList";
import DeactivatePhoneModal from "../DeactivatePhoneModal";
import RenewPhoneModal from "../RenewPhoneModal";
import PhoneCallModal from "../makeCallModal/PhoneCallModal";
import PhoneDashboardHeader from "../phoneDashboardHeader/PhoneDashboardHeader";
import PhoneList from "../phoneList/PhoneList";
import "./PhoneDashboard.scss";

export function PhoneDashboard() {
  const [selectedPhone, setSelectedPhone] = useState<IPhone>();
  const [smsListVisible, setSmsListVisible] = useState(false);

  const [phoneCallModalOpened, setPhoneCallModalOpened] = useState(false);
  const [phoneDeactivateModalOpened, setPhoneDeactivateModalOpened] =
    useState(false);
  const [phoneRenewModalOpened, setPhoneRenewModalOpened] = useState(false);

  const onSmsButtonClick = (phone: IPhone) => {
    setSelectedPhone(phone);
    setSmsListVisible(true);
  };

  const onSmsListBackClick = () => {
    setSmsListVisible(false);
    setSelectedPhone(undefined);
  };

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }, []);

  const showNotification = () => {
    noidWorkerApi.showNotification();
    // IncomingCallManager.setNewActiveCall({ parameters: {
    //     From: "+48 345 432 234"
    // }});
  };

  const onPhoneCallClick = (phone: IPhone) => {
    setSelectedPhone(phone);
    setPhoneCallModalOpened(true);
  };

  const onPhoneDeleteClick = (phone: IPhone) => {
    setSelectedPhone(phone);
    setPhoneDeactivateModalOpened(true);
  };

  const onPhoneRenewClick = (phone: IPhone) => {
    setSelectedPhone(phone);
    setPhoneRenewModalOpened(true);
  };

  return (
    <>
      <div className="w-full h-full pt-[64px] lg:ml-[256px] lg:pt-0">
        <div className="h-full w-full">
          {!smsListVisible && (
            <div className="h-full overflow-y-auto no-scrollbar overflow-x-hidden pb-[65px] lg:pb-6">
              <PhoneDashboardHeader />
              <PhoneList
                onSmsButtonClick={onSmsButtonClick}
                onPhoneCallClick={onPhoneCallClick}
                onPhoneDeleteClick={onPhoneDeleteClick}
                onPhoneRenewClick={onPhoneRenewClick}
              />
            </div>
          )}
          {smsListVisible && (
            <SmsList phone={selectedPhone} onBackClick={onSmsListBackClick} />
          )}
        </div>
      </div>

      <PhoneCallModal
        isOpen={phoneCallModalOpened}
        phone={selectedPhone}
        onClose={() => {
          setSelectedPhone(null);
          setPhoneCallModalOpened(false);
        }}
      />

      <DeactivatePhoneModal
        show={phoneDeactivateModalOpened}
        phone={selectedPhone}
        onClose={() => {
          setSelectedPhone(null);
          setPhoneDeactivateModalOpened(false);
        }}
      />

      <RenewPhoneModal
        show={phoneRenewModalOpened}
        phone={selectedPhone}
        onClose={() => {
          setSelectedPhone(null);
          setPhoneRenewModalOpened(false);
        }}
      />
    </>
  );
}
