import classNames from "classnames";
import React from "react";
import {isDesktop} from "react-device-detect";
import {useAuth} from "../../providers/AuthProvider";
import {useIdentities} from "../../providers/IdentityProvider";
import IdentitiesStats from "../IdentitiesStats";
import NewIdentityView from "../newIdentity/NewIdentityView";
import PrimaryInput from "../pimaryInput/primaryInput";
import "./DashboardHeader.scss";

function DashboardHeader(props) {
    const {identities, compromisedTotal} = useIdentities();
    const {userProfile} = useAuth();

    return (
        <div className="relative bg-white">
            <div
                className="relative z-10 top-0 px-6 flex flex-row flex-wrap lg:flex-nowrap gap-[12px] md:gap-[32px] lg:gap-[72px] items-center">
                <IdentitiesStats/>

                <NewIdentityView
                    onIdentityCreated={identities}
                    totalLimitReached={
                        identities && identities.length >= userProfile.totalIdentityLimit
                    }
                />
            </div>

            <div className="absolute top-0 left-0 z-0 lg:h-[192px] w-full">
                <div
                    className={classNames(
                        "absolute z-0 h-[290px] lg:h-[250px] lg:max-h-[250px] w-full lg:w-[420px] blur-md",
                        "bg-[radial-gradient(ellipse_at_top_center,_var(--tw-gradient-stops))]",
                        "lg:bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))]",
                        {"from-[#F4FBFB]": compromisedTotal == 0},
                        {"from-[#FFF5F2]": compromisedTotal > 0}
                    )}
                />
                <img
                    className="h-[248px] lg:h-[276px] absolute right-[-96px] top-[-84px] md:right-[-48px] md:top-[-48px] z-10"
                    src={require("../../assets/images/mask-identity.png")}
                />
            </div>

            <PrimaryInput
                className="relative z-10 mx-6 my-6 lg:mt-0"
                width="full"
                icon={require("../../assets/images/icon-lupe.svg")}
                placeholder="Type note, service name etc."
                rightLabel={isDesktop ? `Search all identities` : "Search"}
                value={props.searchPhrase}
                onChange={(e) => props.setSearchPhrase(e.target.value)}
            />
        </div>
    );
}

export default DashboardHeader;
