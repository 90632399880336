import { ISentSms, ISms } from "@no.id/web-common";
import classNames from "classnames";
import { AsYouType } from "libphonenumber-js";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSmsActions } from "../../../../providers/SmsProvider";
import { formatDate } from "../../../../utils/DateUtils";
import PhoneUtils from "../../../../utils/PhoneUtils";
import { MessageGroup, SmsThread } from "../smsList/types";

const ALLOWED_PHONE_CHARS: string[] = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "+",
];

const MessageBubble = ({
  message,
  isReceived,
}: {
  message: ISms | ISentSms;
  isReceived: boolean;
}) => (
  <p
    className={classNames(
      `flex flex-row gap-4 rounded-[12px] border-[1px] border-neutral-5 text-[14px] font-normal heading-5 max-w-[95%] md:max-w-[70%] w-max whitespace-pre-wrap`,
      `center-items justify-left px-6 py-4`,
      { "bg-[#F2F3F3] text-[#153F4C]": isReceived },
      { "bg-[#27A5A5] text-[#F4FBFB]": !isReceived }
    )}
  >
    {message.body}
  </p>
);

const MessagesGroup = ({
  messages,
  number,
  numberCountry,
}: {
  messages: MessageGroup;
  number: string;
  numberCountry: string;
}) => {
  const lastMesssage = messages[messages.length - 1];
  const isSent = "to" in lastMesssage;

  return (
    <div className="flex w-full first:mt-6">
      <div
        className={classNames("w-full flex flex-col gap-2", {
          "items-end justify-end": isSent,
        })}
      >
        <>
          {messages.map((message: ISms | ISentSms, idx: number) => (
            <MessageBubble message={message} isReceived={!isSent} key={idx} />
          ))}
        </>
        <div
          className={classNames(
            "flex gap-4 bg-white center-items justify-center w-fit",
            { "flex-row": !isSent },
            { "flex-row-reverse": isSent }
          )}
        >
          <div className="m-auto flex items-center justify-center h-10 w-10 rounded-[8px] border-[1px] border-neutral-0">
            <div className={`w-6 fi fi-${numberCountry?.toLowerCase()}`}></div>
          </div>
          <div
            className={classNames(
              "flex flex-col",
              { "items-end": isSent },
              { "items-start": !isSent }
            )}
          >
            <div className="text-[14px] font-medium heading-5 text-neutral-800">
              {new AsYouType().input(number)}
            </div>
            <div className="text-[14px] font-medium heading-5 text-silver_sand">
              {formatDate(lastMesssage.date)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Header = ({
  number,
  countryCode,
  isNew,
}: {
  number: string;
  countryCode?: string;
  isNew: boolean;
}) => (
  <div className="p-6 bg-[#FCFCFC] border-b-[1px] border-neutral-0 flex flex-row justify-between items-center rounded-t-[12px]">
    <div className="flex flex-row gap-4 items-center">
      <div className="m-auto p-2 rounded-[8px] border-[1px] border-neutral-0">
        <div className={`h-6 fi fi-${countryCode?.toLowerCase()}`}></div>
      </div>

      <div className="text-[14px] font-medium leading-5 text-neutral-80">
        <span className="text-[#BEC4C5]">To:</span>{" "}
        {isNew ? "Enter number" : new AsYouType().input(number)}
      </div>
    </div>
  </div>
);

export interface SmsThreadProps {
  thread: SmsThread;
  onSmsSend: (destNumber: string, message: string) => void;
  isNew: boolean;
}

export default function SmsThreadView({
  thread,
  onSmsSend,
  isNew,
}: SmsThreadProps) {
  const [error, setError] = useState(false);
  const [newDestNumber, setNewDestNumber] = useState<string>("");
  const [newDestNumberValid, setNewDestNumberValid] = useState<boolean>(false);
  const [newMessageBody, setNewMessageBody] = useState<string>("");
  const bottomRef = useRef(null);

  const { markAlreadyRead } = useSmsActions();

  const onNewDestNumberChange = (value) => {
    if (value.split("").every((v) => ALLOWED_PHONE_CHARS.includes(v))) {
      setNewDestNumber(value);
      setNewDestNumberValid(
        PhoneUtils.isNumberValid(value, thread.srcNumberCountry)
      );
    }
  };

  const onSendClick = () => {
    if (isNew && (!newDestNumber || !newDestNumberValid)) return;
    if (!newMessageBody) return;

    onSmsSend(isNew ? newDestNumber : thread.destNumber, newMessageBody);
    setNewDestNumber("");
    setNewMessageBody("");
    setTimeout(() => {
      bottomRef.current?.lastElementChild?.scrollIntoView({
        behavior: "smooth",
      });
    }, 300);
  };

  useEffect(() => {
    bottomRef.current?.lastElementChild?.scrollIntoView({ behavior: "smooth" });
  }, [bottomRef.current]);

  useEffect(() => {
    thread.messageGroups.forEach((group) => {
      group.forEach((m: ISms | ISentSms) => {
        const message = m as ISms;
        if (message.alreadyRead === false) {
          markAlreadyRead(message.smsId);
        }
      });
    });
  }, [thread]);

  return (
    <div className="flex flex-col h-full">
      <Header
        number={thread.destNumber}
        countryCode={thread.destNumberCountry}
        isNew={isNew}
      />
      <div className={`flex flex-col grow ${isNew ? "h-screen" : "h-0"} pb-6`}>
        <div
          className={`w-full ${
            isNew ? "m-auto" : "grow h-0"
          } overflow-auto overscroll-contain px-6 scrollbar-thin`}
        >
          {isNew && <div className="text-center">Start your conversation</div>}
          {thread.messageGroups?.length > 0 && (
            <div ref={bottomRef} className="flex flex-col gap-8 h-full">
              {_.map(thread.messageGroups, (group, idx) => (
                <MessagesGroup
                  messages={group}
                  number={
                    "to" in group[0] ? thread.srcNumber : thread.destNumber
                  }
                  numberCountry={
                    "to" in group[0]
                      ? thread.srcNumberCountry
                      : thread.destNumberCountry
                  }
                  key={idx}
                />
              ))}
              {/* <div ref={bottomRef} className="h-[1px] w-full invisible" /> */}
            </div>
          )}
        </div>
        {isNew && (
          <div className="w-full px-6">
            <div className="w-full p-1 rounded-[12px] border-[1px] border-neutral-5 flex flex-row justify-between mt-8 items-center">
              <img
                src={require("../../../../assets/images/icon-destination-number.svg")}
                className="h-[24px] ml-3"
              ></img>
              <input
                placeholder="Number to message"
                value={newDestNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onNewDestNumberChange(e.target.value)
                }
                className="flex-1 px-2 py-3 text-[14px] font-normal leading-5 resize-none !outline-none max-h-11"
              />
            </div>
          </div>
        )}
        <div className="w-full px-6">
          <div className="w-full p-1 rounded-[12px] border-[1px] border-neutral-5 flex flex-row justify-between mt-8 items-center">
            <img
              src={require("../../../../assets/images/icon-messages.svg")}
              className="h-[24px] ml-3"
            ></img>
            <textarea
              placeholder="Message"
              value={newMessageBody}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setNewMessageBody(e.target.value)
              }
              className="flex-1 px-2 py-3 text-[14px] font-normal leading-5 resize-none !outline-none max-h-11"
            />
            <img
              src={require("../../../../assets/images/icon-send.svg")}
              className={`h-[40px] bg-neutral-100  p-[10px] rounded-[8px] ml-3 ${
                !newMessageBody ||
                (isNew && (!newDestNumber || !newDestNumberValid))
                  ? "opacity-30"
                  : ""
              }`}
              onClick={onSendClick}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}
