import { Icon } from "semantic-ui-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import React, { useState } from "react";
import "./IdentityFieldCopyable.scss";
import AnalyticsEvents from "../../events/analyticsEvents";
import { trackGoal } from "fathom-client";

function IdentityFieldCopyable(props) {
  const [showMessage, setShowMessage] = useState(false);

  const handleFathomEventName = () => {
    switch (props.name) {
      case "email":
        return AnalyticsEvents.WEB_COPY_IDENTITY;
      case "password":
        return AnalyticsEvents.WEB_COPY_PASSWORD;
    }
  };

  const onValueCopy = () => {
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 1000);
    trackGoal(handleFathomEventName(), 0);
  };

  return (
    <div className="flex justify-between items-center hover:bg-[#F4FBFB] hover:rounded-[8px] group">
      {showMessage ? (
        <div className="flex items-center gap-1 bg-success-0 rounded-xl py-2 px-2 w-full">
          <img src={require("../../assets/images/icon_check.svg")} />
          <div className="text-[12px] text-success-50 font-semibold leading-4">
            {props.name} copied
          </div>
        </div>
      ) : (
        <>
          <div className="flex w-fit items-center gap-1 rounded-xl py-2 pl-2 pr-4">
            <img src={props.icon} />
            <span
              className={`max-w-[121px] text-[12px] font-semibold leading-4 ${props.textClassNames}`}
            >
              {props.displayValue}
            </span>
          </div>

          <div className="flex gap-2 mr-2">
            {props.extraCallback && (
              <img
                className="hover:cursor-pointer"
                src={props.extraIcon}
                onClick={props.extraCallback}
              />
            )}

            <CopyToClipboard text={props.value} onCopy={onValueCopy}>
              <img
                className="hover:cursor-pointer"
                src={require("../../assets/images/icon_copy.svg")}
              />
            </CopyToClipboard>
          </div>
        </>
      )}
    </div>
  );
}

export default IdentityFieldCopyable;
