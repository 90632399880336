import React from "react";
import {Placeholder} from "semantic-ui-react";

function MailItemLoader(props) {

    return (
        <Placeholder fluid style={props.style}>
            <Placeholder.Paragraph>
                <Placeholder.Line/>
                <Placeholder.Line/>
                <Placeholder.Line/>
                <Placeholder.Line/>
                <Placeholder.Line/>
            </Placeholder.Paragraph>
            <Placeholder.Paragraph>
                <Placeholder.Line/>
                <Placeholder.Line/>
                <Placeholder.Line/>
            </Placeholder.Paragraph>
        </Placeholder>
    )

}

export default MailItemLoader;