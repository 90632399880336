import React from "react";
import Modal from "../Modal";

export interface EmailSentProps {
  show: boolean;
}

export default function EmailSentModal({ show }: EmailSentProps) {
  return (
    <Modal
      isOpen={show}
      nestedModal={true}
      backdropColor="bg-white/75"
      style="z-20"
    >
      <div
        className="bg-white pt-6 px-5 pb-4 rounded-[12px] border-[1px] flex flex-col gap-2 brand-neutral-100
       text-neutral-100 w-[360px] shadow-lg shadow-[#10182810]"
      >
        <div className="mb-2 font-sans text-sm heading-5 font-normal text-center">
          Your email has been sent securely, safeguarding your identity with the
          anonymous identity.
        </div>
      </div>
    </Modal>
  );
}
