import React from "react";
import {
  TEModal,
  TEModalBody,
  TEModalContent,
  TEModalDialog,
} from "tw-elements-react";
import SecondaryButton from "./secondaryButton/secondaryButton";

export interface FundCreditsModalProps {
  open: boolean;
  onClose: () => void;
}

export default function FundCreditsModal({
  open,
  onClose,
}: FundCreditsModalProps) {
  return (
    <TEModal
      show={open}
      backdrop={true}
      className="bg-neutral-100/80 pt-[10vh]"
    >
      <TEModalDialog>
        <TEModalContent>
          <TEModalBody className="flex flex-col gap-3 px-4">
            <p className="font-semibold">
              You cannot fund your credits right now.
            </p>
            <p>
              In final release, you could buy many numbers and call as much as
              you want.
            </p>
            <p>Stay tuned!</p>

            <SecondaryButton
              title="I understand"
              onClick={onClose}
              className="mt-3"
            />
          </TEModalBody>
        </TEModalContent>
      </TEModalDialog>
    </TEModal>
  );
}
