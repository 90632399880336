import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import App from "./App";
import GlobalContextProvider from "./components/context/GlobalContextProvider";
import "./index.css";
import { AuthProvider } from "./providers/AuthProvider";
import "tw-elements-react/dist/css/tw-elements-react.min.css";

// // if (config.env !== 'prod') {
//     Sentry.init({
//         dsn: "https://3300ab4ee5144f4dba3dd206426d5610@o366936.ingest.sentry.io/6173825",
//         integrations: [new Integrations.BrowserTracing()],
//         environment: config.env,

//         // We recommend adjusting this value in production, or using tracesSampler
//         // for finer control
//         tracesSampleRate: 1.0,
//     });
// // }

ReactDOM.render(
  <GlobalContextProvider>
    <AuthProvider>
      <BrowserRouter>
        <Route path="/" component={App} />
      </BrowserRouter>
    </AuthProvider>
  </GlobalContextProvider>,
  document.getElementById("root")
);
