import React, { useState, useEffect, useRef, useCallback } from "react";
import Keyboard from "react-simple-keyboard";

export interface PhoneKeyboardProps {
  onKeyPress: (button: string, e?: MouseEvent) => any;
}

const keyboardOptions = {
    layout: {
        default: ["1 2 3", "4 5 6", "7 8 9", "* 0 #"],
      },
      display: {
        "0": "0 / +"
      },
  };

export default function PhoneKeyboard({ onKeyPress }: PhoneKeyboardProps) {
  const [isLongPress, setIsLongPress] = useState(false);
  const pressTimer = useRef<NodeJS.Timeout | undefined>();;

  const handleButtonMouseDown = (button: string) => {
    pressTimer.current = setTimeout(() => {
        setIsLongPress(true);
        if (button === "0") {
            onKeyPress("+");
        }
    }, 1000); 
  };

  const handleButtonMouseUp = useCallback((button: string) => {
    if (!isLongPress) {
        onKeyPress(button);
    } 
    setIsLongPress(false);
    clearTimeout(pressTimer.current);
  }, [onKeyPress, isLongPress, pressTimer]);

  useEffect(() => {
    const buttons = document.querySelectorAll('.hg-button');
    buttons.forEach((button : HTMLButtonElement) => {
      const buttonValue = button.getAttribute('data-skbtn');
      button.onmousedown = () => handleButtonMouseDown(buttonValue);
      button.onmouseup = () => handleButtonMouseUp(buttonValue);

      // For touch devices
      button.ontouchstart = () => handleButtonMouseDown(buttonValue);
      button.ontouchend = () => handleButtonMouseUp(buttonValue); ;
    });

    return () => {
      // Cleanup the event listeners when the component unmounts
      buttons.forEach((button : HTMLButtonElement) => {
        button.onmousedown = null;
        button.onmouseup = null;
        button.ontouchstart = null;
        button.ontouchend = null;
      });
    };
  }, [isLongPress, handleButtonMouseDown, handleButtonMouseUp]);


  return <Keyboard {...keyboardOptions} />;
}
