import _ from "lodash";
import { Line } from "rc-progress";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BarLoader } from "react-spinners";
import { Message } from "semantic-ui-react";
import {
  useEmailActions,
  useEmailMessages,
} from "../../providers/EmailProvider";
import { useIdentities } from "../../providers/IdentityProvider";
import "../MessageReader.scss";
import IdentitySelectView from "../identitySelectView/identitySelectView";
import MailItem from "../mailItem/mailItem";
import MailList from "../mailList/mailList";
import MailToolbar from "../mailToolbar/mailToolbar";
import MailboxIdentityFilter from "../mailboxIdenttyFilter/mailboxIdentityFilter";
import "./MailboxView.scss";
// @ts-ignore
import styles from "./mailboxView.module.scss";

const NotificationIndicator = (value: number) => {
  if (value <= 0) return null;
  return (
    <div className="rounded-[6px] bg-[#F4484E] text-white py-[2px] px-2 text-xs sm:text-[12px] font-semibold heading-[16px] text-center w-6 h-6 flex items-center justify-center">
      {value}
    </div>
  );
};

export default function MailboxView(props) {
  const { identities } = useIdentities();
  const { emailSummaries, progress, progressVisible } = useEmailMessages();
  const { refresh, isAlreadyRead } = useEmailActions();

  const [sortedEmailSummaries, setSortedEmailSummaries] = useState(
    () => emailSummaries
  );

  const [currentIdentityId, setCurrentIdentityId] = useState(null);
  const [mailListError, setMailListError] = useState(null);
  const [selectedMailIdx, setSelectedMailIdx] = useState(undefined);
  const [selectedMail, setSelectedMail] = useState(undefined);
  const [showProgress, setShowProgress] = useState(() => {
    return progressVisible && progress != 1;
  });

  const [showInfiniteProgress, setShowInfiniteProgress] = useState(false);

  const identityOptions = useMemo(() => {
    let unreadByIdentity = {};
    let hasEmailByIdentity = {};
    for (let i = 0; i < emailSummaries?.length; i++) {
      let summary = emailSummaries[i];
      if (!isAlreadyRead(summary.mailId)) {
        unreadByIdentity[summary.identityId] = unreadByIdentity[
          summary.identityId
        ]
          ? unreadByIdentity[summary.identityId] + 1
          : 1;
      }
      hasEmailByIdentity[summary.identityId] = true;
    }

    return _.map(
      _.uniqBy(identities, "id").filter((id) => hasEmailByIdentity[id.id]),
      (id) => {
        return {
          key: id.id,
          label: id.note
            ? `${id.note} (Identity for ${id.domain})`
            : `Dedicated Identity for ${id.domain}`,
          value: id.id,
          content: (
            <div className="flex flex-row w-full items-center hover:bg-gray-100/50 pl-1 sm:px-4">
              <div>
                {unreadByIdentity[id.id] ? (
                  NotificationIndicator(unreadByIdentity[id.id])
                ) : (
                  <div className="w-6 h-6"></div>
                )}
              </div>
              <IdentitySelectView identity={id} />
            </div>
          ),
        };
      }
    );
  }, [emailSummaries, identities]);

  useEffect(() => {
    let emails = currentIdentityId
      ? _.filter(emailSummaries, { identityId: currentIdentityId })
      : emailSummaries;
    setSortedEmailSummaries(_.orderBy(emails, ["date"], ["desc"]));
  }, [emailSummaries, currentIdentityId]);

  useEffect(() => {
    let emails = currentIdentityId
      ? _.filter(emailSummaries, { identityId: currentIdentityId })
      : emailSummaries;
    setSortedEmailSummaries(_.orderBy(emails, ["date"], ["desc"]));
  }, [emailSummaries, currentIdentityId]);

  const handleRefresh = () => {
    setShowProgress(progressVisible);
    refresh();
    // trackGoal(AnalyticsEvents.WEB_MAILS_REFRESHED, 0);
  };

  const onIdentityChange = (currentIdentityId) => {
    setCurrentIdentityId(currentIdentityId);
  };

  const handleBackToMailboxClick = () => {
    setSelectedMailIdx(undefined);
  };

  const handleMailSelect = useCallback(
    (mailIdx) => {
      setSelectedMailIdx(mailIdx);
      setSelectedMail(sortedEmailSummaries[mailIdx]);
    },
    [sortedEmailSummaries]
  );

  useEffect(() => {
    // console.log(`progress: ${progress} progressVisible: ${progressVisible}`);
    setShowProgress(progress < 1 && progressVisible);
  }, [progress, progressVisible]);

  return (
    <div className="no-scrollbar w-full h-full mt-[64px] lg:ml-[256px] lg:mt-0">
      <div className="px-3 py-6 h-full">
        <div
          className={`${styles.mailboxContentInner} ${
            selectedMailIdx === undefined ? "" : "mail-only"
          }`}
        >
          <div className={`${selectedMailIdx === undefined ? "" : "hidden"}`}>
            <MailboxIdentityFilter
              identityOptions={identityOptions}
              currentIdentityId={currentIdentityId}
              onIdentityChange={onIdentityChange}
            />
          </div>

          {selectedMailIdx !== undefined && (
            <MailToolbar backToMailboxClick={handleBackToMailboxClick} />
          )}

          {showProgress && (
            <div className={styles.loadingWrapper}>
              <div className={styles.loadingMask} />
              <div className={styles.progressBarWrapper}>
                {showInfiniteProgress ? (
                  <BarLoader className={styles.progressBar} color="#32B852" />
                ) : (
                  <Line
                    className={styles.progressBar}
                    percent={progress * 100}
                    strokeWidth={2}
                    strokeColor="#32B852"
                    trailColor="#D7F4DE"
                  />
                )}
              </div>
            </div>
          )}

          {(!showProgress || (showProgress && emailSummaries?.length > 0)) && (
            <>
              {selectedMailIdx === undefined && (
                <MailList
                  summaries={sortedEmailSummaries}
                  currentIdentityId={currentIdentityId}
                  onMailSelect={handleMailSelect}
                />
              )}

              {selectedMailIdx >= 0 && (
                <MailItem
                  summary={selectedMail}
                  mailId={selectedMail.mailId}
                  identityId={selectedMail.identityId}
                  identity={identities.find(
                    (id) => id.id === selectedMail.identityId
                  )}
                />
              )}
            </>
          )}

          {mailListError && (
            <div style={{ margin: "84px auto" }}>
              <Message
                error
                header="Errors occurred"
                content="Please try again by clicking refresh button"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  // return (
  //     <div className={`mailbox-wrapper ${selectedMailIdx === undefined ? "" : 'mail-only'} `}>

  //         <div className='mailbox-content'>

  //             <div className={`mailbox-content-inner ${selectedMailIdx === undefined ? "" : 'mail-only'}`}>

  //                 { selectedMailIdx === undefined ?
  //                     (
  //                         <MailboxIdentityFilter identityOptions={identityOptions}
  //                                                currentIdentityId={currentIdentityId}
  //                                                onIdentityChange={onIdentityChange}
  //                                                onRefreshClick={handleRefresh}
  //                                                style={{marginBottom: '12px'}}
  //                         />)
  //                     :
  //                     (
  //                         <div className='mailbox-content-inner-navigation'>
  //                             <Button className='mailbox-back-btn' onClick={handleBackToMailboxClick}>
  //                                 <Icon name='angle left'/>
  //                                 Back to Inbox
  //                             </Button>
  //                         </div>
  //                     )
  //                 }

  //                 { showProgress && (
  //                     <div style={{marginBottom: '24px', display: 'flex', justifyContent: 'center' }}>
  //                         <PulseLoader
  //                             size={10}
  //                             color={"black"}
  //                             loading={true}
  //                         />
  //                     </div>
  //                 )}

  //                 { (!showProgress || (showProgress && emailSummaries?.length > 0)) && (
  //                     <>

  //                         { selectedMailIdx === undefined && (
  //                             <MailList
  //                                 summaries={sortedEmailSummaries}
  //                                 currentIdentityId={currentIdentityId}
  //                                 onMailSelect={handleMailSelect}
  //                             />
  //                         )}

  //                         { selectedMailIdx >= 0 && (
  //                             <MailItem summary={selectedMail}
  //                                       mailId={selectedMail.mailId}
  //                                       identityId={selectedMail.identityId}
  //                                       identity={identities.find(id => id.id == selectedMail.identityId)}
  //                             />
  //                         )}
  //                     </>
  //                 )}
  //             </div>

  //             { mailListError && (
  //                 <div style={{margin: '84px auto'}}>
  //                     <Message
  //                         error
  //                         header='There was some errors'
  //                         content='Please try again by clicking refresh button'
  //                     />

  //                     <Button size='big' onClick={this.refresh} className='refresh-button-error'>
  //                         <Icon style={{color: 'white'}} name='refresh'/>
  //                         Refresh
  //                     </Button>
  //                 </div>
  //             )}

  //             {!isMobileOnly  && (
  //                 <div className='mailbox-bottom-image'>
  //                     <Image src='images/subscribe-envelop.png'/>
  //                 </div>
  //             )}

  //         </div>
  //     </div>
  // )
}
