import classNames from "classnames";
import React from "react";
import onboardingOpen from "../../assets/images/onboarding-open.svg";
import { useOnboarding } from "../../providers/OnboardingProvider";
import missions from "../onboardingModal/OnboardingMissions";
import OnboardingProgressBar from "../onboardingProgressBar/OnboardingProgressBar";

const btnMailStyle = classNames(
  "flex flex-row flex-center items-center",
  "text-[14px] leading-5 font-semibold text-green-500"
);

export default function OnboardingMenu(props) {
  const { isLoaded, onboarding } = useOnboarding();

  const getCurrentStep = () => {
    return onboarding && onboarding[0] ? onboarding[0].step : 0;
  };

  const getNextStepMessage = () => {
    switch (getCurrentStep()) {
      case 0:
        return "Start mission";
      case missions.length - 1:
        return "Finish mission";
      default:
        return "Next mission";
    }
  };

  return (
    <>
      {isLoaded && (
        <div className="bg-green-50 rounded-[12px] py-4 flex flex-col items-center mt-10">
          <div className="text-center text-neutral-80 text-[14px] font-semibold">
            Onboarding mission
          </div>
          <div className="py-4 px-2">
            <OnboardingProgressBar
              missionsLength={missions.length}
              step={getCurrentStep()}
            />
          </div>
          <button onClick={props.open} className={btnMailStyle}>
            <img src={onboardingOpen} className="max-w-none pr-2" alt="" />
            <div>{getNextStepMessage()}</div>
          </button>
        </div>
      )}
    </>
  );
}
