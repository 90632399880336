import React from "react";

export function SendIcon({
  color = "#F2F3F3",
  size = 21,
}: {
  color?: string;
  size?: number;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33398 12.538L17.5007 3.37134"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4998 3.37134L12.0831 18.3713C12.0085 18.5343 11.8457 18.6387 11.6665 18.6387C11.4873 18.6387 11.3245 18.5343 11.2498 18.3713L8.33315 12.538L2.49981 9.62134C2.33688 9.54667 2.23242 9.38389 2.23242 9.20467C2.23242 9.02545 2.33688 8.86267 2.49981 8.788L17.4998 3.37134"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
