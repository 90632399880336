import { ISms } from "@no.id/web-common";
import _ from "lodash";
import moment from "moment";
import { SmsThread } from "./features/sms/components/smsList/types";

export const mockSmsMessages1: ISms[] = [
  {
    primaryId: "1111",
    smsId: "1",
    phoneId: "1",
    body: "SLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco ",
    from: "+48123123123",
    date: moment("15/10/2023 09:15:00", "DD MM YYYY hh:mm:ss").toDate(),
    alreadyRead: false,
  },
  {
    primaryId: "1111",
    smsId: "2",
    phoneId: "1",
    body: "2 SLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco ",
    from: "+48123123123",
    date: moment("15/10/2023 09:16:00", "DD MM YYYY hh:mm:ss").toDate(),
    alreadyRead: false,
  },
  {
    primaryId: "1111",
    smsId: "3",
    phoneId: "1",
    body: "3 SLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco ",
    from: "+48123123123",
    date: moment("15/10/2023 09:17:00", "DD MM YYYY hh:mm:ss").toDate(),
    alreadyRead: false,
  },
];

export const mockSmsMessages2: ISms[] = [
  {
    primaryId: "1111",
    smsId: "11",
    phoneId: "1",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco ",
    from: "+481555666777",
    date: moment("15/10/2023 09:15:00", "DD MM YYYY hh:mm:ss").toDate(),
    alreadyRead: false,
  },
  {
    primaryId: "1111",
    smsId: "21",
    phoneId: "1",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco ",
    from: "+481555666777",
    date: moment("15/10/2023 09:16:00", "DD MM YYYY hh:mm:ss").toDate(),
    alreadyRead: false,
  },
  {
    primaryId: "1111",
    smsId: "31",
    phoneId: "1",
    body: "SLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco ",
    from: "+481555666777",
    date: moment("15/10/2023 09:17:00", "DD MM YYYY hh:mm:ss").toDate(),
    alreadyRead: false,
  },
];

export const mockSmsThreads: SmsThread[] = [
  {
    id: _.uniqueId(),
    messageGroups: [mockSmsMessages1, mockSmsMessages2],
    destNumber: "+1 234 567 890",
    destNumberCountry: "US",
    srcNumber: "+48 656 656 656",
    srcNumberCountry: "PL",
    alreadyRead: false,
  },
  {
    id: _.uniqueId(),
    destNumber: "+1 234 567 890",
    messageGroups: [mockSmsMessages1, mockSmsMessages2],
    destNumberCountry: "US",
    srcNumber: "+48 656 656 656",
    srcNumberCountry: "DE",
    alreadyRead: false,
  },
];
