import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PulseLoader } from "react-spinners";
import { TEModal, TEModalContent, TEModalDialog } from "tw-elements-react";
const phoneUtil =
  require("google-libphonenumber").PhoneNumberUtil.getInstance();
const phoneNumberFormat = require("google-libphonenumber").PhoneNumberFormat;

import { IPhone } from "@no.id/web-common";
import { Call } from "@twilio/voice-sdk";
import { TwilioError } from "@twilio/voice-sdk/es5/twilio/errors";
import classNames from "classnames";
import moment from "moment";
import "react-simple-keyboard/build/css/index.css";
import { useDebounce } from "usehooks-ts";
import { PhoneCallEventsHandler } from "../../interface/phoneCallHandlers";
import {
  usePhones,
  usePhonesActions,
} from "../../providers/PhoneNumbersProvider";
import { useUserProfile } from "../../providers/UserProfileProvider";
import PhoneUtils from "../../utils/PhoneUtils";
import InfoView from "../InfoView";
import CustomButton from "../customButton";
import PhoneKeyboard from "../phoneKeyboard";
import PrimaryButton from "../primaryButton";
import SecondaryButton from "../secondaryButton/secondaryButton";
import Timer, { TimerHandles } from "../timer";

type PhoneCallStatus = "active" | "ringing" | "error";

export interface PhoneCallModalProps {
  isOpen: boolean;
  phone: IPhone;
  onClose: () => void;
}

export default function PhoneCallModal({
  isOpen,
  phone,
  onClose,
}: PhoneCallModalProps) {
  const { credits, decreaseCredits } = useUserProfile();

  const { phones: allMyPhones, activeOutgoingCall } = usePhones();
  const { computeCallCost, initiateOutgoingPhoneCall, hangupActivePhoneCall } =
    usePhonesActions();

  const [showModal, setShowModal] = useState(false); //isOpen);

  const [incomingCall, setIncomingCall] = useState(false);
  const [status, setStatus] = useState<PhoneCallStatus>();
  const [totalCallCost, setTotalCallCost] = useState(null);
  const [error, setError] = useState(null);
  const [isCallInProgress, setCallInProgress] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const debouncedPhoneNumber = useDebounce<string>(phoneNumber, 500);

  const [isCallCostComputed, setCallCostComputed] = useState(false);
  const [computeCallCostInProgress, setComputeCallCostInProgress] =
    useState(false);
  const [computeCallCostError, setComputeCallCostError] = useState(null);
  const [callCost, setCallCost] = useState<number>(77); //null);
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [connectionStartDate, setConnectionStartDate] =
    useState<moment.Moment>();

  const [canCall, setCanCall] = useState(false);

  // const activeCall = useRef<Call>();

  const timerRef = useRef<TimerHandles>(null);

  const onModalClose = useCallback(() => {
    setShowModal(false);
    onClose();
  }, [onClose]);

  const isPhoneNumberMine = (phoneNumber: string) => {
    const numberProto = phoneUtil.parse(phoneNumber, phone.country);
    const phoneNumberFriendlyName = phoneUtil.format(
      numberProto,
      phoneNumberFormat.E164
    );
    return (
      allMyPhones.findIndex(
        (phone) => phone.friendlyName == phoneNumberFriendlyName
      ) != -1
    );
  };

  const onPhoneCallEnd = useCallback(() => {
    timerRef.current?.end();
    // activeCall.current = undefined;
    setPhoneNumber("");
    setCallInProgress(false);
    setStatus(null);
  }, [timerRef]);

  const phoneCallStatusHandler = useMemo<PhoneCallEventsHandler>(() => {
    return {
      onAccept: (call: Call) => {
        console.log("The incoming call was accepted.");
        setConnectionStartDate(moment());
        setStatus("active");
        setCallInProgress(true);
        timerRef.current.start();
      },
      onRinging: (call: Call) => {
        console.log("Phone is ringing.");
        setCallInProgress(true);
        setTotalCallCost(null);
        setStatus("ringing");
      },
      onDisconnect: (call: Call) => {
        console.log("The call has been disconnected.");
        onPhoneCallEnd();
      },
      onReject: (call: Call) => {
        console.log("The call has been rejected.");
        onPhoneCallEnd();
      },
      onCancel: (call: Call) => {
        console.log("The call has been canceled.");
        onPhoneCallEnd();
      },
      onError: (call: Call) => {
        console.log("The call has been disconnected with error");
        onPhoneCallEnd();
        setStatus("error");
      },
      onDeviceError: (error: TwilioError, call: Call) => {
        onPhoneCallEnd();
        if (error && error.code == 31201) {
          setError("Microphone issue. Check your device.current.");
          return;
        }
        if (error) {
          setError("Unknown Device Error. Try again later.");
        }
        console.error("Device Error: " + JSON.stringify(error, null, 3));
        setStatus("error");
      },
    };
  }, [timerRef, setStatus]);

  const onCallClick = async () => {
    if (isPhoneNumberMine(phoneNumber)) {
      setError("You cannot call your own number");
      return;
    }

    if (!PhoneUtils.isNumberValid(phoneNumber, phone.country)) {
      setError("Invalid phone number");
      return;
    }

    const callCostInCredits = 77; //await computeCallCost(phone, phoneNumber);
    if (!callCostInCredits) {
      return setStatus(null);
    }

    setCallCost(callCostInCredits);
    if (callCostInCredits > credits) {
      return setStatus(null);
    }

    setStatus("ringing");
    initiateOutgoingPhoneCall(phone, phoneNumber); //, phoneCallStatusHandler);

    // setCallInProgress(true);
    // timerRef.current.start();
  };

  useEffect(() => {
    console.log(
      "Got active connection outgoing: " +
        JSON.stringify(activeOutgoingCall, null, 3)
    );

    if (!activeOutgoingCall) {
      //Do nothing, call ended
      return;
    }

    activeOutgoingCall.on("accept", phoneCallStatusHandler.onAccept);
    activeOutgoingCall.on("ringing", phoneCallStatusHandler.onRinging);
    activeOutgoingCall.on("disconnect", phoneCallStatusHandler.onDisconnect);
    activeOutgoingCall.on("reject", phoneCallStatusHandler.onReject);
    activeOutgoingCall.on("cancel", phoneCallStatusHandler.onCancel);
    activeOutgoingCall.on("error", phoneCallStatusHandler.onError);
  }, [activeOutgoingCall]);

  const computeCallCostForPhoneNumber = async (phoneNumber: string) => {
    setCallCostComputed(false);
    setComputeCallCostInProgress(true);
    setCanCall(false);

    //trackGoal(analyticsEvents.WEB_PHONE_COST_CLICKED, 0);
    const callCostInCredits = 77; //await computeCallCost(phone, phoneNumber);
    if (!callCostInCredits) {
      console.log("Cannot get call's cost: "); // + err);

      setCallCostComputed(false);
      setComputeCallCostInProgress(false);
      setCallCost(null);
      setCanCall(false);
      setComputeCallCostError("Error, please try again.");

      setTimeout(() => {
        setComputeCallCostError("null");
      }, 1500);
      //trackGoal(AnalyticsEvents.WEB_PHONE_COST_FAILED, 0);

      return;
    }

    //trackGoal(AnalyticsEvents.WEB_PHONE_COST_SUCCESS, 0);
    setCallCostComputed(true);
    setComputeCallCostInProgress(false);
    setCallCost(callCostInCredits);

    setCanCall(callCostInCredits <= credits);
  };

  const onKeyPress = useCallback(
    (value: string) => {
      if (status === "ringing" || status === "error") {
        return;
      }
      if (status === "active") {
        if (value !== "+") {
          activeOutgoingCall.sendDigits(value);
          setPhoneNumber((currentPhoneNumber) => currentPhoneNumber + value);
        }
        return;
      }

      if (value === "{backspace}") {
        setPhoneNumber((currentPhoneNumber) =>
          currentPhoneNumber.length <= 0 ? "" : currentPhoneNumber.slice(0, -1)
        );
      } else {
        setPhoneNumber((currentPhoneNumber) => currentPhoneNumber + value);
      }

      setCallCostComputed(false);
    },
    [status, activeOutgoingCall, setPhoneNumber]
  );

  const onHangUpClick = () => {
    timerRef.current.end();
    hangupActivePhoneCall();
    setStatus(undefined);

    // if (incomingCall) {
    //   IncomingCallManager.hangUp();

    //   setIncomingCall(false);
    //   setCallInProgress(false);
    //   setStatus(null);

    //   activeCall.current = undefined;
    // }
  };

  // const computeCallStatistics = (connectionEndDate) => {
  //   if (!connectionStartDate) {
  //     console.log("No connection happened - start date null");
  //     return;
  //   }
  //   console.log("startDate: " + connectionStartDate);
  //   console.log("endDate: " + connectionEndDate);

  //   //TODO: (!) change  It's a hack (read call duration hack description above)
  //   let callDurationInSec = connectionEndDate.diff(
  //     connectionStartDate,
  //     "seconds"
  //   );
  //   console.log("Call duration in seconds: " + callDurationInSec);

  //   let callDurationInMin = Math.ceil(callDurationInSec / 60);
  //   let totalCallCost = callDurationInMin * callCost;
  //   console.log("totalCallCost: $" + totalCallCost);

  //   setConnectionStartDate(undefined);
  //   setTotalCallCost(totalCallCost);

  //   decreaseCredits(totalCallCost);
  // };

  // showNotification = () => {
  //   const title = "Incoming call";
  //   const activeCall.current = IncomingCallManager.getActiveCall();
  //   const fromPhoneNumber =
  //     activeCall.current && activeCall.current.parameters && activeCall.current.parameters.From
  //       ? activeCall.current.parameters.From
  //       : "";
  //   const options = {
  //     body: fromPhoneNumber,
  //     requireInteraction: true,
  //   };

  //   const notification = new Notification(title, options);
  //   notification.onclick = () => {
  //     onPickUpClick();
  //   };
  // };

  useEffect(() => {
    setCanCall(callCost <= credits);
  }, [callCost]);

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (phone) {
      setIsNumberValid(PhoneUtils.isNumberValid(phoneNumber, phone.country));
    }
  }, [phoneNumber, phone]);

  useEffect(() => {
    if (
      debouncedPhoneNumber.length > 0 &&
      PhoneUtils.isNumberValid(debouncedPhoneNumber, phone.country)
    ) {
      computeCallCostForPhoneNumber(debouncedPhoneNumber);
    }
  }, [debouncedPhoneNumber]);

  return (
    <div>
      <TEModal
        show={showModal}
        setShow={setShowModal}
        backdrop={false}
        className="bg-neutral-100/80 pt-[10vh]"
      >
        <TEModalDialog>
          <TEModalContent className="p-10 !bg-brand_primary-0 max-w-[356px] flex flex-col gap-4 rounded-[12px]">
            <img
              className="h-2.5 absolute top-4 right-4 fill-brand_primary-100 cursor-pointer"
              src={require("../../assets/images/icon-close.svg")}
              onClick={onModalClose}
            />

            <h3 className="font-bold text-[40px] leading-[60px] text-neutral-100">
              New Call
            </h3>

            <div className="flex flex-row gap-1 px-4 py-2 bg-white rounded-[12px]">
              <div className="flex-1 flex-col gap-0.5">
                <div className="text-[12px] font-normal text-neutral-80 leading-4">
                  Your Number
                </div>
                <div className="text-[14px] leading-5 font-medium text-neutral-80">
                  {phone?.friendlyName}
                </div>
              </div>
              <img
                className="cursor-pointer"
                onClick={() => navigator.clipboard.writeText(phone.number)}
                src={require("../../assets/images/icon_copy_green.svg")}
              />
            </div>

            <div className="flex flex-row gap-2 p-3 bg-white border-[1px] border-neutral-5 rounded-[12px] items-center justify-center">
              <img
                src={require("../../assets/images/icon-phone.svg")}
                className="h-6"
              />
              <input
                name="phoneNumber"
                type="tel"
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
                placeholder="Number to Call"
                className="placeholder-neutral-60 flex-1 outline-none"
              />
            </div>

            <div>
              {!computeCallCostInProgress && !isCallCostComputed && (
                <div className="text-center text-[12px] text-brand_primary-100 leading-4 font-sans font-normal">
                  The cost of call will be displayed
                  <br />
                  after you typed the valid number.
                </div>
              )}

              {(computeCallCostInProgress || isCallCostComputed) && (
                <div className="flex flex-row gap-3 w-fit items-center justify-center m-auto  text-brand_primary-100 leading-4 font-sans font-normal text-[12px]">
                  <div>The Cost: </div>
                  {computeCallCostInProgress && <PulseLoader size={12} />}
                  {!computeCallCostInProgress && isCallCostComputed && (
                    <div>
                      <span className="text-[16px] font-semibold">
                        {callCost}
                      </span>{" "}
                      credits / min
                    </div>
                  )}
                </div>
              )}

              {isCallCostComputed && !canCall && (
                <div className="mt-2">
                  <InfoView
                    message={"You don't have enough credits"}
                    type="info"
                    size="fit"
                  />
                </div>
              )}

              {!computeCallCostError && isCallCostComputed && totalCallCost && (
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "300",
                    margin: "24px 0px 0px 0px",
                  }}
                >
                  Cost :{" "}
                  <span
                    style={{
                      marginLeft: "4px",
                      fontWeight: "700",
                      color: "rgb(79, 187, 188)",
                    }}
                  >
                    {totalCallCost} $
                  </span>
                </div>
              )}

              {computeCallCostError && (
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "300",
                    color: "red",
                    margin: "24px 0px 0px 0px",
                  }}
                >
                  {computeCallCostError}
                </div>
              )}
            </div>

            <div className="flex flex-col justify-end items-end">
              <PhoneKeyboard onKeyPress={onKeyPress} />
              {!status && phoneNumber.length > 0 && (
                <div className="p-2 bg-[#ececec] rounded-b-md mt-[-2px]">
                  <SecondaryButton
                    title="&#9003;"
                    className={classNames("text-[12px] bg-white w-20 h-10")}
                    width="auto"
                    onClick={() => {
                      console.log("Status on backpress: " + status);
                      onKeyPress("{backspace}");
                    }}
                  />
                </div>
              )}
            </div>

            <div className="flex flex-col gap-2">
              {!status && (
                <CustomButton
                  title="Call"
                  className={
                    "text-[14px] font-semibold text-white bg-secondary-100"
                  }
                  disabled={!isNumberValid || !canCall}
                  onClick={onCallClick}
                  width="full"
                  icon={require("../../assets/images/icon-phone-call.svg")}
                  iconDisabled={require("../../assets/images/icon-phone-call-disabled.svg")}
                />
              )}

              {!status && (
                <SecondaryButton
                  title="Close"
                  className={classNames(
                    "text-[12px] bg-white text-neutral-100"
                  )}
                  // disabled={inProgress}
                  width="full"
                  onClick={onModalClose}
                />
              )}

              {canCall && isCallInProgress && (
                <div className="flex w-full flex-col justify-center items-center">
                  <Timer ref={timerRef} />

                  {status == "ringing" && <PulseLoader size={12} />}

                  {(status == "ringing" || status == "active") && (
                    <PrimaryButton
                      title="Hang Up"
                      className={classNames("!text-[14px] !bg-error-50 mt-2")}
                      onClick={onHangUpClick}
                      width="full"
                      icon={require("../../assets/images/icon-phone-hangup.svg")}
                      iconDisabled={require("../../assets/images/icon-phone-hangup.svg")}
                    />
                  )}
                </div>
              )}
            </div>

            {status === "error" && (
              <InfoView message={error} type="error" size="fit" />
            )}

            <div className="font-sans text-neutral-100 text-[14px] font-semibold heading-5 text-center">
              Maximum call duration is 3min.
            </div>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </div>
  );
}
