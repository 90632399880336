import { trackGoal } from "fathom-client";
import _ from "lodash";
import React, { useState } from "react";
import { isMobileOnly } from "react-device-detect";
import Select, { components } from "react-select";
import { Icon } from "semantic-ui-react";
import AnalyticsEvents from "../../events/analyticsEvents";
import { usePhonesActions } from "../../providers/PhoneNumbersProvider";
import { useUserProfile } from "../../providers/UserProfileProvider";
import PrimaryButton from "../primaryButton";
import "./BuyPhoneNumberView.scss";

import iconArrowDown from "../../assets/images/icon-filter-arrow-down.svg";

import classNames from "classnames";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const style = {
  control: (base) => ({
    ...base,
    boxSizing: "border-box",
    width: "100%",
    height: "50px",
    padding: "0px 16px",
    border: "1px solid #EAEBEB",
    borderRadius: "12px",
    cursor: "pointer",
  }),
};

const countryOptions = [
  {
    key: "us",
    value: "us",
    flag: "fi fi-us",
    label: "United States",
    cost: "2",
  },
  { key: "ca", value: "ca", flag: "fi fi-ca", label: "Canada", cost: "2" },
  { key: "is", value: "is", flag: "fi fi-is", label: "Iceland", cost: "10" },
];

const CustomOption = ({ innerProps, isDisabled, data }) =>
  !isDisabled ? (
    <div
      {...innerProps}
      className="text-[14px] h-12 leading-4 flex flex-row items-center gap-2 p-3 cursor-pointer hover:bg-cyan"
    >
      <div className={data.flag}></div>
      <div>{data.label}</div>
    </div>
  ) : null;

const Control = ({ children, ...props }) => (
  <components.Control {...props}>
    <img alt="" src={require("../../assets/images/icon-world.svg")} />{" "}
    {children}
  </components.Control>
);

const DropdownIndicator = ({ ...props }) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={iconArrowDown} alt="" />
    </components.DropdownIndicator>
  );
};

export default function BuyPhoneNumberView(props) {
  const [inProgress, setInProgress] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [phoneMetadata, setPhoneMetadata] = useState(null);
  const [showForm, setShowForm] = useState(() => !isMobileOnly);

  const { getNewPhone } = usePhonesActions();

  const credits = useUserProfile().credits;
  const decreaseCredits = useUserProfile().decreaseCredits;

  const onCountryChange = (e, { value, options }) => {
    let buyPhoneMeta = _.find(options, (o) => {
      return o.value === value;
    });
    setPhoneMetadata(buyPhoneMeta);
  };

  const handleBuyPhoneClick = () => {
    if (!showForm) {
      setShowForm(true);
      return;
    }

    setInProgress(true);
    setError(null);
    trackGoal(AnalyticsEvents.WEB_PHONE_BUY, 0);

    getNewPhone(phoneMetadata.value, (newPhone) => {
      if (newPhone == null) {
        setError("Error. Please try again.");
        setMessage(null);
        setInProgress(false);
        trackGoal(AnalyticsEvents.WEB_PHONE_BUY_FAILED, 0);

        setTimeout(() => {
          setError(null);
        }, 2500);
        return;
      }

      decreaseCredits(phoneMetadata.cost);

      setError(null);
      setMessage("Your new number is ready");
      setPhoneMetadata(null);
      setInProgress(false);
      trackGoal(AnalyticsEvents.WEB_PHONE_BUY_SUCCESS, 0);

      setTimeout(() => {
        setMessage(null);
      }, 2500);
    });

    // setTimeout(() => {
    //     getNewPhone(phoneMetadata.value)
    //         .then(newPhone => {
    //
    //             console.log("New number: " + JSON.stringify(newPhone, null, 3));
    //             decreaseCredits(phoneMetadata.cost);
    //
    //             setError(null);
    //             setMessage("Your new number is ready");
    //             setPhoneMetadata(null);
    //             setInProgress(false);
    //
    //             setTimeout(() => {
    //                 setMessage(null)
    //             }, 2500);
    //
    //         })
    //         .catch(err => {
    //             console.log("BuyPhoneNumberView | getNew | err: " + err);
    //             setError("Error. Please try again.");
    //             setMessage(null);
    //             setInProgress(false);
    //
    //             setTimeout(() => {
    //                 setError(null)
    //             }, 2500)
    //         });
    // }, 2000);
  };

  return (
    <div
      className={classNames(
        "flex w-full lg:py-6 lg:items-center lg:min-h-[196px] md:w-fit md:max-w-[70%] lg:max-w-none",
        {
          "rounded-[16px] border-[1px] border-neutral-5 p-4 bg-white md:bg-transparent md:border-0":
            showForm,
        }
      )}
    >
      <div className="flex flex-col flex-wrap gap-4 w-full">
        <div className="flex flex-col flex-wrap w-full gap-2">
          <div className="flex flex-row flex-nowrap w-full items-center justify-between md:justify-start">
            <div className="w-[60%] md:w-full text-neutral-100 text-[16px] md:text-[20px] font-semibold leading-6 lg:leading-8">
              New Phone Number
            </div>

            <PrimaryButton
              className={classNames("text-[12px] scale-[0.85] md:hidden", {
                "bg-transparent border-0": showForm,
              })}
              disabled={inProgress}
              onClick={() => setShowForm((v) => !v)}
              width="fit"
              icon={require(!showForm
                ? "../../assets/images/icon-plus.svg"
                : "../../assets/images/icon-close.svg")}
            />
          </div>

          <div
            className={classNames(
              `md:block text-neutral-80 text-[14px] font-normal leading-5`,
              { hidden: !showForm },
              { "md:w-[80%] lg:w-full": showForm }
            )}
          >
            Your privacy is protected. <br className="mobile-break" />
            Phones are registered to no.ID ehf.
          </div>
        </div>

        {showForm && !error && !message && (
          <div className="flex flex-row flex-wrap gap-6 items-center w-full min-w-[226px]">
            <Select
              className="w-full md:w-fit md:flex-1 lg:flex-none lg:w-[226px] h-[48px] md:min-w-[164px] text-[14px]"
              placeholder="Select Country"
              styles={style}
              isSearchable={true}
              options={countryOptions}
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option: CustomOption,
              }}
              value={phoneMetadata}
              onChange={setPhoneMetadata}
            />

            <div className="flex flex-row gap-3 items-center min-w-[100px]">
              <img
                className="h-6 lg:ml-0"
                src={require("../../assets/images/icon-credits.svg")}
              />

              <div className="flex flex-row gap-3 text-neutral-80 text-[14px] leading-5">
                <span
                  className={classNames(
                    { "font-normal": !phoneMetadata },
                    { "font-semibold": phoneMetadata }
                  )}
                >
                  {phoneMetadata ? phoneMetadata.cost : 0}
                </span>
                <div>credits / month</div>
              </div>
            </div>

            <PrimaryButton
              className="text-[12px] min-w-[204px] w-full md:w-fit md:flex-1 lg:flex-none"
              disabled={
                !phoneMetadata ||
                inProgress ||
                (phoneMetadata && phoneMetadata.cost > credits)
              }
              onClick={handleBuyPhoneClick}
              title="Buy phone number"
              width="fit"
              icon={require("../../assets/images/icon-cart.svg")}
              iconDisabled={require("../../assets/images/icon-cart-disabled.svg")}
            />
          </div>
        )}

        {message && (
          <div className="buy-phone-message-wrapper">
            <div className="buy-phone-success">
              <Icon name="checkmark" />
              <span>{message}</span>
            </div>
          </div>
        )}

        {error && (
          <div className="buy-phone-message-wrapper">
            <div className="buy-phone-error">
              <Icon name="warning circle" />
              {error}
            </div>
          </div>
        )}
      </div>

      {/* {isMobileOnly && !message && !error && (
        <Button
          className="buy-phone-number-buy-btn"
          disabled={
            showForm &&
            (!phoneMetadata ||
              inProgress ||
              (phoneMetadata && phoneMetadata.cost > credits))
          }
          onClick={handleBuyPhoneClick}
        >
          {!inProgress && (
            <div>
              {showForm && (
                <>
                  <Icon
                    style={{
                      color: "#ffffff",
                      fontSize: "16px",
                      marginRight: "16px",
                    }}
                    name="shopping cart"
                  />
                  <span style={{}}>Buy Number</span>
                </>
              )}
            </div>
          )}

          {inProgress && (
            <PulseLoader size={10} color={"#ffffff"} loading={true} />
          )}
        </Button>
      )} */}
    </div>
  );
}
