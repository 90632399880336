import React, { useCallback } from "react";

import "../MessageReader.scss";

import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import MailItemSummary from "../maiItemSummary/mailItemSummary";

import styles from "./mailList.module.scss";
import { useIdentities } from "../../providers/IdentityProvider";
import { useEmailActions } from "../../providers/EmailProvider";

export default function MailList(props) {
  const { identities } = useIdentities();
  const { isAlreadyRead } = useEmailActions();

  const MailItemRow = useCallback(
    ({ data, index, style }) => (
      <div style={style}>
        <MailItemSummary
          alreadyRead={isAlreadyRead(data[index].mailId)}
          summary={data[index]}
          identity={identities?.find((id) => id.id === data[index].identityId)}
          handleClick={() => {
            props.onMailSelect(index);
          }}
        />
      </div>
    ),
    [identities, props, isAlreadyRead]
  );

  return (
    <div className="h-[calc(100vh-170px)] lg:h-[calc(100vh-100px)]">
      <AutoSizer>
        {({ height, width }) => {
          return (
            <List
              itemKey={(idx) => (props.summaries ? props.summaries[idx].id : 0)}
              itemData={props.summaries}
              height={height}
              itemCount={props.summaries ? props.summaries.length : 0}
              itemSize={108}
              width={width}
              className={styles.listWrapper}
            >
              {MailItemRow}
            </List>
          );
        }}
      </AutoSizer>
    </div>
  );
}
