import React, {useEffect, useState} from "react";
import "./IdentityList.scss";
import { Icon } from "semantic-ui-react";
import _ from "lodash";
import { useIdentities } from "../../providers/IdentityProvider";
import IdentityView from "../identityView/identityView";

function IdentityList(props) {
  const { identities } = useIdentities();
  const [ filteredIdentities, setFilteredIdentities ] = useState([]);

  const filterIdentity = (i) => {
    const phrase = props.searchPhrase.toLowerCase();
    return i.domain.toLowerCase().includes(phrase) ||
        i.email.toLowerCase().includes(phrase) ||
        i.note.toLowerCase().includes(phrase) ||
        i.profile.username.toLowerCase().includes(phrase) ||
        i.profile.firstname.toLowerCase().includes(phrase) ||
        i.profile.lastname.toLowerCase().includes(phrase);
  }

  useEffect(() => {
    if (!props.searchPhrase) {
      setFilteredIdentities(identities);
    } else {
      setFilteredIdentities(identities.filter(filterIdentity));
    }
  }, [props.searchPhrase, identities]);

  return (
    <div className="px-6">

      {filteredIdentities && (
        <div className="flex flex-wrap gap-6 w-full">
          {_.map(filteredIdentities, (id) => (
            <IdentityView key={id.id} id={id} />
          ))}
        </div>
      )}

      {filteredIdentities && filteredIdentities.length === 0 && (
        <div
          style={{
            color: "grey",
            fontSize: "35px",
            marginTop: "24px",
            fontWeight: "300",
            width: "100%",
            textAlign: "center",
          }}
        >
          {!props.searchPhrase && (
              <>
                <div style={{ opacity: "0.35" }}>Empty</div>
                <Icon
                    name="users"
                    size="huge"
                    style={{ marginTop: "8px", opacity: "0.35" }}
                />
              </>
          )}
          <div
            style={{
              fontSize: "16px",
              marginTop: "24px",
              fontWeight: "300",
              opacity: "0.75",
            }}
          >
            {props.searchPhrase ? 'No identities' : 'Create at least one ID to start protecting your privacy.'}
          </div>
        </div>
      )}
    </div>
  );
}

export default IdentityList;
