import React from "react";
import { CompromisedIcon } from "./common/icons/CompromisedIcon";
import { SafeIcon } from "./common/icons/SafeIcon";

export interface DomainAvatarWithStatusProps {
  domain: string;
  isCompromised: boolean;
}

export default function DomainAvatarWithStatus({
  domain,
  isCompromised,
}: DomainAvatarWithStatusProps) {
  return (
    <div className=" relative">
      <div className="h-10 w-10 border-[1px] rounded-[8px] flex items-center">
        <img
          className="rounded-[8px] border-[1px]"
          src={"https://logo.clearbit.com/" + domain + "?size=40"}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "/images/generic-40.png";
          }}
        />
      </div>
      <div className="absolute -bottom-3 -right-3">
        {isCompromised ? <CompromisedIcon /> : <SafeIcon />}
      </div>
    </div>
  );
}
