import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import bold from "../../assets/images/icon_bold.svg";
import center from "../../assets/images/icon_center.svg";
import color from "../../assets/images/icon_color.svg";
import emoji from "../../assets/images/icon_emoji.svg";
import fontSize from "../../assets/images/icon_fontSize.svg";
import indent from "../../assets/images/icon_indent.svg";
import italic from "../../assets/images/icon_italic.svg";
import justify from "../../assets/images/icon_justify.svg";
import left from "../../assets/images/icon_left.svg";
import link from "../../assets/images/icon_link.svg";
import ordered from "../../assets/images/icon_ordered.svg";
import outdent from "../../assets/images/icon_outdent.svg";
import right from "../../assets/images/icon_right.svg";
import strikethrough from "../../assets/images/icon_strikethrough.svg";
import subscript from "../../assets/images/icon_subscript.svg";
import superscript from "../../assets/images/icon_superscript.svg";
import underline from "../../assets/images/icon_underline.svg";
import unlink from "../../assets/images/icon_unlink.svg";
import unordered from "../../assets/images/icon_unordered.svg";

const btnClassName =
  "border-none rounded-lg hover:bg-neutral-200 hover:shadow-none shadow-none";

// https://jpuri.github.io/react-draft-wysiwyg/#/docs
const toolbar = {
  options: [
    "inline",
    "fontSize",
    "fontFamily",
    "list",
    "textAlign",
    "colorPicker",
    "emoji",
  ],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["bold", "italic", "underline", "strikethrough"],
    bold: { icon: bold, className: btnClassName },
    italic: { icon: italic, className: btnClassName },
    underline: { icon: underline, className: btnClassName },
    strikethrough: { icon: strikethrough, className: btnClassName },
    superscript: { icon: superscript, className: btnClassName },
    subscript: { icon: subscript, className: btnClassName },
  },
  fontSize: {
    icon: fontSize,
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontFamily: {
    options: [
      "Arial",
      "Georgia",
      "Impact",
      "Tahoma",
      "Times New Roman",
      "Verdana",
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["unordered", "ordered", "indent", "outdent"],
    unordered: { icon: unordered, className: btnClassName },
    ordered: { icon: ordered, className: btnClassName },
    indent: { icon: indent, className: btnClassName },
    outdent: { icon: outdent, className: btnClassName },
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["left", "center", "right", "justify"],
    left: { icon: left, className: btnClassName },
    center: { icon: center, className: btnClassName },
    right: { icon: right, className: btnClassName },
    justify: { icon: justify, className: btnClassName },
  },
  colorPicker: {
    icon: color,
    className: btnClassName,
    component: undefined,
    popupClassName: undefined,
    colors: [
      "rgb(97,189,109)",
      "rgb(26,188,156)",
      "rgb(84,172,210)",
      "rgb(44,130,201)",
      "rgb(147,101,184)",
      "rgb(71,85,119)",
      "rgb(204,204,204)",
      "rgb(65,168,95)",
      "rgb(0,168,133)",
      "rgb(61,142,185)",
      "rgb(41,105,176)",
      "rgb(85,57,130)",
      "rgb(40,50,78)",
      "rgb(0,0,0)",
      "rgb(247,218,100)",
      "rgb(251,160,38)",
      "rgb(235,107,86)",
      "rgb(226,80,65)",
      "rgb(163,143,132)",
      "rgb(239,239,239)",
      "rgb(255,255,255)",
      "rgb(250,197,28)",
      "rgb(243,121,52)",
      "rgb(209,72,65)",
      "rgb(184,49,47)",
      "rgb(124,112,107)",
      "rgb(209,213,216)",
    ],
  },
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: "_self",
    options: ["link", "unlink"],
    link: { icon: link, className: btnClassName },
    unlink: { icon: unlink, className: btnClassName },
    linkCallback: undefined,
  },
  emoji: {
    icon: emoji,
    className: btnClassName,
    component: undefined,
    popupClassName: undefined,
    emojis: [
      "😀",
      "�",
      "😂",
      "😃",
      "😉",
      "😋",
      "😎",
      "😍",
      "😗",
      "🤗",
      "🤔",
      "😣",
      "😫",
      "😴",
      "😌",
      "🤓",
      "😛",
      "😜",
      "😠",
      "😇",
      "😷",
      "😈",
      "👻",
      "😺",
      "😸",
      "😹",
      "😻",
      "😼",
      "😽",
      "🙀",
      "🙈",
      "🙉",
      "",
      "👼",
      "👮",
      "🕵",
      "💂",
      "👳",
      "🎅",
      "👸",
      "👰",
      "👲",
      "🙍",
      "🙇",
      "🚶",
      "🏃",
      "💃",
      "⛷",
      "🏂",
      "🏌",
      "🏄",
      "🚣",
      "🏊",
      "⛹",
      "🏋",
      "🚴",
      "👫",
      "💪",
      "👈",
      "👉",
      "👉",
      "👆",
      "🖕",
      "👇",
      "",
      "🤘",
      "🖐",
      "👌",
      "👍",
      "👎",
      "✊",
      "👊",
      "👏",
      "🙌",
      "🙏",
      "🐵",
      "🐶",
      "🐇",
      "🐥",
      "🐸",
      "",
      "🐛",
      "🐜",
      "🐝",
      "🍉",
      "🍄",
      "🍔",
      "🍤",
      "🍨",
      "🍪",
      "🎂",
      "🍰",
      "🍾",
      "🍷",
      "🍸",
      "🍺",
      "🌍",
      "🚑",
      "⏰",
      "🌙",
      "🌝",
      "🌞",
      "⭐",
      "🌟",
      "🌠",
      "🌨",
      "🌩",
      "⛄",
      "🔥",
      "🎄",
      "🎈",
      "🎉",
      "🎊",
      "🎁",
      "🎗",
      "🏀",
      "🏈",
      "🎲",
      "🔇",
      "🔈",
      "📣",
      "🔔",
      "🎵",
      "🎷",
      "💰",
      "🖊",
      "📅",
      "✅",
      "❎",
      "💯",
    ],
  },
};

const MailSendEditor = (props) => {
  const onChange = (value) => {
    props.onChange(draftToHtml(convertToRaw(value.getCurrentContent())));
  };

  return (
    <Editor
      wrapperClassName="w-full flex-1 flex flex-col" //h-72 min-[768px]:h-56 min-[1200px]:h-64 border-2 "
      toolbarClassName="max-[768px]:hidden rounded-lg"
      editorClassName="flex-1 overflow-y-auto break-words py-1 px-1"
      onEditorStateChange={onChange}
      toolbar={toolbar}
    />
  );
};

export default MailSendEditor;
