const missions = [
    {
        url: "https://vimeo.com/706995265",
        isVideo: true,
        title: 'Hello! 🎉'
    },
    {
        url: "https://form.typeform.com/to/wMg17N5l",
        identityProtected: true,
        identityDomain: 'pl.tommy.com',
        title: 'First question!'
    },
    {
        url: "https://form.typeform.com/to/LM83KvS0",
        identityProtected: true,
        identityDomain: "znanylekarz.pl",
        title: 'Second question!'
    },
    {
        url: "https://form.typeform.com/to/P1r7OthB",
        passwordProtected: true,
        password: "Hr64tj6m",
        title: 'Third question!'
    },
    {
        url: "https://form.typeform.com/to/JunpDnb7",
        passwordProtected: true,
        password: "rMp2bLe5",
        title: 'Fourth question!'
    },
    {
        url: "https://form.typeform.com/to/tE2X20Qr",
        title: 'Fifth question!'
    },
    {
        url: "https://vimeo.com/706998554",
        isVideo: true,
        title: 'Thank you! 🎉'
    }
];

export default missions;