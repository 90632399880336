import React from "react";
import SecondaryButton from "../secondaryButton/secondaryButton";
import "/node_modules/flag-icons/css/flag-icons.min.css";

export interface PhoneNumberCardProps {
  phone: any;
  unreadSmsCounter?: number;
  onSmsButtonClick: () => void;
  onCallButtonClick: () => void;
  onDeleteButtonClick: () => void;
  onRenewButtonClick: () => void;
}

const NotificationIndicator = ({ value }: { value: number }) => {
  if (!value) return undefined;

  return (
    <div className="rounded-[6px] bg-[#F4484E] text-white py-[2px] px-2 text-[12px] font-semibold heading-[16px] w-fit">
      {value}
    </div>
  );
};

export default function PhoneNumberCard({
  phone,
  unreadSmsCounter,
  onSmsButtonClick,
  onCallButtonClick,
  onDeleteButtonClick,
  onRenewButtonClick,
}: PhoneNumberCardProps) {
  return (
    <>
      <div
        key={phone.id}
        className="flex flex-col gap-4 px-6 pt-6 pb-4 rounded-[12px] border-[1px] border-neutral-0 bg-white w-full md:w-[281px] shadow-[0_1px_2px_0_rgba(16,24,40,0.05)]"
      >
        <>
          {phone.isActive && phone.renew && (
            <div className="bg-green-100 py-3 px-2 text-center rounded-[12px] font-normal leading-5 text-[14px]">
              The number will renew soon
            </div>
          )}
        </>
        <>
          {!phone.isActive && (
            <div className="flex flex-row bg-red-100 py-3 px-2 items-center justify-between rounded-[12px] font-normal leading-5 text-[14px]">
              The number is deactivated
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={onRenewButtonClick}
                aria-label="Close"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Group">
                    <path
                      id="Path"
                      d="M4.16675 9.99992L8.33341 14.1666L16.6667 5.83325"
                      stroke="#32B852"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </button>
            </div>
          )}
        </>

        <div className="flex flex-row gap-3">
          <div className={`fi fi-${phone.country?.toLowerCase()}`} />
          <span className="phone-number">{phone.friendlyName}</span>
        </div>

        <div className="flex flex-row gap-2">
          <SecondaryButton
            className="basis-5/12 bg-secondary-100 text-white text-[12px] h-8"
            title="Call"
            icon={require("../../assets/images/icon-call.svg")}
            onClick={onCallButtonClick}
          />

          <div className="basis-5/12 relative min-w-[45%]">
            <SecondaryButton
              className="bg-eastern_blue w-full md:min-w-[88px] text-white text-[12px] h-8"
              title="SMS"
              icon={require("../../assets/images/icon-sms.svg")}
              onClick={onSmsButtonClick}
            />

            <div className="absolute top-[-8px] right-[-8px]">
              <NotificationIndicator value={unreadSmsCounter} />
            </div>
          </div>

          <div className="flex basis-2/12 justify-center">
            <button
              className="bg-transparent border-none h-8"
              onClick={onDeleteButtonClick}
            >
              <img src={require("../../assets/images/icon-trash.svg")} alt="" />
            </button>
          </div>

          {/* <Popup
          trigger={
            <SecondaryButton
              className="border-[1px] border-gray-200 h-8 px-1.5 md:px-3 min-w-[40px]"
              icon={require("../../assets/images/icon-3dots.svg")}
            />
          }
        >
          More options soon
        </Popup> */}
        </div>

        <div className="border-t-[1px] border-neutral-0 mr-[-24px] ml-[-24px] px-6 pt-4 flex flex-row items-center justify-between">
          <div>
            {phone.note && <div>{phone.note}</div>}
            {!phone.note && (
              <div className="flex flex-row gap-2 items-center">
                <span className="text-gray-400 text-[12px] font-normal">
                  No note
                </span>
              </div>
            )}
          </div>
          <img
            src={require("../../assets/images/icon-edit.svg")}
            className="h-5"
          />
        </div>
      </div>
    </>
  );
}
