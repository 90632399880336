import { LiveQueryType } from "@no.id/web-common";
import { proxy } from "comlink";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import noidWorkerApi from "../NoidWorkerAPI";
import useLiveQuery from "../hooks/useLiveQuery";
import { useSyncState } from "./SyncStateProvider";

const OnboardingContext = React.createContext();
const OnboardingUpdateContext = React.createContext();

export function useOnboarding() {
  return useContext(OnboardingContext);
}

export function useOnboardingUpdate() {
  return useContext(OnboardingUpdateContext);
}

export function OnboardingProvider({ children }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const onboarding = useLiveQuery(LiveQueryType.ONBOARDING);
  const { isSynced } = useSyncState();

  const updateOnboardingStep = useCallback((step, callback) => {
    noidWorkerApi.OnboardingService.updateOnboarding(
      step,
      proxy((onboarding) => {
        callback(onboarding);
      })
    );
  }, []);

  useEffect(() => {
    if (isSynced && !isLoaded) {
      setIsLoaded(true);
    }
  }, [isSynced]);

  const providerValue = useMemo(
    () => ({
      onboarding,
      isLoaded,
    }),
    [onboarding, isLoaded]
  );

  const onboardingActions = useMemo(
    () => ({
      updateOnboardingStep,
    }),
    [updateOnboardingStep]
  );

  return (
    <OnboardingContext.Provider value={providerValue}>
      <OnboardingUpdateContext.Provider value={onboardingActions}>
        {children}
      </OnboardingUpdateContext.Provider>
    </OnboardingContext.Provider>
  );
}
