import { envConfig } from "@no.id/web-common";
import React from "react";
import PrimaryButton from "../primaryButton";

export default function GetExtensionView() {
  return (
    <div className="hidden lg:flex flex-col gap-4 w-full px-3 py-6 rounded-[12px] bg-cyan border-b-[1px] border-b-[rgba(39, 165, 165, 0.20)]">
      <div className="text-[24px] text-center">😮</div>
      <div className="widget-addon-panel-description">
        <div>Create and use Identity with</div>
        <div>
          <span className="font-medium">One Click </span> at every service
        </div>
      </div>
      <a href={envConfig.WIDGET_URL} rel="noopener noreferrer" target="_blank">
        <PrimaryButton
          className="text-[12px] leading-4 py-2"
          title="Get Browser Extension"
          type="compact"
          width="full"
          icon={require("../../assets/images/icon-puzzle.svg")}
        />
      </a>
    </div>
  );
}
