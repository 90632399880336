import classNames from "classnames";
import React, { useState } from "react";
import Select, { components } from "react-select";
import iconArrowDown from "../../assets/images/icon-filter-arrow-down.svg";
import iconFilter from "../../assets/images/icon-mail-filter.svg";
import iconPencil from "../../assets/images/icon-pencil.svg";
import iconRefresh from "../../assets/images/icon-refresh.svg";
import { useEmailActions } from "../../providers/EmailProvider";
import MailSendModal from "../mailSendModal/mailSendModal";
import styles from "./mailboxIdentityFilter.module.scss";

const CustomOption = ({ innerProps, isDisabled, data }) =>
  !isDisabled ? (
    <div {...innerProps} className={`${styles.optionWrapper}`}>
      {data.content}
    </div>
  ) : null;

const Control = ({ children, ...props }) => (
  <components.Control {...props}>
    <img alt="" className="h-4" src={iconFilter} /> {children}
  </components.Control>
);

const DropdownIndicator = ({ ...props }) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={iconArrowDown} alt="" />
    </components.DropdownIndicator>
  );
};

const style = {
  control: (base) => ({
    ...base,
    boxSizing: "border-box",
    width: "100%",
    height: "50px",
    padding: "0px 16px",
    border: "1px solid #EAEBEB",
    borderRadius: "12px",
    cursor: "pointer",
  }),
};

const btnMailStyle = classNames(
  "flex flex-row flex-center items-center gap-3 px-4 py-3 rounded-[12px] border-[1px] border-neutral-5",
  "text-[14px] leading-5 font-semibold text-neutral-10"
);

export default function MailboxIdentityFilter(props) {
  // eslint-disable-next-line
  const [currentIdentity, setCurrentIdentity] = useState(
    props.currentIdentityId
  );
  // eslint-disable-next-line
  const [isRefreshVisible, setIsRefreshVisible] = useState(true);
  const { refresh } = useEmailActions();
  const [selectedValue, setSelectedValue] = useState();
  const [isSendMailModalOpen, setIsSendMailModalOpen] = useState(false);

  const onIdentityChange = (selected) => {
    const value = selected ? selected.value : null;
    setSelectedValue(selectedValue);
    setCurrentIdentity(value);
    if (props.onIdentityChange) {
      props.onIdentityChange(value);
    }
  };

  const hideRefresh = (hide) => () => {
    setIsRefreshVisible(!hide);
  };

  const showSendMailModal = () => {
    setIsSendMailModalOpen(true);
  };

  const closeSendMailModal = () => {
    setIsSendMailModalOpen(false);
  };

  return (
    <div className="flex flex-row gap-4 w-full mb-6 lg:mb-3">
      <Select
        className="flex-1 h-12 text-xs sm:text-base"
        placeholder="Filter by Identity"
        styles={style}
        classNames={{
          menu: () =>
            "rounded-xl border-[1px] border-white z-20 w-[90vw] sm:w-full",
        }}
        isSearchable={true}
        isClearable={true}
        options={props.identityOptions}
        components={{
          Control,
          DropdownIndicator,
          IndicatorSeparator: () => null,
          Option: CustomOption,
        }}
        value={selectedValue}
        onMenuOpen={hideRefresh(true)}
        onMenuClose={hideRefresh(false)}
        onChange={onIdentityChange}
      />

      <button onClick={refresh} className={btnMailStyle}>
        <img src={iconRefresh} className="max-w-none" alt="" />
      </button>

      <button onClick={showSendMailModal} className={btnMailStyle}>
        <img src={iconPencil} className="max-w-none" alt="" />
        <div className="hidden md:block">Create</div>
      </button>

      {isSendMailModalOpen && (
        <MailSendModal
          show={isSendMailModalOpen}
          onClose={closeSendMailModal}
        />
      )}
    </div>
  );
}
