import classNames from "classnames";
import React from "react";
import Modal from "../Modal";

const btnConfirmClass = classNames(
  "flex flex-center items-center justify-center px-4 py-3 rounded-[8px] border-[1px] border-error-50",
  "text-xs leading-4 font-semibold text-white bg-error-50 cursor-pointer"
);

const btnCancelClass = classNames(
  "flex flex-center items-center justify-center px-4 py-3 rounded-[8px] border-[1px] border-neutral-40",
  "text-xs leading-5 font-semibold text-black bg-white text-neutral-100 cursor-pointer"
);

export interface IdentityDeanonymizationModalProps {
  show: boolean;
  onSave: () => void;
  setShow: (value: boolean) => void;
}

export default function IdentityDeanonymizationModal({
  show,
  onSave,
  setShow,
}: IdentityDeanonymizationModalProps) {
  return (
    <Modal
      isOpen={show}
      nestedModal={true}
      backdropColor="bg-white/75"
      style="z-20"
    >
      <div
        className="bg-white pt-6 px-5 pb-4 rounded-[12px] border-[1px] flex flex-col gap-2 brand-neutral-100
       text-neutral-100 w-[320px] shadow-lg shadow-[#10182810]"
      >
        <div className="text-[18px] font-semibold font-sans leading-6 text-left">
          Potential Deanonymization Risk
        </div>
        <div className="mb-2 font-sans text-sm heading-5 font-normal text-left">
          Are you sure you want to manually select an identity? Doing so may
          increase the risk of linking your profiles across domains and exposing
          more information about you.
        </div>
        <button type="button" className={btnConfirmClass} onClick={onSave}>
          Yes, I understand the risk
        </button>
        <button
          type="button"
          className={btnCancelClass}
          onClick={() => setShow(false)}
        >
          No, I wasn't aware of the risk
        </button>
      </div>
    </Modal>
  );
}
