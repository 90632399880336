import * as Fathom from "fathom-client";
import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import { RecognitionDashboard } from "./components/RecognitionDashboard";
import IdentityDashboard from "./components/identityDashboard/IdentityDashboard";
import Login from "./components/login/Login";
import MailboxView from "./components/mailboxView/MailboxView";
import Menu from "./components/menu/Menu";
import { PhoneDashboard } from "./components/phoneDashboard/PhoneDashboard";
import SignUp from "./components/signUp/SignUp";
import AnalyticsEvents from "./events/analyticsEvents";
import { useAuth } from "./providers/AuthProvider";
import { EmailProvider } from "./providers/EmailProvider";
import { IdentityProvider } from "./providers/IdentityProvider";
import { OnboardingProvider } from "./providers/OnboardingProvider";
import { PhonesProvider } from "./providers/PhoneNumbersProvider";
import { SmsProvider } from "./providers/SmsProvider";
import { SyncStateProvider } from "./providers/SyncStateProvider";
import { UserProfileProvider } from "./providers/UserProfileProvider";
// import MetaTags from 'react-meta-tags';

export default function App(props) {
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    const resizeListener = () => {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    resizeListener();

    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  Fathom.load(AnalyticsEvents.APP_CODE);
  return (
    <>
      {isLoggedIn != undefined && (
        <div className="App">
          {/* <MetaTags>
            <meta
              name="viewport"
              content="width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"
            />
          </MetaTags> */}
          {isLoggedIn && (
            <SyncStateProvider>
              <IdentityProvider>
                <EmailProvider>
                  <PhonesProvider>
                    <SmsProvider>
                      <OnboardingProvider>
                        <UserProfileProvider>
                          <div className="flex flex-row overflow-y-hidden">
                            <Menu isMobile={false} />
                            <Switch>
                              <Route
                                path={"login"}
                                render={() => <Redirect to="/main" />}
                              />
                              <Route
                                path={"/register"}
                                render={() => <Redirect to="/main" />}
                              />

                              <Route
                                path="/main"
                                exact
                                component={IdentityDashboard}
                              />
                              {/*<ProtectedRoute path='/main' exact component={IdentityDashboard}/>*/}
                              <Route
                                path="/message"
                                exact
                                component={MailboxView}
                              />
                              {/*<ProtectedRoute path='/message' exact component={MessageReader}/>*/}
                              {/*<ProtectedRoute path='/message/:id' exact component={MessageReader}/>*/}
                              {/*<ProtectedRoute path='/phones' exact component={PhoneDashboard}/>*/}
                              <Route
                                path="/phones"
                                exact
                                component={PhoneDashboard}
                              />
                              <Route
                                path="/recognition"
                                exact
                                component={RecognitionDashboard}
                              />
                              {/*<ProtectedRoute path='/recognition' exact component={RecognitionDashboard}/>*/}
                              <Route render={() => <Redirect to="/main" />} />
                            </Switch>
                          </div>
                        </UserProfileProvider>
                      </OnboardingProvider>
                    </SmsProvider>
                  </PhonesProvider>
                </EmailProvider>
              </IdentityProvider>
            </SyncStateProvider>
          )}

          {!isLoggedIn && (
            <Switch>
              <Route path={"/"} exact component={Login} />
              <Route path={"/login"} component={Login} />
              <Route path={"/register"} component={SignUp} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          )}

          {/* <PickUpCallModal /> */}
        </div>
      )}
    </>
  );
}
