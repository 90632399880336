const phoneNumberUtil =
  require("google-libphonenumber").PhoneNumberUtil.getInstance();
const phoneNumberFormat = require("google-libphonenumber").PhoneNumberFormat;

export default class PhoneUtils {

  static isNumberValid(number: string, defaultCountry: string) : boolean {
    try {
      let phoneNumber = phoneNumberUtil.parse(number, defaultCountry);
      return phoneNumberUtil.isValidNumber(phoneNumber);
    } catch(err) {
      return false;
    }
  }

  static format(number: string, defaultCountry: string) : string {
    try {
      const parsed = phoneNumberUtil.parse(number, defaultCountry);
      return phoneNumberUtil.format(parsed, phoneNumberFormat.E164);
    } catch(err) {
      console.error('Could not format phone number {}', number, err);
      return undefined;
    }
  }

}
