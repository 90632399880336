import classNames from "classnames";
import React from "react";
import iconBack from "../../assets/images/icon-back.svg";
import styles from "./mailToolbar.module.scss";

const btnMailStyle = classNames(
  "flex flex-row flex-center items-center gap-3 px-6 py-3 rounded-[12px] border-[1px] border-neutral-5",
  "text-[14px] leading-5 font-semibold text-neutral-10"
);

export default function MailToolbar(props) {
  return (
    <div className={styles.mailToolbar}>
      <button className={btnMailStyle} onClick={props.backToMailboxClick}>
        <img src={iconBack} alt="" />
        <span className="hidden md:block">Back to Inbox</span>
      </button>
    </div>
  );
}
