import React from "react";

export function SafeIcon({
  color = "#2A1B48",
  size = 24,
}: {
  color?: string;
  size?: number;
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.672 2.25938C12.5982 2.19219 12.5163 2.13789 12.4295 2.09667C12.2935 2.03194 12.1466 1.99995 12 2C11.8534 1.99995 11.7064 2.03194 11.5705 2.09667C11.4837 2.13789 11.4018 2.19219 11.3279 2.25938C9.188 4.1483 6.39741 5.13155 3.5457 5.00106C3.08552 4.98 2.6705 5.27602 2.54058 5.71798C1.54776 9.0953 1.96704 12.7309 3.70256 15.7936C5.43807 18.8562 8.34144 21.0842 11.7489 21.968C11.9136 22.0107 12.0864 22.0107 12.2511 21.968C15.6585 21.0842 18.5619 18.8562 20.2974 15.7936C22.0329 12.7309 22.4522 9.0953 21.4594 5.71798C21.3295 5.27602 20.9144 4.98 20.4543 5.00106C17.6026 5.13155 14.812 4.1483 12.672 2.25938Z"
        fill="#32B852"
      />
      <path
        d="M9 12L11 14L15 10"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3C14.3358 5.06658 17.3845 6.14257 20.5 6C21.4164 9.11753 21.0294 12.4734 19.4274 15.3005C17.8254 18.1276 15.1453 20.1842 12 21C8.85463 20.1842 6.17459 18.1276 4.57258 15.3005C2.97056 12.4734 2.58354 9.11753 3.49998 6C6.61551 6.14257 9.66415 5.06658 12 3"
        stroke="#32B852"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
