import { IPhone, ISentSms, ISms } from "@no.id/web-common";
import { isPossiblePhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import _ from "lodash";
import moment from "moment";
import { MessageGroup, SmsThread } from "../components/smsList/types";

const MAX_TIME_DIFF_IN_THREAD_IN_SEC = 60;

function getDestinationNumber(sms: ISentSms | ISms): string {
  return "from" in sms ? sms.from : sms.to;
}

function isCorrentNumber(sms: ISentSms | ISms): boolean {
  return isPossiblePhoneNumber("from" in sms ? sms.from : sms.to);
}

export const belongsToGroup = (
  message: ISms | ISentSms,
  group: MessageGroup
): boolean => {
  if (
    ("from" in message && "from" in group[0]) ||
    ("to" in message && "to" in group[0])
  ) {
    const lastMessage = group[group.length - 1];
    return (
      moment(message.date).diff(lastMessage.date, "second") <
      MAX_TIME_DIFF_IN_THREAD_IN_SEC
    );
  }
  return false;
};

export const groupSmsIntoThreads = (
  all: (ISentSms | ISms)[],
  phone: IPhone
) => {
  console.log("All messages: " + JSON.stringify(all, null, 3));

  const phoneCountry = parsePhoneNumber(phone.number).country;

  const threads = _.chain(all)
    .filter(isCorrentNumber)
    .sortBy("date")
    .groupBy(getDestinationNumber)
    .map((messages, destNumber) => {
      const groups: MessageGroup[] = [];
      let group = [];

      console.log("Creating group for: " + destNumber);

      let alreadyRead = true;

      for (let message of messages) {
        if ((message as ISms).alreadyRead === false) {
          alreadyRead = false;
        }

        if (group.length === 0) {
          group = [message];
          continue;
        }

        if (belongsToGroup(message, group)) {
          group.push(message);
        } else {
          groups.push(group);
          group = [message];
        }
      }

      if (group.length > 0) {
        groups.push(group);
      }

      return {
        id: `${phone.number}-${destNumber}`,
        messageGroups: groups,
        destNumber: destNumber,
        destNumberCountry: parsePhoneNumber(destNumber).country,
        srcNumber: phone.number,
        srcNumberCountry: phoneCountry,
        alreadyRead,
      } as SmsThread;
    })
    .value();

  console.log("Threads: " + JSON.stringify(threads, null, 3));

  return threads;
};
