import React, { useCallback } from "react";

const activeColor = "#27A5A5";
const inactiveColor = "#EAEBEB";

export default function PaswordProgressBar(props) {

    const color = useCallback((index) => {
       return index <= props.score ? activeColor : inactiveColor;
    }, [props.score]);

    return (
        <svg width="100%" height="4" viewBox="0 0 216 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2C0 0.895431 0.895431 0 2 0H37.3333V4H2C0.895427 4 0 3.10457 0 2Z" fill={color(0)}/>
            <rect x="43.3335" width="37.3333" height="4" fill={color(1)}/>
            <rect x="86.6665" width="37.3333" height="4" fill={color(2)}/>
            <rect x="130" width="37.3333" height="4" fill={color(3)}/> 
            <path d="M173.667 0H214C215.104 0 216 0.895431 216 2C216 3.10457 215.104 4 214 4H173.667V0Z" fill={color(4)}/>
        </svg>
    )
}