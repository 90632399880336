import classNames from 'classnames';
import * as React from "react";
import { PulseLoader } from 'react-spinners';

export interface CustomButtonProps extends React.HTMLProps<HTMLButtonElement> {
  width?: 'auto' | 'full' | 'fit';
  type?: 'compact' | 'normal' | undefined;
  loading?: boolean;
  disabled?: boolean;
  title: string;
  icon?: any;
  iconDisabled?: any;
  className?: string;
  onClick?: () => void;
}

export default function CustomButton(props: CustomButtonProps) {
  const {
    className,
    type,
    children,
    loading,
    disabled = false,
    title,
    icon,
    iconDisabled,
    width = 'auto',
    ...buttonProps
  } = props;

  const style = classNames(
    'font-semibold flex justify-center items-center rounded-[8px] tracking-tight px-6', 
    { 'w-full': width === 'full' },
    { 'w-fit' : width === 'fit' },
    className,
    {'bg-nile_blue text-white' : !className},
    'disabled:bg-neutral-5 disabled:text-neutral-60 transition disabled:text-[13px]',
    "group", 
    { 'py-2': type === 'compact' },
    { 'min-h-[48px]': type !== 'compact' },
  );

  return (
    <button {...buttonProps} className={style} disabled={disabled} onClick={props.onClick}>
      {loading ? (
        <PulseLoader size={10} color={"white"} loading={true} />
      ) : (
        <div className={classNames(
            'flex flex-row items-center',
            {"gap-3" : !!props.title}
          )}>
          {icon && !disabled && <img src={icon} className="h-6" />}
          {iconDisabled && disabled && <img src={iconDisabled} className="h-6" />}
          <div>{props.title}</div>
        </div>
      )}
    </button>
  );
}
