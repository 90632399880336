import { IPhone } from "@no.id/web-common";
import _ from "lodash";
import React from "react";
import { usePhones } from "../../providers/PhoneNumbersProvider";
import { useSmsMessages } from "../../providers/SmsProvider";
import PhoneNumberCard from "../phoneNumberCard/phoneNumberCard";
import "./PhoneList.scss";

export interface PhoneListProps {
  onSmsButtonClick: (phone: IPhone) => void;
  onPhoneCallClick: (phone: IPhone) => void;
  onPhoneDeleteClick: (phone: IPhone) => void;
  onPhoneRenewClick: (phone: IPhone) => void;
}

export default function PhoneList({
  onSmsButtonClick,
  onPhoneCallClick,
  onPhoneDeleteClick,
  onPhoneRenewClick,
}: PhoneListProps) {
  const { phones, isLoaded } = usePhones();
  const { unreadSmsCounterPerPhoneMap } = useSmsMessages();

  return (
    <div className="pt-0 pb-6 px-4 lg:p-6 mt-2 z-10 absolute md:z-0 w-full md:relative">
      {isLoaded && _.size(phones) == 0 && (
        <div className="phone-list-empty-label">You Have No Phone Numbers</div>
      )}

      {isLoaded && _.size(phones) > 0 && (
        <div className="flex flex-row gap-6 flex-wrap">
          {_.map(phones, (phone) => (
            <PhoneNumberCard
              key={phone.id}
              phone={phone}
              onSmsButtonClick={() => onSmsButtonClick(phone)}
              onCallButtonClick={() => onPhoneCallClick(phone)}
              onDeleteButtonClick={() => onPhoneDeleteClick(phone)}
              onRenewButtonClick={() => onPhoneRenewClick(phone)}
              unreadSmsCounter={unreadSmsCounterPerPhoneMap[phone.id]}
            />
          ))}
        </div>
      )}
    </div>
  );
}
