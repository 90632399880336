import { IPhone } from "@no.id/web-common";
import { Call } from "@twilio/voice-sdk";
import { createContext } from "react";

export interface PhonesContextProps {
  phones: IPhone[];
  isLoaded: boolean;
  activeOutgoingCall?: Call;
  activeIncomingCall?: Call;
}

export interface PhonesActionsContextProps {
  getNewPhone: (country: string, callback: (newPhone: IPhone) => void) => void;
  computeCallCost: (phone: IPhone, destPhoneNumber: string) => Promise<number>;
  initiateOutgoingPhoneCall: (
    phone: IPhone,
    destPhoneNumber: string
  ) => Promise<void>;
  hangupActivePhoneCall: () => void;
  deactivatePhone: (phone: IPhone) => Promise<boolean>;
  renewPhone: (phone: IPhone) => Promise<boolean>;
}

const defaultPhonesContext: PhonesContextProps = {
  phones: [],
  isLoaded: false,
};

const defaultPhonesActionsContext: PhonesActionsContextProps = {
  getNewPhone: () => {},
  computeCallCost: (phone: IPhone, destPhoneNumber: string) =>
    Promise.resolve(Number.MAX_SAFE_INTEGER),
  initiateOutgoingPhoneCall: (phone: IPhone, destPhoneNumber: string) => {
    throw new Error("Not implemented");
  },
  hangupActivePhoneCall: () => {},
  deactivatePhone: (phone: IPhone) => {
    throw new Error("Not implemented");
  },
  renewPhone: (phone: IPhone) => {
    throw new Error("Not implemented");
  },
};

export const PhonesContext =
  createContext<PhonesContextProps>(defaultPhonesContext);
export const PhonesActionsContext = createContext<PhonesActionsContextProps>(
  defaultPhonesActionsContext
);
