import { IIdentity } from "@no.id/web-common";
import { createContext } from "react";

export interface IdentitiesContextProps {
  identities: IIdentity[];
  isLoaded: boolean;
  compromisedTotal: number;
}

export interface IdentitiesActionsContextProps {
  generateNewIdentityFor: (
    domain: string,
    note: string,
    callback: (status: boolean) => void
  ) => void;
  updateIdentityPassword: (
    mailId: string,
    identityId: string,
    callback: (status: boolean) => void
  ) => void;
  generateNewIdentity: (domain: string, note: string) => Promise<string>;
}

const defaultIdentitiesContext: IdentitiesContextProps = {
  identities: [],
  isLoaded: false,
  compromisedTotal: 0,
};

const defaultIdentitiesActionsContext: IdentitiesActionsContextProps = {
  generateNewIdentityFor: (
    domain: string,
    note: string,
    callback: (status: boolean) => void
  ) => {
    throw new Error("Not implemeneted");
  },
  updateIdentityPassword: (
    mailId: string,
    identityId: string,
    callback: (status: boolean) => void
  ) => {
    throw new Error("Not implemented");
  },
  generateNewIdentity: (domain: string, note: string) => {
    throw new Error("Not implemented");
  },
};

export const IdentitiesContext = createContext<IdentitiesContextProps>(
  defaultIdentitiesContext
);

export const IdentitiesActionsContext =
  createContext<IdentitiesActionsContextProps>(defaultIdentitiesActionsContext);
