import React, {useState} from "react";
import {Button, Icon, Modal} from "semantic-ui-react";
import "./UpgradeModal.scss"

export default function UpgradeModal(props) {

    const [isOpen, setIsOpen] = useState(props.open);

    const close = () => {
        setIsOpen(false);
    }

    const open = () => {
        setIsOpen(true);
    }

    return (
        <Modal size={'tiny'}
               dimmer={'blurring'}
               open={isOpen}
               closeOnEscape={false}
               closeOnDimmerClick={false}
               onClose={close}
               trigger={
                   <Button className='btn-upgrade-id' onClick={open}>
                       Upgrade account
                   </Button>
               }
        >

            <Modal.Header>It's Alpha version</Modal.Header>

            <Modal.Content className='upgrade-modal-content'>
                <div>The number of identities is limited to 30.</div>
                <div>In final release, you could have unlimited number of Secure ID.<strong>For free</strong>.</div>
                {/*<div>On request, you could also increase storage for your emails or purchase premium features around virtual phone numbers and payment cards.</div>*/}
                <div>Stay tuned!</div>
            </Modal.Content>

            <Modal.Actions>
                <Button className='btn-i-understand' onClick={() => close(false)}>
                    <Icon name='check' /> I understand
                </Button>
            </Modal.Actions>
        </Modal>
    );


}