import { IIdentity } from "@no.id/web-common";
import React from "react";
import Modal from "./Modal";
import IdentitySelectView from "./identitySelectView/identitySelectView";

export interface IdentityChooseModalProps {
  show: boolean;
  domainIdentities: IIdentity[];
  onChooseIdentity: (id: string) => void;
}

export default function IdentityChooseModal({
  show,
  domainIdentities,
  onChooseIdentity,
}: IdentityChooseModalProps) {
  return (
    <Modal
      isOpen={show}
      nestedModal={true}
      backdropColor="bg-white/75"
      style="z-20"
    >
      <div
        className="bg-white pt-6 px-5 pb-4 rounded-[12px] border-[1px] flex flex-col gap-2 brand-neutral-100
       text-neutral-100 w-[320px] shadow-lg shadow-[#10182810]"
      >
        <div className="text-[18px] font-semibold font-sans leading-6 text-left">
          Select Your Identity
        </div>
        <div className="mb-2 font-sans text-sm heading-5 font-normal text-left">
          You have multiple identities associated with this recipient domain.
          Choose the one you'd like to use:
        </div>
        <div className="border-[1px] border-neutral-0 rounded-[12px] p-2">
          {domainIdentities.map((identity, idx) => (
            <div
              key={identity.id}
              onClick={() => onChooseIdentity(identity.id)}
            >
              <IdentitySelectView identity={identity} />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}
