import { config } from "@no.id/web-common";
import classNames from "classnames";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { isDesktop, isTablet } from "react-device-detect";
import { Icon } from "semantic-ui-react";
import noidWorkerApi from "../../NoidWorkerAPI";
import {
  useIdentities,
  useIdentitiesUpdate,
} from "../../providers/IdentityProvider";
import UrlUtils from "../../utils/UrlUtils";
import InfoView from "../InfoView";
import PrimaryInput from "../pimaryInput/primaryInput";
import PrimaryButton from "../primaryButton";
import UpgradeModal from "../upgradeModal/UpgradeModal";
import "./NewIdentityView.scss";

const validator = require("validator");

export default function NewIdentityView(props) {
  const WEB_URL = config.WEB_URL;

  const { identities, isLoaded } = useIdentities();
  const { generateNewIdentityFor } = useIdentitiesUpdate();

  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [inputs, setInputs] = useState({
    domain: "",
    note: "",
    invalidDomain: false,
  });
  const [totalLimitReached, setTotalLimitReached] = useState(async () => {
    const userProfile = await noidWorkerApi.AuthService.getUserProfile();
    return identities && identities.length >= 20; //userProfile.totalIdentityLimit;
  });
  const [domainLimitReached, setDomainLimitReached] = useState(false);
  const [showForm, setShowForm] = useState(isDesktop || isTablet);
  const [inProgress, setInProgress] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const initializeLimit = async () => {
      const userProfile = await noidWorkerApi.AuthService.getUserProfile();
      setTotalLimitReached(identities && identities.length >= 20); //userProfile.totalIdentityLimit);
    };
    initializeLimit();
  }, [identities, isLoaded]);

  const onValueChange = ({ target: { name, value } }) => {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [name]: value,
      };
    });
  };

  const onCreateIdentityClick = (form) => {
    if (!showForm) {
      setShowForm(true);
      return;
    }

    if (!form.domain || !validator.isURL(form.domain)) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          invalidDomain: true,
        };
      });
      return;
    } else {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          invalidDomain: false,
        };
      });
    }

    const rawDomain = UrlUtils.getRawDomain(form.domain);
    if (_.filter(identities, { domain: rawDomain }).length == 2) {
      setDomainLimitReached(true);
      console.log("domain limit reached");
      return;
    }

    setInProgress(true);
    setDomainLimitReached(false);
    setMessage(null);
    setError(null);

    generateNewIdentityFor(rawDomain, form.note, ({ status, error }) => {
      if (status) {
        setInputs({});
        setInProgress(false);
        setMessage("Your new identity is ready");
        setError(null);

        setTimeout(() => {
          setMessage(null);
        }, 2500);

        return;
      }

      setInProgress(false);
      setMessage(null);

      if (error == 2) {
        setDomainLimitReached(true);
        return;
      }

      setError("Error. Please try again.");
      setTimeout(() => {
        setError(null);
      }, 2500);
    });
  };

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
    setTimeout(() => {
      setIsPopupOpen(false);
    }, 1500);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  return (
    <div
      className={classNames(
        "flex lg:py-6 lg:items-center lg:min-h-[196px] md:w-fit md:max-w-[70%] lg:max-w-none",
        {
          "rounded-[16px] border-[1px] border-neutral-5 p-4 bg-white md:bg-transparent md:border-0":
            showForm,
        }
      )}
    >
      <div className={`flex flex-col flex-wrap gap-4 w-full`}>
        <div className="flex flex-col gap-2 w-fit">
          <div className="flex flex-row flex-nowrap justify-between md:justify-start">
            <div className="w-[60%] md:w-full text-neutral-100 text-[16px] md:text-[20px] font-semibold leading-6 lg:leading-8">
              Get unique Identity <br className="mobile-break" /> for each
              service you use
            </div>

            <PrimaryButton
              className={classNames("text-[12px] scale-[0.85] md:hidden", {
                "bg-transparent border-0": showForm,
              })}
              disabled={inProgress}
              onClick={() => setShowForm((v) => !v)}
              width="fit"
              icon={require(!showForm
                ? "../../assets/images/icon-add.svg"
                : "../../assets/images/icon-close.svg")}
            />
          </div>

          <div
            className={classNames(
              `md:block text-neutral-80 text-[14px] font-normal leading-5`,
              { hidden: !showForm },
              { "md:w-[80%] lg:w-full": showForm }
            )}
          >
            Big Tech and criminals will not learn all about you across different
            platforms
          </div>
        </div>

        {totalLimitReached && (
          <div className={`new-identity-limit-info-wrapper`}>
            <div className="new-identity-limit-info-text">
              <Icon name="warning sign" />
              You reached total Identities limit
            </div>

            <UpgradeModal />

            {/*<Button className='btn-upgrade-id' onClick={() => {upgradeModalRef.current.open()}}>*/}
            {/*    Upgrade account*/}
            {/*</Button>*/}

            {/*<Popup*/}
            {/*    trigger={*/}
            {/*        <Button className='btn-upgrade-id'>*/}
            {/*            Upgrade account*/}
            {/*        </Button>*/}
            {/*    }*/}
            {/*    content={`This option will be available soon`}*/}
            {/*    on='click'*/}
            {/*    open={isPopupOpen}*/}
            {/*    onClose={handlePopupClose}*/}
            {/*    onOpen={handlePopupOpen}*/}
            {/*    position='bottom center'*/}
            {/*/>*/}
          </div>
        )}

        {!totalLimitReached && !error && !message && (
          <div className="flex flex-row flex-wrap gap-4 w-full">
            {showForm && (
              <>
                <PrimaryInput
                  placeholder="Website URL"
                  disabled={inProgress}
                  icon={require("../../assets/images/icon-link.svg")}
                  name="domain"
                  error={inputs.invalidDomain}
                  value={inputs.domain}
                  onChange={onValueChange}
                  className="w-full md:w-fit md:flex-1 lg:flex-none"
                />

                <PrimaryInput
                  placeholder="Short note e.g shopping"
                  disabled={inProgress}
                  icon={require("../../assets/images/icon-note.svg")}
                  name="note"
                  value={inputs.note}
                  onChange={onValueChange}
                  className="w-full md:w-fit md:flex-1 lg:flex-none"
                />

                <PrimaryButton
                  className="text-[12px] min-w-[176px] w-full md:w-fit md:flex-1 lg:flex-none"
                  disabled={inProgress}
                  onClick={() => onCreateIdentityClick(inputs)}
                  title="Create Identity"
                  width="fit"
                  icon={require("../../assets/images/icon-add.svg")}
                />
              </>
            )}
          </div>
        )}

        {error && (
          <InfoView
            message={error}
            actionTitle="Upgrade account now"
            type="error"
          />
        )}

        {message && <InfoView message={message} type="success" />}

        {domainLimitReached && <InfoView message={message} type="info" />}
      </div>

      {/* {message && (
        <div className="id-creation-success">
          <Icon name="checkmark" />
          <span>{message}</span>
        </div>
      )} */}

      {/* {domainLimitReached && (
        <div className="new-identity-domain-limit-info ">
          <Icon name="warning sign" />
          You can have
          <strong style={{ fontWeight: "700" }}> at most two IDs </strong>
          for one domain
        </div>
      )} */}
    </div>
  );
}
