import React from "react";

export function AttachmentIcon({
  color = "#153F4C",
  size = 21,
}: {
  color?: string;
  size?: number;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4993 6.70472L7.0826 12.1214C6.39224 12.8117 6.39224 13.931 7.0826 14.6214C7.77295 15.3117 8.89224 15.3117 9.5826 14.6214L14.9993 9.20472C16.38 7.82401 16.38 5.58544 14.9993 4.20472C13.6186 2.82401 11.38 2.82401 9.99926 4.20472L4.5826 9.62139C2.51153 11.6925 2.51153 15.0503 4.5826 17.1214C6.65367 19.1925 10.0115 19.1925 12.0826 17.1214L17.4993 11.7047"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
