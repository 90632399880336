import { IIdentity, LiveQueryType } from "@no.id/web-common";
import { proxy } from "comlink";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import noidWorkerApi from "../NoidWorkerAPI";
import {
  IdentitiesActionsContext,
  IdentitiesContext,
} from "../context/identitiesContext";
import useLiveQuery from "../hooks/useLiveQuery";
import { useSyncState } from "./SyncStateProvider";

export function useIdentities() {
  return useContext(IdentitiesContext);
}

export function useIdentitiesUpdate() {
  return useContext(IdentitiesActionsContext);
}

export function IdentityProvider({ children }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const identities = useLiveQuery(LiveQueryType.ALL_IDENTITIES) as IIdentity[];
  const { isSynced } = useSyncState();

  const compromisedTotal = useMemo(() => {
    return identities
      ? identities.filter((i) => i.compromisedDomains?.length > 0).length
      : 0;
  }, [identities]);

  const generateNewIdentityFor = useCallback(
    (domain: string, note: string, callback: (status: boolean) => void) => {
      noidWorkerApi.IdentityService.generateNewIdentityForWithCallback(
        domain,
        note,
        proxy((status: boolean) => {
          callback(status);
        })
      );
    },
    []
  );

  const generateNewIdentity = async (
    domain: string,
    note: string
  ): Promise<string> => {
    const response = await noidWorkerApi.IdentityService.generateNewIdentity(
      domain,
      note
    );
    if (!response.success) {
      if (response.error === -10) {
        throw "You have already exhausted your identity limit.";
      } else {
        throw "Problem occurred when creating the new identity, try again.";
      }
    }
    return response.identity.id as string;
  };

  const updateIdentityPassword = useCallback(
    (
      identityId: string,
      newPassword: string,
      callback: (status: boolean) => void
    ) => {
      noidWorkerApi.IdentityService.updateIdentityPassword(
        identityId,
        newPassword,
        proxy((status: boolean) => {
          callback(status);
        })
      );
    },
    []
  );

  useEffect(() => {
    if (isSynced && !isLoaded) {
      setIsLoaded(true);
    }
  }, [isSynced]);

  const providerValue = useMemo(
    () => ({
      identities,
      isLoaded,
      compromisedTotal,
    }),
    [identities, isLoaded, compromisedTotal]
  );

  const identitiesActions = useMemo(
    () => ({
      generateNewIdentityFor,
      updateIdentityPassword,
      generateNewIdentity,
    }),
    [generateNewIdentityFor, updateIdentityPassword, generateNewIdentity]
  );

  return (
    <IdentitiesContext.Provider value={providerValue}>
      <IdentitiesActionsContext.Provider value={identitiesActions}>
        {children}
      </IdentitiesActionsContext.Provider>
    </IdentitiesContext.Provider>
  );
}
