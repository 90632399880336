import { trackGoal } from "fathom-client";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { Button, Image } from "semantic-ui-react";
import AnalyticsEvents from "../../events/analyticsEvents";
import { useAuthActions } from "../../providers/AuthProvider";
import PrimaryInput from "../pimaryInput/primaryInput";
import "./Login.scss";

export default function Login(props) {
  const { login } = useAuthActions();

  let location = useLocation();
  const history = useHistory();

  const [inProgress, setInProgress] = useState(() => false);
  const [loginFailed, setLoginFailed] = useState(() => false);
  const [invalidEmail, setInvalidEmail] = useState(() => false);
  const [noidPassword, setNoidPassword] = useState("");
  const [noidUsername, setNoidUsername] = useState("");

  const usernameRef = React.createRef();
  const passwordRef = React.createRef();

  const validateUsername = (username) => {
    if (!username || username.length < 6 || username.length > 48) {
      return false;
    }
    const re = /^[0-9a-zA-Z]+$/g;
    return re.test(username);
  };

  const handleLoginClick = async (e) => {
    e.preventDefault();

    if (!validateUsername(noidUsername)) {
      setInvalidEmail(true);
      // setInProgress(false);
      return;
    }

    setInProgress(true);
    setInvalidEmail(false);
    setLoginFailed(false);
    trackGoal(AnalyticsEvents.WEB_LOGIN_CLICKED, 0);
    await login(noidUsername, noidPassword, (loginResult) => {
      if (!loginResult) {
        setLoginFailed(true);
        setInProgress(false);
        trackGoal(AnalyticsEvents.WEB_LOGIN_FAILED, 0);
        return;
      }

      trackGoal(AnalyticsEvents.WEB_LOGIN_SUCCESS, 0);
      goToDashboard();
    });
  };

  const goToDashboard = () => {
    let goto = "/main";

    if (
      location.state &&
      location.state.from &&
      location.state.from.pathname !== "/"
    ) {
      goto = location.state.from.pathname + location.state.from.search;
    }

    history.push(goto);
  };

  const onSignUpClick = () => {
    history.push("/register");
  };

  const handleKeyUp = (e) => {
    e.which = e.which || e.keyCode;

    if (e.which === 13) {
      switch (e.target.name) {
        case "noidUsername":
          passwordRef.current.focus();
          break;
        case "noidPassword":
          handleLoginClick(e);
          break;
        default:
          break;
      }
    }
  }

  return (
    <div className="login-grid-wrapper">
      <div className="login-desc-wrapper" style={{ padding: "0px !important" }}>
        <Image className="login-logo" src="/images/logo.png" />

        {/*<Image className='login-main-image' size='large' src='/images/header-incognito.png'/>*/}

        <div className="login-head">
          <div className="login-subheader">Mask your Identity.</div>
          <div className="login-subheader2">Get peace of mind.</div>
        </div>

        {/*<UpgradeModal />*/}
      </div>

      <div className="login-form-wrapper">
        <div className="login-form-header">
          Welcome{" "}
          <div
            style={{ display: "inline-block" }}
            className="glitch"
            data-text="John Doe"
          >
            John Doe
          </div>
        </div>

        <div className="login-form w-full">
          <div>
            <div className="flex flex-col gap-6">
              <PrimaryInput
                className="py-5"
                textClassnames="text-[16px]"
                width="full"
                icon={require("../../assets/images/icon_user.svg")}
                placeholder="Username"
                name="noidUsername"
                value={noidUsername}
                error={invalidEmail}
                onChange={(e) => setNoidUsername(e.target.value)}
                disabled={inProgress}
                inputRef={usernameRef}
                onKeyUp={handleKeyUp}
              />

              <PrimaryInput
                className="py-5"
                textClassnames="text-[16px] font-sans"
                width="full"
                icon={require("../../assets/images/icon_lock.svg")}
                name="noidPassword"
                value={noidPassword}
                placeholder="Password"
                type="password"
                onChange={(e) => setNoidPassword(e.target.value)}
                disabled={inProgress}
                inputRef={passwordRef}
                onKeyUp={handleKeyUp}
              />

              {loginFailed && (
                <div style={{ color: "red", marginTop: "16px" }}>
                  Login failed. Please try again
                </div>
              )}
            </div>
            <Button
              color="black"
              onClick={handleLoginClick}
              className="btn-login"
              // style={{color: 'white', width: '90%', margin: '0 auto', fontSize: '18px'}}
            >
              {inProgress && (
                <PulseLoader size={10} color={"white"} loading={true} />
              )}

              {!inProgress && <span>Login</span>}
            </Button>

            <div
              onClick={onSignUpClick}
              disabled={inProgress}
              className="register-label"
            >
              <span>Create Account</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
