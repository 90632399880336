import React, { Component } from "react";
import GlobalContext from "./GlobalContext";

export default class GlobalContextProvider extends Component {
  state = {
    flags: {
      hasNewSms: false,
      newSmsPhoneIds: new Set(),
    },
    loaders: {
      mail: false,
      mailProgress: 0,
    },
    mails: undefined,
    smsMessages: undefined,
    identitySearchPhrase: "",
  };

  render() {
    return (
      <GlobalContext.Provider
        value={{
          flags: this.state.flags,
          loaders: this.state.loaders,
          mails: this.state.mails,
          smsMessages: this.state.smsMessages,
          identitySearchPhrase: this.state.identitySearchPhrase,
          updateNewSmsFlag: (value) => {
            const flags = Object.assign({}, this.state.flags);
            flags.hasNewSms = value;
            this.setState({ flags });
          },
          updateNewSmsPhoneIds: (phoneIds) => {
            const flags = Object.assign({}, this.state.flags);
            for (let phoneId of phoneIds) {
              flags.newSmsPhoneIds.add(phoneId);
            }
            flags.hasNewSms = flags.newSmsPhoneIds.size > 0;
            this.setState({ flags });
          },
          removeNewSmsPhoneId: (value) => {
            const flags = Object.assign({}, this.state.flags);
            if (!flags.newSmsPhoneIds.has(value)) {
              return;
            }
            flags.newSmsPhoneIds.delete(value);
            flags.hasNewSms = flags.newSmsPhoneIds.size > 0;
            this.setState({ flags });
          },
          updateMailLoader: (mail, mailProgress) => {
            const loaders = Object.assign({}, this.state.loaders);
            loaders.mail = mail;
            loaders.mailProgress = mailProgress;
            this.setState({ loaders });
          },
          updateMails: (value) => {
            const mails = Object.assign({}, value);
            this.setState({ mails });
          },
          updateSmsMessages: (value) => {
            const smsMessages = Object.assign({}, value);
            this.setState({ smsMessages });
          },
          countUnreadMessages: () => {
            return this.state.mails
              ? Object.values(this.state.mails)
                  .flat()
                  .filter((mail) => !mail.alreadyRead).length
              : 0;
          },
          updateIdentitySearchPhrase: (identitySearchPhrase) => {
            this.setState({ identitySearchPhrase });
          },
        }}
      >
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}
