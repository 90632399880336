import React from "react";

export function CloseIcon({
  color = "#153F4C",
  size = 13,
}: {
  color?: string;
  size?: number;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.41425 6.87134L9.70725 4.57834C10.0983 4.18734 10.0983 3.55534 9.70725 3.16434C9.31625 2.77334 8.68425 2.77334 8.29325 3.16434L6.00025 5.45734L3.70725 3.16434C3.31625 2.77334 2.68425 2.77334 2.29325 3.16434C1.90225 3.55534 1.90225 4.18734 2.29325 4.57834L4.58625 6.87134L2.29325 9.16434C1.90225 9.55534 1.90225 10.1873 2.29325 10.5783C2.48825 10.7733 2.74425 10.8713 3.00025 10.8713C3.25625 10.8713 3.51225 10.7733 3.70725 10.5783L6.00025 8.28534L8.29325 10.5783C8.48825 10.7733 8.74425 10.8713 9.00025 10.8713C9.25625 10.8713 9.51225 10.7733 9.70725 10.5783C10.0983 10.1873 10.0983 9.55534 9.70725 9.16434L7.41425 6.87134Z"
        fill={color}
      />
    </svg>
  );
}
