import classNames from "classnames";
import React from "react";
import Modal from "./Modal";
import { MaskIcon } from "./common/icons/MaskIcon";

const btnConfirmClass = classNames(
  "flex flex-row flex-center items-center justify-center px-4 py-2 rounded-[8px] border-[1px] border-neutral-5",
  "text-[14px] leading-5 font-semibold text-white bg-neutral-100"
);

const btnCancelClass = classNames(
  "flex flex-center items-center justify-center px-4 py-2 rounded-[8px] border-[1px] border-neutral-40",
  "text-xs leading-5 font-semibold text-black bg-white text-neutral-100 cursor-pointer"
);

export interface NewIdentityRequiredModalProps {
  show: boolean;
  onConfirm: () => void;
  setShow: (value: boolean) => void;
}

export default function NewIdentityRequiredModal({
  show,
  onConfirm,
  setShow,
}: NewIdentityRequiredModalProps) {
  return (
    <Modal
      isOpen={show}
      nestedModal={true}
      backdropColor="bg-white/75"
      style="z-20"
    >
      <div
        className="bg-white pt-6 px-5 pb-4 rounded-[12px] border-[1px] flex flex-col gap-2 brand-neutral-100
       text-neutral-100 w-[320px] shadow-lg shadow-[#10182810]"
      >
        <div className="text-base font-semibold font-sans leading-6 text-left">
          New Identity Required
        </div>
        <div className="mb-2 font-sans text-sm heading-5 font-normal text-left">
          Before sending the email to this recipient, you need to create a new
          identity dedicated to this domain.
        </div>
        <button type="button" className={btnConfirmClass} onClick={onConfirm}>
          <div className="flex flex-row gap-1 justify-center items-center">
            <MaskIcon color="#ffffff" />
            <div>Create Identity</div>
          </div>
        </button>
        <button
          type="button"
          className={btnCancelClass}
          onClick={() => setShow(false)}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}
