import classNames from 'classnames';
import * as React from "react";

export interface InfoViewProps {
  type?: 'info' | 'success' | 'error'
  message: string;
  size?: 'fit' | 'full';
  actionTitle?: string;
  onClick?: () => void;
}

export default function InfoView(props: InfoViewProps) {
  const {
    type,
    message, 
    actionTitle,
    size,
    onClick
  } = props;

  const style = classNames(
    "flex flex-row  py-3 px-4 gap-[48px] items-center justify-between rounded-[12px] font-sans font-medium w-full",
    {"bg-error-5 text-error-50" : type == 'error'},
    {"bg-warning-5 text-warning-100" : type == 'info'},
    {"bg-success-0 text-success-50" : type == 'success'},
    {"leading-4 text-[12px]" : size === "fit"},
    {"min-w-[356px] leading-5 text-[14px]" : size !== "fit"},

  );

  const icon = React.useMemo(() => {
    switch(type) {
      case 'error' : return require("../assets/images/icon-compromised.svg");
      case 'success' : return require("../assets/images/icon-check.svg");
      case 'info' : return require("../assets/images/icon-info-error.svg");
    }
  }, [type]);

  return (
    <div className={style}>
      <div className="flex flex-row items-center gap-2">
        <img src={icon} className="h-6" />
        <div>{message}</div>
      </div>
      {actionTitle && <div onClick={onClick} className="font-semibold cursor-pointer">{actionTitle}</div>}
    </div>
  );
}
