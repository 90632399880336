import classNames from "classnames";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PulseLoader } from "react-spinners";
import SmsThreadView from "../smsThreadView/smsThreadView";
import "./SmsList.scss";
import { SmsThread } from "./types";

import { isMobile } from "react-device-detect";
import { mockSmsThreads } from "../../../../mockData";
import { useSmsMessages } from "../../../../providers/SmsProvider";
import { formatDate } from "../../../../utils/DateUtils";

import { IPhone, ISentSms, ISms } from "@no.id/web-common";
import { AsYouType } from "libphonenumber-js";
import noidWorkerApi from "../../../../NoidWorkerAPI";
import PhoneUtils from "../../../../utils/PhoneUtils";
import { groupSmsIntoThreads } from "../../utils/SmsUtils";
import SmsToolbar from "../smsToolbar/smsToolbar";

export interface SmsListProps {
  phone: IPhone;
  onBackClick: () => void;
}

interface ThreadSummaryCard {
  thread: SmsThread;
  isSelected: boolean;
  onThreadSelected: (thread: SmsThread) => void;
  isNew: boolean;
}

const newThread: SmsThread = {
  alreadyRead: true,
  destNumber: "",
  messageGroups: [],
  srcNumber: "",
  id: "-1",
};

const ThreadSummaryCard = ({
  thread,
  onThreadSelected,
  isSelected,
  isNew,
}: ThreadSummaryCard) => {
  const lastGroup = thread.messageGroups[thread.messageGroups.length - 1];
  const lastMessage = lastGroup ? lastGroup[lastGroup.length - 1] : undefined;

  return (
    <div
      className={classNames(
        "flex flex-row gap-4 rounded-[12px] border-[1px] border-neutral-5 center-items justify-center p-6 cursor-pointer w-[360px]",
        { "bg-neutral-0 shadow-sm": isSelected },
        {
          "bg-[#FCFCFC] shadow-md font-semibold":
            !thread.alreadyRead && !isSelected,
        },
        { "bg-white shadow-sm": !isSelected && thread.alreadyRead }
      )}
      onClick={() => onThreadSelected(thread)}
    >
      <div className="m-auto rounded-[8px] border-[1px] h-10 w-10 flex items-center justify-center bg-white border-neutral-0">
        <div
          className={`h-6 fi fi-${thread.destNumberCountry?.toLowerCase()}`}
        ></div>
      </div>
      <div className="flex-1 flex flex-col gap-2">
        <div
          className={classNames(
            "text-[14px] heading-5 text-neutral-80",
            { "font-medium": !isSelected },
            { "font-bold": isSelected }
          )}
        >
          <span className="text-[#BEC4C5]">To:</span>{" "}
          <span className={classNames({ "font-bold": !thread.alreadyRead })}>
            {isNew ? "Enter number" : new AsYouType().input(thread.destNumber)}
          </span>
        </div>
        <div
          className={classNames(
            "line-clamp-2 text-neutral-100 text-[12px] heading-4 break-all",
            {
              "font-bold": isSelected,
            }
          )}
        >
          {lastMessage?.body}
        </div>
      </div>
      <div
        className={classNames(
          "m-auto text-[12px] heading-4 text-neutral-100",
          { "font-normal": thread.alreadyRead },
          { "font-bold": !thread.alreadyRead }
        )}
      >
        {isNew ? "now" : formatDate(lastMessage?.date)}
      </div>
    </div>
  );
};

const threads: SmsThread[] = mockSmsThreads;

export default function SmsList({ phone, onBackClick }: SmsListProps) {
  const {
    smsSummaries: allSmsSummaries,
    smsSentMessages: allSmsSentMessages,
    phonesWithFailures,
    phonesInitialized,
  } = useSmsMessages();

  const [error, setError] = useState(false);
  const [newConversation, setNewConversation] = useState(false);
  const [smsSummaries, setSmsSummaries] = useState<ISms[]>([]);
  const [smsSentMessages, setSmsSentMessages] = useState<ISentSms[]>([]);

  const [threads, setThreads] = useState<SmsThread[]>();
  const [selectedThread, setSelectedThread] = useState<SmsThread>();

  const isSmsListLoaded = useMemo(() => {
    return true; //phonesInitialized.includes(props.phoneId);
  }, []);

  const onThreadSelected = useCallback(
    (thread: SmsThread) => {
      setSelectedThread(thread);
    },
    [setSelectedThread]
  );

  const onGoBackClick = () => {
    if (isMobile && selectedThread) {
      setSelectedThread(undefined);
      return;
    }
    onBackClick();
  };

  const onCreateClick = () => {
    setNewConversation(true);
    setSelectedThread(newThread);
  };

  useEffect(() => {
    setSmsSummaries(allSmsSummaries.filter((p) => p.phoneId === phone.id));
  }, [allSmsSummaries]);

  useEffect(() => {
    setSmsSentMessages(
      allSmsSentMessages.filter((p) => p.phoneId === phone.id)
    );
  }, [allSmsSentMessages]);

  useEffect(() => {
    console.log("Changes in SMSes detected. Grouping into threads...");

    const updatedTheads = groupSmsIntoThreads(
      [...smsSummaries, ...smsSentMessages],
      phone
    );
    setThreads(updatedTheads);
    if (selectedThread) {
      const updatedSelectedThread = updatedTheads.find(
        (t) => t.id === selectedThread.id
      );
      setSelectedThread(updatedSelectedThread);
    }
  }, [smsSummaries, smsSentMessages]);

  const onSmsSend = async (destNumber: string, body: string) => {
    const formatDestNumber = PhoneUtils.format(destNumber, phone.country);
    const status = noidWorkerApi.SmsService.sendSms(
      phone.id,
      formatDestNumber,
      body
    );
    if (!status) {
      alert("Problem with sending sms");
    } else if (newConversation) {
      setNewConversation(false);
      setSelectedThread(threads.find((t) => t.destNumber === formatDestNumber));
    }
  };

  return (
    <div className="p-6 h-full">
      <div className="h-full flex flex-col gap-6 pt-6">
        <SmsToolbar
          phone={phone}
          onBackClick={onGoBackClick}
          onCreateClick={onCreateClick}
        />

        {!isSmsListLoaded && (
          <PulseLoader size={10} color={"black"} loading={true} />
        )}

        {isSmsListLoaded && error && (
          <span className="sms-list-empty-info">
            Cannot load messages. Try again later.
          </span>
        )}

        {isSmsListLoaded && (
          <div className="flex flex-col h-full gap-6">
            <div className="flex flex-row h-0 grow gap-6 w-full">
              <div
                className={classNames(
                  "flex-col gap-3 overflow-x-auto no-scrollbar min-w-[360px] max-w-[360px]",
                  { "flex ": !selectedThread },
                  { "hidden sm:flex ": selectedThread }
                )}
              >
                {threads &&
                  threads.length > 0 &&
                  _.map(threads, (thread, idx) => (
                    <ThreadSummaryCard
                      key={idx}
                      thread={thread}
                      isSelected={selectedThread?.id === thread.id}
                      onThreadSelected={onThreadSelected}
                      isNew={false}
                    />
                  ))}
                {(newConversation || (threads && threads.length == 0)) && (
                  <ThreadSummaryCard
                    thread={newThread}
                    isSelected={selectedThread?.id === newThread.id}
                    onThreadSelected={onThreadSelected}
                    isNew={true}
                  />
                )}
              </div>

              <div className="h-[100%] border-[1px] w-full md:basis-full rounded-[12px] border-neutral-5">
                {selectedThread && (
                  <SmsThreadView
                    thread={selectedThread}
                    onSmsSend={onSmsSend}
                    isNew={selectedThread === newThread}
                  />
                )}
                {threads && threads.length == 0 && (
                  <SmsThreadView
                    thread={newThread}
                    onSmsSend={onSmsSend}
                    isNew={true}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
