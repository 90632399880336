import { Backend } from "@no.id/web-common";
import { Device } from "@twilio/voice-sdk";
import noidWorkerApi from "./NoidWorkerAPI";
import IncomingCallManager from "./utils/IncomingCallManager";

let twilioDevices = [];

class TwilioDevice {
  async startTwilioDevice(clientId) {
    if (!clientId) {
      return;
    }

    const sessionToken =
      await noidWorkerApi.AuthService.getPersonalisedSessionToken();

    const payload = {
      sessionToken,
      clientId,
    };

    Backend.getIncomingCallToken(payload).then((response) => {
      if (!response || !response.status || !response.token) {
        return;
      }

      let device = new Device(response.token, {
        sounds: {
          incoming: "/sounds/ringing_sound.mp3",
        },
      });
      this.setupDevice(device);
      twilioDevices.push(device);
    });
  }

  async startTwilioDevices(newPhones) {
    if (twilioDevices.length > 0) {
      return;
    }
    Object.values(newPhones)
      .map((phone) => phone.clientId)
      .filter((clientId) => clientId !== undefined)
      .forEach((clientId) => {
        this.startTwilioDevice(clientId);
      });
  }

  async destroyAllDevices() {
    twilioDevices.forEach((device) => device.destroy());
  }

  async setupDevice(device) {
    device.register();

    device.on("incoming", function (conn) {
      IncomingCallManager.setNewActiveCall(conn);

      conn.on("disconnect", (call) => {
        IncomingCallManager.disconnect(conn);
      });
    });

    device.on("cancel", function (conn) {
      IncomingCallManager.cancel(conn);
    });

    device.on("error", function (error) {
      if (error && IncomingCallManager.getActiveCall() == error.connection) {
        IncomingCallManager.reportError(
          error.connection,
          "An error occurred while incoming call"
        );
        return;
      }

      try {
        error.connection.disconnect();
      } catch (err) {
        console.error("Error when closing incorrect connection : " + err);
      }
    });
  }
}

export default new TwilioDevice();
