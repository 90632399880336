import classNames from 'classnames';
import * as React from "react";
import { PulseLoader } from 'react-spinners';
import { SecondaryButtonProps } from './type';

export default function SecondaryButton(props: SecondaryButtonProps) {
  const {
    className,
    type,
    children,
    loading,
    disabled = false,
    title,
    icon, 
    iconDisabled,
    width = 'auto',
    ...buttonProps
  } = props;

  const style = classNames(
    'flex justify-center items-center rounded-[8px] tracking-tight', 
    // 'focus-visible:ring-2 focus-visible:ring-gray-900 focus-visible:ring-inset disabled:bg-none', 
    // 'disabled:bg-gray-200 disabled:text-gray-500 transition',
    'text-interactive-2 border-silver_sand border-[1px] text-black py-2 px-3',
    "hover:brightness-[120%]",
    { 'w-full': width === 'full' },
    className,
  );

  return (
    <button {...buttonProps} className={style} disabled={disabled} onClick={props.onClick}>
      {loading ? (
        <PulseLoader size={10} color={"white"} loading={true} />
      ) : (
        <div className='flex flex-row gap-[6px] items-center'>
        {icon && !disabled && <img src={icon} className="h-4" />}
        {iconDisabled && disabled && <img src={iconDisabled} className="h-4" />}
        {props.title && <div className='font-sans text-[12px] leading-4 font-semibold'>{props.title}</div>}
      </div>
      )}
    </button>
  );
}
