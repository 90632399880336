import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

export interface ModalProps {
  isOpen: boolean;
  nestedModal?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  hasCloseBtn?: boolean;
  style?: string;
  backdropColor?: string;
}

export default function Modal({
  isOpen,
  nestedModal,
  hasCloseBtn,
  onClose,
  children,
  style,
  backdropColor,
}: ModalProps) {
  const [isModalOpen, setModalOpen] = useState(isOpen);
  const modalRef = useRef<HTMLDialogElement | null>(null);

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    setModalOpen(false);
  };

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      if (isModalOpen) {
        modalElement.showModal();
      } else {
        modalElement.close();
      }
    }
  }, [isModalOpen]);

  return (
    <>
      {nestedModal ? (
        <>
          {isOpen && (
            <div
              className={classNames(
                "absolute left-0 top-0 h-full w-full rounded-[12px]",
                style,
                `${backdropColor ? backdropColor : "bg-black/50"}`,
                ""
              )}
              style={{ marginLeft: "auto", marginRight: "auto" }}
            >
              <div className="absolute left-[50%] top-[50%] text-center -translate-y-2/4 -translate-x-2/4">
                {hasCloseBtn && (
                  <button
                    className="modal-close-btn"
                    onClick={handleCloseModal}
                  >
                    Close
                  </button>
                )}
                {children}
              </div>
            </div>
          )}
        </>
      ) : (
        <dialog
          ref={modalRef}
          className={classNames(
            style,
            `${backdropColor ? backdropColor : "bg-black/50"}`
          )}
          style={{ position: "absolute" }}
        >
          {hasCloseBtn && (
            <button className="modal-close-btn" onClick={handleCloseModal}>
              Close
            </button>
          )}
          {children}
        </dialog>
      )}
    </>
  );
}
