import { LiveQueryType } from "@no.id/web-common";
import { proxy } from "comlink";
import * as _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import noidWorkerApi from "../NoidWorkerAPI";

export default function useLiveQuery<T>(queryId: LiveQueryType) {
  const [data, setData] = useState<T>();
  const subscriptionId = useRef<string>();

  const handleResult = useCallback(
    (result: T) => {
      if (!_.isEqual(data, result)) {
        // console.log("MAIN_THREAD [useLiveQuery] NOT THE SAME Got result:", JSON.stringify(result, null, 3));
        setData(result);
      }
    },
    [data, setData]
  );

  const handleError = useCallback((error: any) => {
    console.error(
      "GOT ERROR FROM useLiveQuery: " + JSON.stringify(error, null, 3)
    );
  }, []);

  const handleComplete = useCallback(() => {}, []);

  useEffect(() => {
    noidWorkerApi.LiveQueryService.subscribe(
      queryId,
      proxy({
        next: handleResult,
        error: handleError,
        complete: handleComplete,
      })
    ).then((subId: string) => (subscriptionId.current = subId));

    return () => {
      if (subscriptionId.current) {
        noidWorkerApi.LiveQueryService.unsubscribe(subscriptionId.current);
      }
    };
  }, []);

  return data;
}
