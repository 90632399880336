/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Image } from "semantic-ui-react";
import PaswordProgressBar from "../passwordProgressBar";
import PrimaryButton from "../primaryButton";
import SecondaryButton from "../secondaryButton/secondaryButton";
import "./passwordChanger.scss";
import { zxcvbn, zxcvbnOptions } from "@zxcvbn-ts/core";
import * as zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
import * as zxcvbnEnPackage from "@zxcvbn-ts/language-en";
import { useIdentitiesUpdate } from "../../providers/IdentityProvider";

const options = {
  // dictionary: {
  //   ...zxcvbnCommonPackage.dictionary,
  //   ...zxcvbnEnPackage.dictionary,
  // },
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  useLevenshteinDistance: true,
  translations: zxcvbnEnPackage.translations,
};
zxcvbnOptions.setOptions(options);

const passwordGenerator = require("generate-password");

const MIN_LEN = 4;
const MAX_LEN = 64;
const DEFAULT_LEN = 16;

export default function PasswordChanger(props) {
  const { updateIdentityPassword } = useIdentitiesUpdate();

  const [length, setLength] = useState(
    props.defaultPasswordLength || DEFAULT_LEN
  );

  const [uppercase, setUppercase] = useState(true);
  const [lowercase, setLowercase] = useState(true);
  const [numbers, setNumbers] = useState(true);
  const [symbols, setSymbols] = useState(true);
  const [passwordScore, setPasswordScore] = useState(0);

  const OPTIONS = [
    {
      title: "Uppercase",
      value: uppercase,
      toggle: () => setUppercase((v) => !v),
    },
    {
      title: "Numbers",
      value: numbers,
      toggle: () => setNumbers((v) => !v),
    },
    {
      title: "Symbols",
      value: symbols,
      toggle: () => setSymbols((v) => !v),
    },
  ];

  const generateSecurePassword = useCallback(() => {
    return passwordGenerator.generate({
      length: length,
      numbers: numbers,
      symbols: symbols,
      lowercase: true,
      uppercase: uppercase,
      strict: true,
    });
  }, [length, numbers, symbols, uppercase]);

  const [password, setPassword] = useState(generateSecurePassword());

  const [showPassword, setShowPassword] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const onChange = (newValue) => {
    setLength(newValue);
  };

  const onSave = () => {
    setIsSaving(true);
    updateIdentityPassword(props.identityId, password, ({ status, error }) => {
      setIsSaving(false);
      props.onClose();
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    setPassword(generateSecurePassword());
  }, [length, uppercase, numbers, symbols, generateSecurePassword]);

  useEffect(() => {
    setPasswordScore(zxcvbn(password).score);
  }, [password]);

  return (
    <div className="px-4 pb-6">
      <div
        className="flex flex-col gap-1 border border-eastern_blue bg-[#F4FBFB] rounded-[8px] p-[10px] 
                      items-center content-center"
      >
        <div className="w-full flex flex-row gap-1 items-center content-center">
          <Image
            src={require("../../assets/images/icon_key_password.svg")}
            className="w-5"
          />

          <div className="h-fit grow text-eastern_blue text-xs font-semibold truncate ">
            {showPassword ? password : "*".repeat(length)}
          </div>

          <div className="w-4 cursor-pointer">
            {showPassword && (
              <FaEyeSlash
                onClick={togglePasswordVisibility}
                style={{ color: "#27A5A5" }}
              />
            )}

            {!showPassword && (
              <FaEye
                onClick={togglePasswordVisibility}
                style={{ color: "#27A5A5" }}
              />
            )}
          </div>
        </div>

        <div className="w-full">
          <PaswordProgressBar score={passwordScore} />
        </div>
      </div>

      <div className="flex flex-row gap-4 items-center text-xs py-2 mt-2 px-2">
        <Image
          src={require("../../assets/images/icon_ruler.svg")}
          className="w-5"
        />

        <Slider
          className="grow"
          defaultValue={props.defaultPasswordLength || DEFAULT_LEN}
          min={MIN_LEN}
          max={MAX_LEN}
          value={length}
          onChange={onChange}
          railStyle={{ backgroundColor: "#EAEBEB", height: "5px" }}
          trackStyle={{ backgroundColor: "#2EE5E5", height: "5px" }}
          handleStyle={{
            backgroundColor: "#2EE5E5",
            border: "none",
            color: "#2EE5E5",
          }}
        />
        <div className="underline text-nile_blue font-semibold cursor-default">
          {length}
        </div>
      </div>

      <div className="flex flew-row gap-4 mb-4 mt-2 items-center px-2">
        <Image
          src={require("../../assets/images/icon_filters.svg")}
          className="w-6"
        />

        <div className="flex flex-col gap-2 text-nile_blue font-normal">
          {OPTIONS.map((opt) => (
            <div
              key={opt.title}
              className="flex flex-row gap-2 items-center text-xs"
            >
              <input
                type="checkbox"
                checked={opt.value}
                className="form-checkbox w-4 h-4 bg-white border-blue_diamond text-blue_diamond rounded-[4px] focus:ring-0 focus:ring-offset-0 cursor-pointer"
                onChange={opt.toggle}
              />
              <span className="cursor-default">{opt.title}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full flex flex-col gap-2 px-2">
        <PrimaryButton
          className="grow text-[12px]"
          disabled={isSaving}
          onClick={onSave}
          title="Save new password"
        />
        <SecondaryButton
          className="text-[12px]"
          disabled={isSaving}
          onClick={props.onClose}
          title="Cancel"
        />
      </div>
    </div>
  );
}
