import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Popup } from "semantic-ui-react";
import noidWorkerApi from "../../NoidWorkerAPI";
import iconCompromised from "../../assets/images/icon-compromised.svg";
import iconInfoCompromised from "../../assets/images/icon-info-compromised.svg";
import iconSafe from "../../assets/images/icon-safe.svg";
import styles from "./mailItemSummary.module.scss";

const formatDate = (dateStr) => {
  if (!moment(new Date()).isSame(dateStr, "day")) {
    return moment(dateStr).format("DD MMM");
  } else {
    return moment(dateStr).format("LT");
  }
};

function MailItemSummary(props) {
  const summary = props.summary;
  const identity = props.identity;

  const isDomainCompromised = useCallback(
    () =>
      summary.isCompromised &&
      !identity?.trustedDomains?.includes(summary.senderDomain),
    [identity?.trustedDomains, summary.isCompromised, summary.senderDomain]
  );

  const [isCompromised, setIsCompromised] = useState(() =>
    isDomainCompromised()
  );

  useEffect(() => {
    setIsCompromised(isDomainCompromised());
  }, [isDomainCompromised]);

  const trustDomain = () => {
    noidWorkerApi.CompromiseService.markDomainAsTrusted(
      summary.senderDomain,
      summary.identityId
    );
    setIsCompromised(false);
  };

  return (
    <div
      className={`${styles.mailSummaryWrapper} 
                         ${props.alreadyRead ? "" : styles.unread} ${
        isCompromised ? styles.compromised : ""
      }
                         ${props.isSelected ? styles.extended : ""}
                         `}
      onClick={props.handleClick}
    >
      <div className={styles.avatarWrapper}>
        <img
          src={
            "https://logo.clearbit.com/" + summary.identityDomain + "?size=40"
          }
          onError={(i) => (i.target.src = "/images/generic.png")}
          alt=""
        />
        <img
          className={styles.avatarStatus}
          src={isCompromised ? iconCompromised : iconSafe}
          alt=""
        />
      </div>

      <div className={styles.descriptionWrapper} onClick={props.handleClick}>
        <div className={styles.sender}>{summary.from.name}</div>

        {props.isSelected && (
          <div className={styles.senderAddress}>{summary.from.address}</div>
        )}

        <div className={styles.subject}>{summary.subject}</div>
      </div>

      <div className={styles.dateAndStatusWrapper}>
        <div>{formatDate(summary.date)}</div>

        {isCompromised && (
          <Popup
            trigger={
              <img
                className={styles.iconInfoCompromised}
                src={iconInfoCompromised}
                alt=""
              />
            }
            className={styles.popupWrapper}
            hoverable={true}
          >
            <Popup.Header className={styles.popupHeader}>
              Unknown sender
            </Popup.Header>
            <Popup.Content className={styles.popupContent}>
              Identity is registered for
              <span className={styles.identityDomain}>
                {" "}
                {summary.identityDomain}{" "}
              </span>
              but this message came from{" "}
              <span className={styles.senderDomain}>
                {" "}
                {summary.from.address.split("@")[1]}{" "}
              </span>
              <Button onClick={trustDomain} className={styles.trustSenderBtn}>
                Trust sender domain
              </Button>
            </Popup.Content>
          </Popup>
        )}
      </div>
    </div>
  );
}

export default MailItemSummary;
