import { ISentSms, ISms } from "@no.id/web-common";
import { Dictionary } from "lodash";
import { createContext } from "react";

export interface SmsContextProps {
  smsSummaries: ISms[];
  smsSentMessages: ISentSms[];
  phonesWithFailures: string[];
  phonesInitialized: string[];
  unreadTotalSmsCounter: number;
  unreadSmsCounterPerPhoneMap: Dictionary<number>;
}

export interface SmsActionsContextProps {
  refresh: () => void;
  markAlreadyRead: (smsId: string) => void;
  modifyUnreadTotalSmsCounterBy: (value: number) => void;
}

const defaultSmsContext: SmsContextProps = {
  smsSummaries: [],
  smsSentMessages: [],
  phonesWithFailures: [],
  phonesInitialized: [],
  unreadTotalSmsCounter: -1,
  unreadSmsCounterPerPhoneMap: {},
};

const defaultSmsActionsContext: SmsActionsContextProps = {
  refresh: () => {},
  markAlreadyRead: (smsId: string) => {},
  modifyUnreadTotalSmsCounterBy: (value: number) => {},
};

export const SmsContext = createContext<SmsContextProps>(defaultSmsContext);
export const SmsActionsContext = createContext<SmsActionsContextProps>(
  defaultSmsActionsContext
);
