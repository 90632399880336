import classNames from "classnames";
import React, { ReactNode } from "react";

export interface MenuItemProps {
  isActive?: boolean;
  icon: any;
  iconActive: any;
  title: string;
  onClick: () => void;
  extraButton?: ReactNode;
}

export default function MenuItem(props: MenuItemProps) {
  const style = classNames(
    "flex flex-row gap-3 justify-between px-3 py-3 w-full cursor-pointer",
    { "rounded-[12px] bg-cyan": props.isActive }
  );

  return (
    <div className={style} onClick={props.onClick}>
      <div className="flex flex-row gap-3">
        <img
          className="h-5"
          src={props.isActive ? props.iconActive : props.icon}
        />
        <span className="text-neutral-80 text-[16px] leading-6 lg:text-[14px] lg:leading-5 font-semibold ">
          {props.title}
        </span>
      </div>
      {props.extraButton ? props.extraButton : null}
    </div>
  );
}
