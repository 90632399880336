import classNames from "classnames";
import * as React from "react";
import { CustomInputProps } from "./type";

export default function PrimaryInput(props: CustomInputProps) {
  const {
    className,
    disabled = false,
    placeholder,
    width = "auto",
    error,
    rightLabel,
    textClassnames,
    inputRef,
    ...inputProps
  } = props;

  const style = classNames(
    "font-sans font-normal flex justify-center items-center focus:outline-none",
    "text-interactive-2 placeholder:text-neutral-60 text-[14px] leading-5 text-black",
    "tracking-wide",
    textClassnames,
    { "w-full": width === "full" }
  );

  const wrapperStyle = classNames(
    "h-fit flex flex-row gap-2 px-4 py-3 rounded-[12px]  items-center min-h-[48px]",
    { "border-neutral-5 border-[1px]": !error },
    { "border-error-50 border-[2px]": error },
    className,
    { "bg-white": !disabled },
    { "bg-gray-100": disabled }
  );

  return (
    <div className={wrapperStyle}>
      <img className="h-6 w-6" src={props.icon} />
      <input
        {...inputProps}
        className={style}
        disabled={disabled}
        placeholder={props.placeholder}
        ref={inputRef}
      />
      {rightLabel && (
        <span className="text-primary-100 text-[16px] whitespace-nowrap font-semibold leading-6 w-max text-eastern_blue">
          {rightLabel}
        </span>
      )}
    </div>
  );
}
