import React, { useCallback, useEffect, useState } from "react";
import { Message } from "semantic-ui-react";
import { useEmailActions } from "../../providers/EmailProvider";
import MailItemSummary from "../maiItemSummary/mailItemSummary";
import MailBody from "../mailBody/mailBody";
import MailItemLoader from "../mailItemLoader/MailItemLoader";
import styles from "./mailItem.module.scss";

export default function MailItem(props) {
  const { getEmail } = useEmailActions();

  const [mail, setMail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDangerousMail, setShowDangerousMail] = useState(false);
  const [error, setError] = useState(null);

  // eslint-disable-next-line
  const loadMail = useCallback(() => {
    setIsLoading(true);
    setShowDangerousMail(false);

    getEmail(props.mailId, props.identityId)
      .then((mail) => {
        setMail(mail);
        setShowDangerousMail(false);
        setError(null);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Mail read/decrypt error", err);
        setMail(null);
        setIsLoading(false);
        setShowDangerousMail(false);
        setError("Something has gone wrong. Please try again");
      });
  }, [getEmail, props.identityId, props.mailId]);

  useEffect(() => {
    loadMail();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="h-[calc(100vh-170px)] lg:h-[calc(100vh-100px)] overflow-y-auto">
      <MailItemSummary
        alreadyRead={true}
        summary={props.summary}
        identity={props.identity}
        isSelected={true}
        handleClick={() => {}}
      />

      <div className={styles.mailItemContent}>
        {isLoading && <MailItemLoader style={{ width: "90%" }} />}
        {mail && !isLoading && !error && (
          <MailBody mail={mail} dangerousMail={showDangerousMail} />
        )}
        {error && !isLoading && (
          <Message negative>
            <Message.Header>{error}</Message.Header>
          </Message>
        )}
      </div>
    </div>
  );
}
