import React from "react";
import BuyPhoneNumberView from "../buyPhoneNumberView/BuyPhoneNumberView";
import CreditPanel from "../creditPanel/CreditPanel";
import "./PhoneDashboardHeader.scss";

export default function PhoneDashboardHeader(props) {
  return (
    <div className="relative bg-white">
      <div className="relative z-[1] top-0 px-4 lg:px-6 pb-6 flex flex-row flex-wrap lg:flex-nowrap gap-[0px] md:gap-[32px] lg:gap-[72px] items-center">
        <CreditPanel />
        <BuyPhoneNumberView />
      </div>

      <div className="absolute top-0 left-0 z-0 lg:h-[192px] w-full">
        <div
          style={{ zIndex: -10 }}
          className="absolute h-[310px] lg:h-[250px] lg:max-h-[250px] w-full lg:w-[420px] blur-md
        bg-[radial-gradient(ellipse_at_top_center,_var(--tw-gradient-stops))] 
        lg:bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))]
        from-[#F4FBFB]"
        ></div>

        <img
          className="h-[240px] lg:h-[256px] absolute right-[-100px] top-[-48px] md:right-[-24px] md:top-[-16px] z-10"
          src={require("../../assets/images/no-spam-no-robocalling-no-bothering.png")}
        />
      </div>
    </div>
  );
}
