import React, {useContext, useEffect, useState} from 'react'
import noidWorkerApi from "../NoidWorkerAPI";
import {proxy} from "comlink";

export const UserProfileContext = React.createContext();

export function useUserProfile() {
    return useContext(UserProfileContext);
}


export function UserProfileProvider({ children }) {

    const [credits, setCredits] = useState(0);

    const loadCredits = async () => {
        await noidWorkerApi.UserProfileService.getCredit(proxy(credit => setCredits(credit)));
    };

    useEffect(() => {
        loadCredits()
    });


    const decreaseCredits = (cost) => {
        noidWorkerApi.UserProfileService.decreaseCredit(cost, proxy(credits => setCredits(credits)));
    }

    return (
        <UserProfileContext.Provider value={{credits, decreaseCredits}}>
                {children}
        </UserProfileContext.Provider>
    )


}