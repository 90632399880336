import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

interface TimerProps {}

export interface TimerHandles {
  start: () => void;
  end: () => void;
}

const Timer = forwardRef<TimerHandles, TimerProps>((props, ref) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval!);
      setSeconds(0);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isActive, seconds]);

  useImperativeHandle(ref, () => ({
    start() {
      setIsActive(true);
    },
    end() {
      setIsActive(false);
    },
  }));

  const formatTime = useCallback((totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }, []);

  return (
    <div className="font-sans font-medium text-[19px]">
      {isActive ? formatTime(seconds) : null}
    </div>
  );
});

export default Timer;
