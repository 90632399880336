import React, { useMemo } from "react";
import "./IdentitiesStats.scss";
import { useIdentities } from "../providers/IdentityProvider";

function IdentitiesStats(props) {
  const { identities, compromisedTotal } = useIdentities();

  const identitiesTotal = useMemo(() => {
    return identities ? identities.length : 0;
  }, [identities]);

  return (
    <div className="py-6 lg:p-6 flex flex-row md:flex-col lg:flex-row flex-wrap gap-10">
      <div className="flex flex-col gap-4">
        <div className="font-medium text-[14px] leading-5">Identities: </div>
        <div className="font-bold text-[32px] leading-10">
          {identitiesTotal}
        </div>
      </div>

      <div className="flex flex-col text-warning-50 gap-4">
        <div className="font-medium text-[14px] leading-5">Compromised: </div>
        <div className="font-bold text-[32px] leading-10">
          {compromisedTotal}
        </div>
      </div>
    </div>
  );
}

export default IdentitiesStats;
