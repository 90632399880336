import { LiveQueryType } from "@no.id/web-common";
import React, { useContext, useEffect, useMemo, useState } from "react";
import useLiveQuery from "../hooks/useLiveQuery";

const SyncStateContext = React.createContext();

export function useSyncState() {
  return useContext(SyncStateContext);
}

export function SyncStateProvider({ children }) {
  const [isSynced, setIsSynced] = useState(false);
  const syncState = useLiveQuery(LiveQueryType.SYNC_STATE);

  useEffect(() => {
    // console.log("@@@@@@ sync : " + JSON.stringify(syncState, null, 3));
    if (
      syncState &&
      (syncState.phase == "in-sync" || syncState.phase == "initial") &&
      syncState.status == "connected"
    ) {
      if (!isSynced) {
        setIsSynced(true);
      }
    }
  }, [syncState]);

  const providerValue = useMemo(
    () => ({
      isSynced,
    }),
    [isSynced]
  );

  return (
    <SyncStateContext.Provider value={providerValue}>
      {children}
    </SyncStateContext.Provider>
  );
}
