import React from "react";

export function PencilIcon({
  color = "#153F4C",
  size = 29,
}: {
  color?: string;
  size?: number;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5007 10.7047H9.00065C8.08018 10.7047 7.33398 11.4509 7.33398 12.3714V19.8714C7.33398 20.7919 8.08018 21.538 9.00065 21.538H16.5007C17.4211 21.538 18.1673 20.7919 18.1673 19.8714V17.3714"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 17.3714H14L21.0833 10.288C21.7737 9.59767 21.7737 8.47839 21.0833 7.78803C20.393 7.09767 19.2737 7.09767 18.5833 7.78803L11.5 14.8714V17.3714"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.334 9.03796L19.834 11.538"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
