import React from "react";

export function MaskIcon({
  color = "#000000",
  size = 32,
}: {
  color?: string;
  size?: number;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.6035 49.4519V20.8287C32.6035 20.1634 33.2411 19.6836 33.8804 19.8678L38.9343 21.324C39.2703 21.4208 39.5313 21.686 39.6227 22.0235L41.8383 30.2042L37.6819 28.2895L34.8579 30.5196L37.6819 32.601L41.9862 30.7504L43.6394 36.8544C43.719 37.1484 43.6603 37.4628 43.4798 37.7082L34.4092 50.0442C33.837 50.8224 32.6035 50.4177 32.6035 49.4519Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.7472 18.8584C39.844 18.8584 41.6629 20.3065 42.1328 22.35L45.2248 35.7971C45.5393 37.165 45.1976 38.6013 44.3007 39.681L35.8247 49.8842C34.068 51.999 30.8423 52.058 29.0094 50.009L19.7799 39.6915C18.8408 38.6416 18.4474 37.2125 18.7171 35.8299L21.3173 22.497C21.7295 20.3837 23.581 18.8584 25.7341 18.8584H37.7472ZM39.2091 23.0223C39.0525 22.3411 38.4461 21.8584 37.7472 21.8584H25.7341C25.0164 21.8584 24.3992 22.3668 24.2619 23.0713L21.6616 36.4042C21.5717 36.865 21.7028 37.3414 22.0159 37.6914L31.2453 48.0089C31.8563 48.6919 32.9315 48.6722 33.5171 47.9673L41.993 37.764C42.292 37.4041 42.4059 36.9253 42.3011 36.4694L41.0751 31.1376L42.4687 30.5312L40.751 29.7282L39.2091 23.0223Z"
        fill={color}
      />
    </svg>
  );
}
