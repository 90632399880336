import { IPhone } from "@no.id/web-common";
import classNames from "classnames";
import React from "react";

const btnMailStyle = classNames(
  "flex flex-row flex-center items-center gap-3 px-6 py-3 rounded-[12px] border-[1px] border-neutral-5 max-h-12",
  "text-[14px] leading-5 font-semibold text-neutral-10"
);

const btnMailCompactStyle = classNames(
  "flex flex-row flex-center items-center gap-3 p-3 rounded-[12px] border-[1px] border-neutral-5 max-h-12",
  "text-[14px] leading-5 font-semibold text-neutral-10"
);

export interface SmsToolbarProps {
  phone: IPhone;
  onBackClick: () => void;
  onCreateClick: () => void;
}

export default function SmsToolbar({
  phone,
  onBackClick,
  onCreateClick,
}: SmsToolbarProps) {
  return (
    <div className="w-full flex flex-col gap-4">
      <div
        className={classNames(
          "flex flex-row w-full justify-between items-center"
        )}
      >
        <div className=" flex flex-row items-center gap-4">
          <button className={btnMailStyle} onClick={onBackClick}>
            <img
              src={require("../../../../assets/images/icon-back.svg")}
              alt=""
            />
            <span className="hidden md:block">Back to Phones</span>
          </button>
          <div className="hidden sm:flex flex-col gap-[-4px]">
            <div className="text-[14px] text-silver_sand font-medium leading-5">
              Phone number:
            </div>
            <div className="text-[20px] text-neutral-100 font-semibold leading-8">
              {phone.friendlyName}
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-2">
          <button className={btnMailCompactStyle}>
            <img
              src={require("../../../../assets/images/icon-refresh.svg")}
              className="h-6 w-6"
              alt=""
            />
          </button>

          <button className={btnMailStyle} onClick={onCreateClick}>
            <img
              src={require("../../../../assets/images/icon-pencil.svg")}
              className="h-6"
              alt=""
            />
            <div className="hidden md:block">Create</div>
          </button>
        </div>
      </div>

      <div className="flex sm:hidden flex-row justify-between w-full items-center">
        <div className="text-[14px] text-silver_sand font-medium leading-5">
          Phone number:
        </div>
        <div className="text-[20px] text-neutral-100 font-semibold leading-8">
          {phone.friendlyName}
        </div>
      </div>
    </div>
  );
}
