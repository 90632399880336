import classNames from "classnames";
import React from "react";
import DomainAvatarWithStatus from "../domainAvatarWithStatus";

function IdentitySelectView({ identity }) {
  return (
    <div
      className={classNames(
        "flex flex-col md:flex-1 rounded-md sm:px-4 py-1",
        "h-fit gap-3 w-full cursor-pointer hover:bg-gray-100/50"
      )}
    >
      <div className="flex justify-start p-2 flex-row gap-4">
        <DomainAvatarWithStatus
          domain={identity.domain}
          isCompromised={identity?.compromisedDomains?.length > 0}
        />

        <div className="ml-2">
          <div className="text-neutral-80 text-sm font-medium font-sans leading-5">
            {identity.domain}
          </div>
          <div className="flex items-center text-neutral-60 text-xs font-medium font-sans leading-5 flex-row truncate">
            {identity.note
              ? identity.note
              : `Dedicated Identity for ${identity.domain}`}
          </div>
        </div>
      </div>
    </div>
  );
}

export default IdentitySelectView;
