import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { Image, Popup } from "semantic-ui-react";
import IdentityFieldCopyable from "../identityFieldCopyable/IdentityFieldCopyable";
import PasswordChanger from "../passwordChanger/passwordChanger";

function IdentityView({ id }) {
  const [showPasswordChanger, setShowPasswordChanger] = useState(false);
  const isCompromised = useMemo(() => id?.compromisedDomains?.length > 0, [id]);

  const togglePasswordChanger = () => {
    setShowPasswordChanger((prevValue) => !prevValue);
  };

  const onPasswordChangerClose = () => {
    togglePasswordChanger();
  };

  return (
    <div
      className={classNames(
        // "grid grid-flow-col auto-cols-fr",
        "flex flex-col md:flex-1",
        "h-fit gap-2 border-[1px] rounded-[12px] w-full",
        "md:min-w-[264px] md:max-w-[326px] lg:max-w-[264px]  shadow-[0_1px_2px_0_rgba(16,24,40,0.05)]",
        { "border-neutral-0 bg-white": !isCompromised },
        { "border-error-5 bg-error-0": isCompromised }
      )}
    >
      <div className="flex justify-between px-6 pt-6">
        <Popup
          trigger={
            <div className="relative">
              <div className="h-[42px] w-[42px] rounded-[8px] flex items-center">
                <Image
                  className="rounded-[8px] border-[1px] h-full w-full"
                  src={"https://logo.clearbit.com/" + id.domain + "?size=42"}
                  onError={(i) => (i.target.src = "/images/generic.png")}
                />
              </div>

              <div className="absolute z-10 right-[-8px] bottom-[-8px] md:left-7">
                {!isCompromised && (
                  <img src={require("../../assets/images/shield-safe.svg")} />
                )}
                {isCompromised && (
                  <img src={require("../../assets/images/shield-unsafe.svg")} />
                )}
              </div>
            </div>
          }
          popperModifiers={[
            {
              name: "zIndex",
              enabled: true,
              phase: "write",
              fn: ({ state }) => {
                state.styles.popper.zIndex = 40;
              },
            },
          ]}
          position="top center"
        >
          <div className="bg-white border-[1px] rounded-[12px] p-2 ">
            <Popup.Header
              style={{ color: isCompromised ? "#f76262" : "#21ba45" }}
            >
              {!isCompromised
                ? "Identity is safe"
                : "Identity is likely compromised!"}
            </Popup.Header>
            <Popup.Content
              style={{ color: !isCompromised ? "grey" : "#010101" }}
            >
              {!isCompromised
                ? "No threads detected"
                : "You started receiving messages from other sources."}
            </Popup.Content>
          </div>
        </Popup>

        <div
          className={classNames(
            "text-[14px] leading-5 font-medium ",
            { "text-neutral-80": !isCompromised },
            { "text-error-50": isCompromised }
          )}
        >
          <a
            href={"https://" + id.domain}
            target="_blank"
            rel="noopener noreferrer"
          >
            {id.domain}
          </a>
        </div>
      </div>

      {showPasswordChanger && (
        <PasswordChanger
          defaultPasswordLength={id.password.length}
          identityId={id.id}
          onClose={onPasswordChangerClose}
        />
      )}

      {!showPasswordChanger && (
        <>
          <div className="px-4">
            <IdentityFieldCopyable
              name="E-mail"
              value={id.email}
              displayValue={`${id.email.substring(0, 16)}...`}
              textClassNames={classNames(
                { "text-neutral-60": !isCompromised },
                { "text-error-50": isCompromised }
              )}
              icon={
                isCompromised
                  ? require("../../assets/images/icon-at-red.svg")
                  : require("../../assets/images/icon_at.svg")
              }
            />
          </div>

          <div className="px-4">
            <IdentityFieldCopyable
              name="Password"
              value={id.password}
              displayValue="*******"
              icon={
                isCompromised
                  ? require("../../assets/images/icon_key-red.svg")
                  : require("../../assets/images/icon_key.svg")
              }
              textClassNames={classNames(
                { "text-neutral-60": !isCompromised },
                { "text-error-50": isCompromised }
              )}
              extraCallback={togglePasswordChanger}
              extraIcon={require("../../assets/images/icon_settings.svg")}
            />
          </div>

          <div className="border-t-[1px] px-6 py-4">
            <Popup
              trigger={
                <span
                  className={classNames(
                    "max-w-[216px] text-[12px] leading-4 font-normal",
                    { "text-[#a6a6a6]": !isCompromised && !id.note },
                    { "text-[#172E3A]": !isCompromised && id.note },
                    { "text-error-50": isCompromised }
                  )}
                >
                  {!id.note ? "No Comment" : id.note}
                </span>
              }
              position="top center"
              content={id.note}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default IdentityView;
