import { ChannelTypes } from "@no.id/web-common";
import { proxy } from "comlink";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import noidWorkerApi from "../NoidWorkerAPI";

const AuthContext = React.createContext();
const AuthActionsContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function useAuthActions() {
  return useContext(AuthActionsContext);
}

export function AuthProvider({ children }) {
  const [userProfile, setUserProfile] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState();

  const login = useCallback(async (username, password, action) => {
    return await noidWorkerApi.AuthService.login(
      username,
      password,
      window.location.origin,
      proxy((loginStatus, userProfile) => {
        if (loginStatus) {
          setIsLoggedIn(true);
          setUserProfile(userProfile);
        } else {
          setIsLoggedIn(false);
        }
        action(loginStatus);
      })
    );
  }, []);

  const signUp = useCallback(
    async (username, password, code, captchaToken, action) => {
      await noidWorkerApi.AuthService.signUp(
        username,
        password,
        code,
        captchaToken,
        window.location.origin,
        proxy((signUpResult, userProfile) => {
          if (!signUpResult) {
            action(false);
            return;
          }

          setUserProfile(userProfile);
          setIsLoggedIn(true);
          action(true);
        })
      );
    },
    []
  );

  const authActions = useMemo(
    () => ({
      login,
      signUp,
    }),
    [login, signUp]
  );

  const value = useMemo(
    () => ({
      isLoggedIn,
      userProfile,
    }),
    [isLoggedIn, userProfile]
  );

  useEffect(() => {
    noidWorkerApi.InitializationService.openDbIfUserLoggedIn(
      proxy((isLoggedIn0) => {
        if (!isLoggedIn0) {
          setIsLoggedIn(false);
          return;
        }
        noidWorkerApi.InitializationService.initNecessaryServicesIfUserLoggedIn(
          proxy((isLoggedIn) => {
            setIsLoggedIn(isLoggedIn);
          })
        );
      })
    );

    const bcAuthChannel = new BroadcastChannel(ChannelTypes.AUTH_CHANNEL);
    bcAuthChannel.onmessage = (msg) => {
      switch (msg.data.type) {
        case "login":
          setIsLoggedIn(true);
          break;

        case "logout":
          setIsLoggedIn(false);
          break;
      }
    };

    return () => {
      bcAuthChannel.close();
    };
  }, []);

  return (
    <AuthContext.Provider value={value}>
      <AuthActionsContext.Provider value={authActions}>
        {children}
      </AuthActionsContext.Provider>
    </AuthContext.Provider>
  );
}
